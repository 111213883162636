import React, { useRef } from 'react';
import ReactSelect from 'react-select';

export const MultiSelect = (props) => {
  // isOptionSelected sees previous props.value after onChange
  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  const selectAllOption = {
    value: '<SELECT_ALL>',
    label: props.allName ? props.allName : 'All Options',
  };

  const isSelectAllSelected = () => valueRef.current?.length === props.options?.length && valueRef.current?.length !== 0;

  const isOptionSelected = (option) => {
    if (valueRef.current) {
      return valueRef.current.some(({ value }) => value === option.value) || isSelectAllSelected();
    }
    return false;
  };

  const getOptions = () => [selectAllOption, ...(props.options ?? [])];

  const getValue = () => (isSelectAllSelected() ? [selectAllOption] : props.value);

  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;

    if (action === 'select-option' && option.value === selectAllOption.value) {
      props.onChange(props.options, actionMeta);
    } else if (
      (action === 'deselect-option' && option.value === selectAllOption.value) ||
      (action === 'remove-value' && removedValue.value === selectAllOption.value)
    ) {
      props.onChange([], actionMeta);
    } else if (actionMeta.action === 'deselect-option' && isSelectAllSelected()) {
      props.onChange(
        props.options.filter(({ value }) => value !== option.value),
        actionMeta,
      );
    } else {
      props.onChange(newValue || [], actionMeta);
    }
  };

  return (
    <ReactSelect
      isOptionSelected={isOptionSelected}
      options={getOptions()}
      value={getValue()}
      onChange={onChange}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      className="form-control-select"
      classNamePrefix="react-select-multi"
      placeholder={props.placeholder}
      isDisabled={props.isDisabled}
      isMulti
      menuPlacement={props.menuPlacement || 'auto'}
      {...props.otherSelectProps}
    />
  );
};
