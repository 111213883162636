import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { MultiSelect } from '../../../../components/other/MultiSelect';
import Select from 'react-select';
import { getCompanyUserGroupListSettingsInit } from '../../../../services/company';
import { getUsersListSettingsInit } from '../../../../services/user';
import { useDeepCompareEffect } from 'react-use';
import StrictOrderUserNotificationSettings from '../../../../components/other/strict-order-user-notification-settings';

const SubmissionSettingsSystem = ({
  task,
  formData,
  handleFormChange,
  companyID,
  setFormData,
  user,
  errors,
  hasStrictOrderOfSubmissions,
}) => {
  const submissionSettings = task.task_forms[0].submission_settings;
  const formID = task.task_forms[0].form_id;

  const [submittersOptions, setSubmittersOptions] = useState({
    facilities: [],
    user_groups: [],
    individual_users: [],
  });

  const handleGetUserGroups = useCallback(
    async (facilitiesIDs) => {
      try {
        const result = await getCompanyUserGroupListSettingsInit({
          company_id: companyID,
          status: 'active',
          form_id: formID,
          field: 'submitters_settings',
          facilities_ids: facilitiesIDs,
        });

        setSubmittersOptions((prevState) => ({
          ...prevState,
          user_groups: result.map((group) => ({ value: group.id, label: group.name })),
        }));
      } catch (e) {
        console.log(e);
      }
    },
    [companyID],
  );

  const handleGetIndividualUsers = useCallback(
    async (facilitiesIDs, userGroupsIDs) => {
      try {
        const result = await getUsersListSettingsInit({
          company_id: companyID,
          status: 'active',
          form_id: formID,
          field: 'submitters_settings',
          facilities_ids: facilitiesIDs,
          user_groups_ids: userGroupsIDs,
        });

        setSubmittersOptions((prevState) => ({
          ...prevState,
          individual_users: result.map((user) => ({ value: user.id, label: `${user.last_name} ${user.first_name}` })),
        }));
      } catch (e) {
        console.log(e);
      }
    },
    [companyID, formID],
  );

  useDeepCompareEffect(() => {
    const facilitiesIDs = formData.submitters.facilities.map((facility) => facility.value);

    if (facilitiesIDs.length) {
      if (!submissionSettings.submitters.is_users_from_initiator_facility) {
        handleGetUserGroups(facilitiesIDs);
      } else {
        handleGetUserGroups(user.facility ? [user.facility.id] : facilitiesIDs);
      }
    } else {
      setSubmittersOptions((prevState) => ({ ...prevState, user_groups: [] }));
    }
  }, [handleGetUserGroups, formData.submitters.facilities]);

  useDeepCompareEffect(() => {
    const facilitiesIDs = formData.submitters.facilities.map((facility) => facility.value);
    const userGroupsIDs = formData.submitters.user_groups.map((group) => group.value);

    if (!submissionSettings.submitters.recipients_only_from_initiator_facility) {
      handleGetIndividualUsers(facilitiesIDs, userGroupsIDs);
    } else {
      handleGetIndividualUsers(user.facility ? [user.facility.id] : facilitiesIDs, userGroupsIDs);
    }
  }, [formData.submitters.user_groups, formData.submitters.facilities, handleGetIndividualUsers]);

  useDeepCompareEffect(() => {
    if (formData.submitters.user_groups.length) {
      setFormData((prevState) => {
        const newPrevState = _.cloneDeep(prevState);
        newPrevState.submitters.user_groups = newPrevState.submitters.user_groups.filter((group) =>
          submittersOptions.user_groups.some((item) => item.value == group.value),
        );

        return newPrevState;
      });
    }
  }, [submittersOptions.user_groups]);

  useDeepCompareEffect(() => {
    if (formData.submitters.individual_users.length) {
      setFormData((prevState) => {
        const newPrevState = _.cloneDeep(prevState);
        newPrevState.submitters.individual_users = newPrevState.submitters.individual_users.filter((obj) =>
          submittersOptions.individual_users.some((item) => obj.value == item.value),
        );

        return newPrevState;
      });
    }
  }, [submittersOptions.individual_users]);

  useDeepCompareEffect(() => {
    if (!submissionSettings.submitters.is_users_from_initiator_facility) {
      setSubmittersOptions((prevState) => ({
        ...prevState,
        facilities: submissionSettings.submitters.facilities.map((item) => ({ value: item.id, label: item.name })),
      }));
      handleGetIndividualUsers([], []);
    } else {
      handleGetIndividualUsers(user.facility ? [user.facility.id] : []);
      handleGetUserGroups(user.facility ? [user.facility.id] : []);
    }
  }, [task, handleGetIndividualUsers]);

  return (
    <>
      <div className="form-control-item-settings">
        <div className="form-settings-label">
          <label className="form-label form-label-big">Select submitters</label>
          <span>Specify users from facilities and / or user groups / individual users set by the form creator</span>
        </div>

        <div className="form-settings-inputs">
          <div className="form-item form-item-select mb-4">
            <label className="form-label form-label-big">Facility</label>
            <div className="select-multy-wrapper">
              <span className="select-placeholder">Choose one or several facilities</span>
              <MultiSelect
                isDisabled={!submittersOptions.facilities.length}
                options={submittersOptions.facilities}
                value={formData.submitters.facilities}
                onChange={(e) => handleFormChange('submitters.facilities', e)}
                placeholder="Choose one or several facilities"
                allName="All facilities"
              ></MultiSelect>
              {formData.submitters.facilities.length > 0 && (
                <>
                  <ul>
                    {formData.submitters.facilities.map((item, key) => (
                      <li key={key}>
                        {item.label}
                        <button
                          className="btn btn-icon"
                          onClick={() =>
                            handleFormChange(
                              'submitters.facilities',
                              formData.submitters.facilities.filter((facility) => facility.value !== item.value),
                            )
                          }
                        >
                          <img src="/images/x-circle.svg" alt="" />
                        </button>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <span className="form-error mt-1 d-flex">{errors?.submitters?.facilities && errors.submitters?.facilities[0]}</span>
          </div>
          <div className={`form-item form-item-select mb-4`}>
            <label className="form-label form-label-big">User groups</label>
            <div className="select-multy-wrapper">
              <span className="select-placeholder">Choose one or several user groups</span>
              <MultiSelect
                isDisabled={!submittersOptions.user_groups.length}
                options={submittersOptions.user_groups}
                value={formData.submitters.user_groups}
                onChange={(e) => handleFormChange('submitters.user_groups', e)}
                allName="All user groups"
              ></MultiSelect>
              {formData.submitters.user_groups.length > 0 && (
                <>
                  <ul>
                    {formData.submitters.user_groups.map((item, key) => (
                      <li key={key}>
                        {item.label}
                        <button
                          className="btn btn-icon"
                          onClick={() =>
                            handleFormChange(
                              'submitters.user_groups',
                              formData.submitters.user_groups.filter((group) => group.value != item.value),
                            )
                          }
                        >
                          <img src="/images/x-circle.svg" alt="" />
                        </button>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <span className="form-error mt-1 d-flex">{errors.submitters?.user_groups && errors.submitters?.user_groups[0]}</span>
          </div>

          <div className="form-item form-item-select mb-4">
            <label className="form-label form-label-big">Individual Users</label>
            <div className="select-multy-wrapper">
              <span className="select-placeholder">Choose one or several individual_users</span>
              <MultiSelect
                isDisabled={!submittersOptions.individual_users.length}
                options={submittersOptions.individual_users}
                value={formData.submitters.individual_users}
                onChange={(e) => handleFormChange('submitters.individual_users', e)}
                allName="All individual users"
              ></MultiSelect>
              {formData.submitters.individual_users.length > 0 && (
                <>
                  <ul className="individual-users-list">
                    {formData.submitters.individual_users
                      .sort(function (a, b) {
                        if (a.label < b.label) {
                          return -1;
                        }
                        if (a.label > b.label) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((item, key) => (
                        <li key={key}>
                          {item.label}
                          <button
                            className="btn btn-icon"
                            onClick={() =>
                              handleFormChange(
                                'submitters.individual_users',
                                formData.submitters.individual_users.filter((user) => user.value !== item.value),
                              )
                            }
                          >
                            <img src="/images/x-circle.svg" alt="" />
                          </button>
                        </li>
                      ))}
                  </ul>
                </>
              )}

              <span className="form-error mt-1 d-flex">
                {errors?.submitters?.individual_users && errors.submitters?.individual_users[0]}
              </span>
            </div>
          </div>
        </div>
      </div>
      {hasStrictOrderOfSubmissions && (
        <div className="form-control-item-settings">
          <div className="form-settings-label">
            <label className="form-label form-label-big">Specify strict order of submitters (required)</label>
            <span>it is necessary to provide a clear and definite sequence for the task submitting </span>
          </div>
          <div className="form-settings-inputs">
            <>
              {formData.submitters.strict_order.map((step, stepIndex) => {
                const notificationData = {
                  should_receive_notification: step.should_receive_notification,
                  notification_type: step.notification_type,
                  notification_text: step.notification_text,
                  errors: errors.submitters?.strict_order?.[stepIndex],
                };

                return (
                  <div className="form-item mb-4" key={stepIndex}>
                    <label className="form-label form-label-big">Step {stepIndex + 1}</label>
                    <div className={formData.submitters.strict_order.length > 2 ? 'd-flex' : ''}>
                      <Select
                        classNamePrefix="react-select"
                        className={`form-control-select`}
                        options={[
                          ...formData.submitters.individual_users.map((item) => ({ ...item, entity_type: 'user' })),
                          ...formData.submitters.user_groups.map((item) => ({ ...item, entity_type: 'user_group' })),
                        ]}
                        value={step}
                        onChange={(e) =>
                          handleFormChange(`submitters.strict_order[${stepIndex}]`, {
                            ...step,
                            value: e.value,
                            label: e.label,
                            entity_type: e.entity_type,
                            submitter: e.value,
                          })
                        }
                      ></Select>
                      {formData.submitters.strict_order.length > 2 && (
                        <button
                          className="btn btn-icon btn-remove ml-4"
                          onClick={() => {
                            handleFormChange(`submitters.strict_order`, [
                              ...formData.submitters.strict_order.filter((item, index) => index !== stepIndex),
                            ]);
                          }}
                        >
                          <i className="icon-remove"></i>
                        </button>
                      )}
                    </div>
                    <span className="form-error mb-2">
                      {errors?.submitters?.strict_order &&
                        errors?.submitters?.strict_order[stepIndex]?.hasOwnProperty('submitter') &&
                        errors?.submitters?.strict_order[stepIndex].submitter}
                    </span>
                    <span className="form-error mb-2">
                      {errors?.submitters?.strict_order &&
                        typeof errors?.submitters?.strict_order[stepIndex] === 'string' &&
                        errors?.submitters?.strict_order[stepIndex]}
                    </span>
                    <StrictOrderUserNotificationSettings
                      stepIndex={stepIndex}
                      key={stepIndex}
                      notificationData={notificationData}
                      onSetStepUserNotification={(newData, key, stepIndex) =>
                        handleFormChange(`submitters.strict_order[${stepIndex}]`, { ...step, ...newData })
                      }
                    />
                  </div>
                );
              })}
              <span
                className="add-facility cursor-pointer"
                onClick={() =>
                  handleFormChange(`submitters.strict_order`, [
                    ...formData.submitters.strict_order,
                    { step: formData.submitters.strict_order.length },
                  ])
                }
              >
                <i className="icon-plus-svgrepo-com mr-2"></i>Add order
              </span>
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default SubmissionSettingsSystem;
