// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slot-details-block {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dadee4;
  padding: 12px;
}
.slot-details-block .slot-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #101828;
  margin-bottom: 0;
}
.slot-details-block .slot-subtitle {
  font-size: 14px;
  color: #667085;
  font-weight: 400;
  margin-top: 8px;
}
.slot-details-block .slot-user-info {
  display: flex;
  gap: 12px;
  margin-top: 24px;
}
.slot-details-block .slot-user-info .slot-user-info-labels .slot-user-info-label {
  font-size: 12px;
  line-height: 18px;
  color: #667085;
}
.slot-details-block .slot-user-info .slot-user-info-values .slot-user-info-value {
  color: #101828;
  font-size: 14px;
  line-height: 18px;
}
.slot-details-block .slot-user-info .slot-user-info-values .slot-user-info-value a {
  color: #6941c6;
  text-decoration: none;
}
.slot-details-block .slot-delete-btn {
  padding: 0;
  margin-top: 24px;
  box-shadow: unset !important;
}
.slot-details-block .slot-delete-btn:hover {
  opacity: 0.8;
}`, "",{"version":3,"sources":["webpack://./src/pages/schedule/components/SlotDetailsPopover/styles.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,kBAAA;EACA,yBAAA;EACA,aAAA;AACF;AACE;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;AACJ;AAEE;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AAAJ;AAGE;EACE,aAAA;EACA,SAAA;EACA,gBAAA;AADJ;AAIM;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AAFR;AAMM;EACE,cAAA;EACA,eAAA;EACA,iBAAA;AAJR;AAMQ;EACE,cAAA;EACA,qBAAA;AAJV;AAUE;EACE,UAAA;EACA,gBAAA;EACA,4BAAA;AARJ;AAUI;EACE,YAAA;AARN","sourcesContent":[".slot-details-block {\n  background-color: #fff;\n  border-radius: 8px;\n  border: 1px solid #dadee4;\n  padding: 12px;\n\n  .slot-title {\n    font-size: 16px;\n    line-height: 24px;\n    font-weight: 600;\n    color: #101828;\n    margin-bottom: 0;\n  }\n\n  .slot-subtitle {\n    font-size: 14px;\n    color: #667085;\n    font-weight: 400;\n    margin-top: 8px;\n  }\n\n  .slot-user-info {\n    display: flex;\n    gap: 12px;\n    margin-top: 24px;\n\n    .slot-user-info-labels {\n      .slot-user-info-label {\n        font-size: 12px;\n        line-height: 18px;\n        color: #667085;\n      }\n    }\n    .slot-user-info-values {\n      .slot-user-info-value {\n        color: #101828;\n        font-size: 14px;\n        line-height: 18px;\n\n        a {\n          color: #6941c6;\n          text-decoration: none;\n        }\n      }\n    }\n  }\n\n  .slot-delete-btn {\n    padding: 0;\n    margin-top: 24px;\n    box-shadow: unset !important;\n\n    &:hover {\n      opacity: 0.8;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
