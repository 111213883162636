import React, { useState, useEffect } from 'react';
import Layout from '../../layout/default';
import { Link } from 'react-router-dom';

import { useNavigate, useParams } from 'react-router-dom';
import { getSubmissionsProfileById } from '../../services/folder/form-avaliable';
import { useAuth } from '../../container/authContext';
import Select from 'react-select';
import { SubmissionItem } from '../../components/library/submission-item';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { getUserById } from '../../services/user';
import { usePermission } from '../../container/permissionContext';
import { redirectToLibrary } from '../../utility';
import { utcToLocalDateFormatter } from '../../utility/dates/utcToLocalDate';

function CorporateLibrary() {
  const { user } = useAuth();
  const [profile, setUser] = useState({});

  const [defaultIndex, setDefaultIndex] = useState(1);
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState([
    {
      url: '/my-profile',
      name: 'My Profile',
    },
  ]);

  const { user_id, id } = useParams();
  const navigation = useNavigate();
  const { isEmployee, hasPermission } = usePermission();
  useEffect(() => {
    if (isEmployee() && !hasPermission('Users, Positions management')) {
      redirectToLibrary(navigation);
    }
  }, [isEmployee, navigation, hasPermission]);
  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, [user_id]);
  const initPage = () => {
    getUserById(user_id)
      .then((res) => {
        setUser(res);
        let breadcrumbs = [
          {
            url: `/company/${res.company.id}`,
            name: res.company.name,
          },
          {
            url: `/company/${res.company.id}/users`,
            name: 'User List',
          },
          {
            url: `/company/${res.company.id}/user/${res.id}`,
            name: `${res.first_name} ${res.last_name}`,
          },
          {
            url: `/company/${res.company.id}/user/${res.id}/forms`,
            name: `Forms`,
          },
        ];
        setPageBreadcrumbs(breadcrumbs);
      })
      .catch((e) => console.log(e));
  };
  const [formsSearch, setFormsSearch] = useState([]);
  const [search, setSearch] = useState('');
  const [isFilterMode, setIsFilterMode] = useState(false);
  const [filters, setFilters] = useState({
    status: {
      value: 'active',
      label: 'Active',
    },
    form_type: null,
    submitter_type: null,
  });

  useEffect(() => {
    changeFolder();
    // eslint-disable-next-line
  }, [user, search, filters]);

  const clearFilter = () => {
    setSearch('');
    setFilters({
      status: {
        value: 'active',
        label: 'Active',
      },
      form_type: null,
      submitter_type: null,
    });
  };
  const handleFilter = (value, key) => {
    setFilters(() => {
      const prevState = { ...filters };
      prevState[key] = value;
      return prevState;
    });
  };

  const changeFolder = () => {
    const bodyRequest = { get_all: true, company_id: id, search: search };
    if (!search) {
      delete bodyRequest.search;
    }

    if (filters.status) {
      bodyRequest['status'] = filters.status.value;
    }

    if (filters.form_type) {
      bodyRequest['form_type'] = filters.form_type.value;
    }

    getSubmissionsProfileById(user_id, bodyRequest)
      .then((res) => {
        setFormsSearch(res);
      })
      .catch((e) => console.log(e));
  };

  return (
    <Layout breadcrumbs={pageBreadcrumbs} title={'User Profile - Forms'}>
      <div className={`page-wrapper ${profile.deleted_at ? 'page-wrapper-deactivated' : ''}`}>
        <div className="company-profile">
          {profile.first_name && (
            <>
              <div className="company-profile-header">
                <div className="user-profile-header">
                  <div>
                    <span className="date-add">
                      Date Added: {utcToLocalDateFormatter(profile.created_at)}{' '}
                      {profile.deleted_at && (
                        <span className="date-add-deactivated">Deactivated: {utcToLocalDateFormatter(user.deleted_at)}</span>
                      )}
                    </span>
                    <h2 className="company-profile-title">
                      {profile.last_name} {profile.first_name}
                    </h2>
                    <h3 className="company-profile-subtitle">{profile.role}</h3>
                  </div>
                </div>
                <div className="company-profile-header-action"></div>
              </div>
              <div className="company-profile-tab user-profile-tab">
                <Tabs selectedIndex={defaultIndex} onSelect={(index) => setDefaultIndex(index)}>
                  <TabList>
                    <Tab onClick={() => navigation(`/company/${id}/user/${user_id}`)}>Main Information</Tab>
                    <Tab>Forms</Tab>
                    <Tab onClick={() => navigation(`/company/${id}/users/${user_id}/related-files`)}>Related Files</Tab>
                  </TabList>
                  <TabPanel>
                    <h3>Main Information</h3>
                  </TabPanel>
                  <TabPanel>
                    <div className="library p-0">
                      <div className="library-filter">
                        <div className="library-filter-search form-control-item">
                          <input
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Search"
                          />
                          <i className="icon-search"></i>
                        </div>
                        <div>
                          <button
                            className={`btn-outline btn ${isFilterMode ? 'btn-active' : ''}`}
                            onClick={() => setIsFilterMode(!isFilterMode)}
                          >
                            <i className="icon-icon-sliders"></i>
                          </button>
                        </div>
                        <Link to={`/company/${id}/users/${user_id}/export-submissions`}>
                          <button disabled={!formsSearch?.length} className="btn" onClick={() => {}}>
                            Export to PDF
                          </button>
                        </Link>
                      </div>
                      {isFilterMode && (
                        <div className="library-filters">
                          <div className="library-filters-wrapper">
                            {/*<div className="form-item">*/}
                            {/*    <label className="form-label">Form type</label>*/}
                            {/*    <Select*/}
                            {/*        options={[*/}
                            {/*            {*/}
                            {/*                value: 'SPECIAL',*/}
                            {/*                label: 'Special'*/}
                            {/*            },*/}
                            {/*            {*/}
                            {/*                value: 'PUBLIC',*/}
                            {/*                label: 'Public'*/}
                            {/*            },*/}
                            {/*            {*/}
                            {/*                value: 'MIXED',*/}
                            {/*                label: 'Mixed'*/}
                            {/*            },*/}
                            {/*        ]}*/}
                            {/*        value={filters.form_type}*/}
                            {/*        onChange={(e) => handleFilter(e, 'form_type')}*/}
                            {/*        className="form-control-select"*/}
                            {/*        classNamePrefix="react-select"*/}
                            {/*    ></Select>*/}
                            {/*</div>*/}
                            <div className="form-item">
                              <label className="form-label">Status</label>
                              <Select
                                options={[
                                  {
                                    value: 'all',
                                    label: 'All',
                                  },
                                  {
                                    value: 'active',
                                    label: 'Active',
                                  },
                                  {
                                    value: 'archived',
                                    label: 'Archived',
                                  },
                                ]}
                                value={filters.status}
                                onChange={(e) => handleFilter(e, 'status')}
                                className="form-control-select"
                                classNamePrefix="react-select"
                              ></Select>
                            </div>
                          </div>

                          <button className="btn btn-outline" onClick={clearFilter}>
                            Clear All
                          </button>
                        </div>
                      )}

                      <>
                        {formsSearch.length > 0 && (
                          <>
                            <div className="library-wrapper">
                              {formsSearch.map((item) => (
                                <SubmissionItem
                                  key={item.id}
                                  url={`/company/${id}/user/${user_id}/forms/${item.id}`}
                                  item={item}
                                ></SubmissionItem>
                              ))}
                            </div>
                          </>
                        )}
                        {!formsSearch.length && !!search && (
                          <>
                            <div className="empty-page">
                              <h3 className="empty-page-title">Unfortunately, nothing was found for your query.</h3>
                              <h4 className="empty-page-subtitle">
                                Try to change the range of keywords. We're here to help you find what you're looking for.
                              </h4>
                            </div>
                          </>
                        )}
                        {!formsSearch.length && !search && (
                          <>
                            <div className="empty-page">
                              <h3 className="empty-page-title">This folder is empty</h3>
                              <h4 className="empty-page-subtitle">This folder currently does not contain any forms. </h4>
                            </div>
                          </>
                        )}
                      </>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default CorporateLibrary;
