import React, { useState, useMemo, useCallback, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Modal from 'react-modal';
import Select from 'react-select';
import { isResponseOk } from '../../../../utility/isResponseOk';
import { stateUSA } from '../../../../constants/state';
import { getTimeSlotService, bookTimeSlotService } from '../../../../services/schedule';
import { utcToLocalDate } from '../../../../utility/dates/utcToLocalDate';
import { isMobile } from 'react-device-detect';

import './styles.scss';

const ScheduleDemoModal = ({ onClose, onSuccess }) => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    company_name: '',
    facility_name: '',
    position: '',
    state: null,
    date: null,
    time: null,
  });

  const [timeSlots, setTimeSlots] = useState([]);
  const [errors, setErrors] = useState(null);

  const getTimeSlots = useCallback(async () => {
    try {
      const params = {
        date_after: new Date(),
        status: 'FREE',
      };

      const response = await getTimeSlotService(params);

      if (isResponseOk(response)) {
        const data = response.data
          .map((slot) => ({
            ...slot,
            ends_at: utcToLocalDate(slot.ends_at),
            starts_at: utcToLocalDate(slot.starts_at),
          }))
          .sort((a, b) => new Date(a.starts_at) - new Date(b.starts_at));

        setTimeSlots(data);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    getTimeSlots();
  }, [getTimeSlots]);

  const handleSubmit = async () => {
    const body = {
      slot: formData.time.value,
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      company_name: formData.company_name,
      facility_name: formData.facility_name,
      state: formData.state.label,
      position: formData.position,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    try {
      const response = await bookTimeSlotService(body);

      if (isResponseOk(response)) {
        onSuccess(response.data);
      } else {
        setErrors(response.response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const isFormDataValue = useMemo(() => {
    return (
      formData.first_name &&
      formData.last_name &&
      formData.company_name &&
      formData.email &&
      formData.facility_name &&
      formData.state &&
      formData.date &&
      formData.position &&
      formData.time
    );
  }, [JSON.stringify(formData)]);

  const availableTimeOptions = useMemo(() => {
    if (!formData.date) {
      return [];
    }

    return timeSlots
      .filter((slot) => slot.starts_at.toDateString() == formData.date.toDateString())
      .map((item) => ({
        value: item.id,
        label: `${item.starts_at.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })} - ${item.ends_at.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}`,
      }));
  }, [formData.date, timeSlots]);

  return (
    <Modal
      isOpen
      className="modal-default"
      onRequestClose={onClose}
      contentLabel="Add time slot"
      overlayClassName={`${isMobile ? 'is-mobile' : ''}`}
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        Schedule a Demo
        <button className="btn btn-icon" onClick={onClose}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>
      <div className="form-wrapper">
        <h3 className="form-wrapper-title">
          <img src="/images/company-add-icon-3.svg" alt="" />
          Contact Information
        </h3>

        <div className={`form-item ${!isMobile ? 'form-item-half' : ''}`}>
          <label className="form-label">Name</label>
          <input
            placeholder="Enter First Name"
            value={formData.first_name}
            onChange={(e) => setFormData((state) => ({ ...state, first_name: e.target.value }))}
            type="text"
            className="form-control"
          />
        </div>
        <div className={`form-item ${!isMobile ? 'form-item-half' : ''}`}>
          <label className="form-label">Last Name</label>
          <input
            placeholder="Enter Last Name"
            value={formData.last_name}
            onChange={(e) => setFormData((state) => ({ ...state, last_name: e.target.value }))}
            type="text"
            className="form-control"
          />
        </div>

        <div className="form-item form-item-mail">
          <label className="form-label">Email</label>
          <img className="mail-icon" src="/images/mail-icon.svg" alt="" />
          <input
            id="email"
            value={formData.email}
            onChange={(e) => setFormData((state) => ({ ...state, email: e.target.value }))}
            type="email"
            className="form-control"
            placeholder="mail@example.com"
          />
          <span className="form-error">{errors?.email && errors.email[0]}</span>
        </div>

        <div className="form-item">
          <label className="form-label">Position</label>
          <input
            placeholder="Enter Position"
            value={formData.position}
            onChange={(e) => setFormData((state) => ({ ...state, position: e.target.value }))}
            type="text"
            className="form-control"
          />
        </div>

        <div className="form-item">
          <label className="form-label">Company</label>
          <input
            placeholder="Enter Company Name"
            value={formData.company_name}
            onChange={(e) => setFormData((state) => ({ ...state, company_name: e.target.value }))}
            type="text"
            className="form-control"
          />
        </div>

        <div className="form-item">
          <label className="form-label">Facility</label>
          <input
            placeholder="Enter Facility"
            value={formData.facility_name}
            onChange={(e) => setFormData((state) => ({ ...state, facility_name: e.target.value }))}
            type="text"
            className="form-control"
          />
        </div>

        <div className="form-item form-item-select">
          <label className="form-label">State</label>
          <Select
            menuPlacement="auto"
            options={stateUSA}
            className="form-control-select"
            classNamePrefix="react-select"
            value={formData.state}
            onChange={(e) => setFormData((prevState) => ({ ...prevState, state: e }))}
            placeholder="Select State"
          />
        </div>
      </div>

      <div className="form-wrapper">
        <h3 className="form-wrapper-title">
          <img src="/images/schedule-with-circles.svg" />
          Schedule
        </h3>

        <div className={`form-item form-item-select ${!isMobile ? 'form-item-half' : ''} available-date-picker`}>
          <label className="form-label">Date</label>
          <DatePicker
            isClearable
            minDate={new Date()}
            className="form-control"
            selected={formData.date}
            placeholderText="Choose Date"
            filterDate={(date) => timeSlots.some(({ starts_at }) => starts_at.toDateString() == date.toDateString())}
            onChange={(date) => setFormData((state) => ({ ...state, date }))}
          />
        </div>
        <div className={`form-item form-item-select ${!isMobile ? 'form-item-half' : ''}`}>
          <label className="form-label">Time</label>
          <Select
            menuPlacement="auto"
            isDisabled={!formData.date}
            options={availableTimeOptions}
            className="form-control-select"
            classNamePrefix="react-select"
            value={formData.time}
            onChange={(e) => setFormData((prevState) => ({ ...prevState, time: e }))}
            placeholder="Select State"
          />
        </div>
      </div>

      <div className="modal-footer">
        <button className="btn btn-outline" onClick={onClose}>
          Cancel
        </button>
        <button className="btn" disabled={!isFormDataValue} onClick={handleSubmit}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default ScheduleDemoModal;
