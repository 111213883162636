import React, { memo, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { useDebounce } from 'use-debounce';
import './styles.scss';

const FormsModal = ({ onClose, onConfirmFormsSelection, formsSuggest, previousSelectedForms = [] }) => {
  const [search, setSearch] = useState('');
  const [selectedForms, setSelectedForms] = useState(previousSelectedForms);
  const [debouncedSearch] = useDebounce(search, 300);

  const formattedSearch = debouncedSearch.trim().toLowerCase();
  const formsSuggestLength = formsSuggest?.length ?? 0;
  const selectedFormsLength = selectedForms.length;
  const isEmptyFormsSuggest = !formsSuggestLength;

  const filteredFormsSuggest = useMemo(() => {
    let list = [];
    if (formsSuggestLength) {
      list = formsSuggest;
      if (formattedSearch.length) {
        list = list.filter(({ name }) => name.toLowerCase().includes(formattedSearch));
      }
      if (selectedForms.length) {
        list = list.map((item) => {
          return {
            ...item,
            selected: selectedForms.includes(item.id),
          };
        });
      }
    }

    return list;
  }, [formattedSearch, formsSuggest, formsSuggestLength, selectedForms]);

  const hasNoSearchResults = !!formattedSearch.length && !filteredFormsSuggest.length;

  const isAllFormsSelected = useMemo(() => {
    return Boolean(formsSuggestLength && formsSuggestLength === selectedFormsLength);
  }, [formsSuggestLength, selectedFormsLength]);

  const formattedSelectedForms = useMemo(() => {
    return formsSuggest?.filter(({ id }) => selectedForms.includes(id)) ?? [];
  }, [formsSuggest, selectedForms]);

  const handleSelectAllForms = () => {
    let newList;

    if (isAllFormsSelected) {
      newList = [];
    } else {
      newList = formsSuggest?.map(({ id }) => id);
    }

    setSelectedForms(newList);
  };

  const handleSelectForm = (formID) => {
    setSelectedForms((prevState) => {
      const isSelectedForm = prevState.includes(formID);
      return isSelectedForm ? prevState.filter((id) => id !== formID) : [...prevState, formID];
    });
  };

  const renderFormsSuggest = () => {
    return (
      <div className={'forms--suggest'}>
        <div className={`form-control-checkbox`} style={{ marginLeft: 27 }}>
          <input name={'select--all'} type="checkbox" checked={isAllFormsSelected} onChange={handleSelectAllForms} />
          <span>{'Select All '}</span>
        </div>
        <div className="forms--suggest__list">
          {filteredFormsSuggest.map(({ id, name, selected }) => {
            return (
              <div key={id} className={`form-control-checkbox`}>
                <input className={'input'} type="checkbox" checked={!!selected} onChange={() => handleSelectForm(id)} />
                <span style={{ display: 'flex', alignItems: 'stretch', color: '#101828' }}>
                  <img src="/images/file-big-icon.png" alt="" style={{ minWidth: 36, height: 36, marginLeft: 20, marginRight: 6 }} />
                  <div className={'label'}>{name}</div>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderSelectedForms = () => {
    return (
      <div className={'selected--forms'}>
        <div className="selected--forms__title">Selected:</div>
        <div className="selected--forms__list">
          {formattedSelectedForms.map(({ id, name }) => {
            return (
              <div className="item" key={id}>
                {name}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleSelectForm(id)}
                >
                  <path
                    d="M12.5003 7.4974L7.50033 12.4974M7.50033 7.4974L12.5003 12.4974M18.3337 9.9974C18.3337 14.5998 14.6027 18.3307 10.0003 18.3307C5.39795 18.3307 1.66699 14.5998 1.66699 9.9974C1.66699 5.39502 5.39795 1.66406 10.0003 1.66406C14.6027 1.66406 18.3337 5.39502 18.3337 9.9974Z"
                    stroke="#FDA29B"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderFormsContent = () => {
    let layout;

    if (isEmptyFormsSuggest || hasNoSearchResults) {
      layout = (
        <div className={'no--result'} style={{ width: '100%', textAlign: 'center' }}>
          Unfortunately, nothing was found for your query.
        </div>
      );
    } else {
      layout = (
        <>
          {renderFormsSuggest()}
          {renderSelectedForms()}
        </>
      );
    }

    return <div className={'forms--container'}>{layout}</div>;
  };

  return (
    <Modal
      isOpen
      className="modal-default pt-4 statistics-forms-modal"
      onRequestClose={onClose}
      ariaHideApp={false}
      style={{ content: { maxWidth: 824, paddingBottom: 108 } }}
    >
      <div className="modal-body" style={{ marginTop: 26 }}>
        <div className="form-control-item">
          <input
            value={search}
            type="text"
            disabled={!formsSuggest?.length}
            className="form-control"
            placeholder="Search by name..."
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <i className="icon-search"></i>
        </div>

        {renderFormsContent()}
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={onClose}>
          Cancel
        </button>
        <button className="btn d-flex" onClick={() => onConfirmFormsSelection(selectedForms)}>
          Select
        </button>
      </div>
    </Modal>
  );
};

export default memo(FormsModal);
