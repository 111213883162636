import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useDeepCompareEffect } from 'react-use';
import { notificationOptions } from '../../../../constants/forms/options';
import { STATIC_ROUTES } from '../../../../constants/routes';
import { useAuth } from '../../../../container/authContext';
import { runTaskService } from '../../../../services/tasks';
import { isResponseOk } from '../../../../utility/isResponseOk';
import SubmissionSettingsSystem from '../../components/forms/SubmissionSettingsSystem';
import SubmissionSettingsContacts from '../../components/forms/SubmissionSettingsContacts';
import SubmissionSettingsUnregistered from '../../components/forms/SubmissionSettingsUnregistered';
import RecipientSettings from '../../components/forms/RecipientsSettings';

const TaskSettingsTab = ({ task }) => {
  const submittersType = task.task_forms[0].submission_settings.submitters.submitters_type;
  const canSelectRecipients = task.task_forms[0].recipient_settings.can_initiator_select_recipients;
  const hasStrictOrderOfSubmissions = task.task_forms[0].submission_settings.has_strict_order_of_submissions;

  const { user } = useAuth();
  const {
    company: { id: companyID },
  } = user;
  const navigate = useNavigate();

  const [formData, setFormData] = useState(null);
  const [errors, setErrors] = useState({});

  useDeepCompareEffect(() => {
    const initialFormData = {
      notify_recipients: false,
      recipients_notification_type: null,
      recipients_notification_text: '',
      notify_submitters: false,
      submitters_notification_type: null,
      submitters_notification_text: '',
    };

    if (canSelectRecipients) {
      initialFormData.recipients = {
        facilities: [],
        user_groups: [],
        individual_users: [],
      };
    }

    if (!formData?.submitters) {
      switch (submittersType) {
        case 'user': {
          initialFormData.submitters = {
            facilities: [],
            user_groups: [],
            individual_users: [],
          };

          if (hasStrictOrderOfSubmissions) {
            initialFormData.submitters.strict_order = [
              {
                step: 0,
              },
              {
                step: 1,
              },
            ];
          }

          break;
        }
        case 'contact': {
          initialFormData.submitters = {
            contact_person_submitter_type: null,
            contacts: [],
            units: [],
          };
          break;
        }
        case 'unregistered': {
          initialFormData.submitters = {
            unregistered_submitters: [
              {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
              },
            ],
          };
          initialFormData.notify_submitters = true;
          break;
        }
        default:
          break;
      }
    }

    setFormData(initialFormData);
  }, [task]);

  const handleFormChange = (key, value) => {
    setFormData((prevState) => {
      const newState = _.cloneDeep(prevState);
      _.set(newState, key, value);
      return newState;
    });
  };

  const handleRunTheTask = useCallback(async () => {
    try {
      const body = {
        ...formData,
        submitters_notification_type: formData.submitters_notification_type?.value,
        recipients_notification_type: formData.recipients_notification_type?.value,
        task_id: task.id,
      };

      switch (submittersType) {
        case 'user': {
          body.submitters = {
            facilities: formData.submitters.facilities.map((item) => item.value),
            user_groups: formData.submitters.user_groups.map((item) => item.value),
            individual_users: formData.submitters.individual_users.map((item) => item.value),
          };

          if (hasStrictOrderOfSubmissions) {
            body.submitters = {
              ...body.submitters,
              has_strict_order_of_submissions: true,
              strict_order: formData.submitters.strict_order,
            };
          }
          break;
        }
        case 'contact': {
          body.submitters = {
            contacts: formData.submitters.contacts.map((item) => item.value),
            units: formData.submitters.units.map((item) => item.value),
            contact_person_submitter_type: formData.submitters.contact_person_submitter_type,
          };
          break;
        }
        case 'unregistered':
          {
            body.submitters = formData.submitters;
          }
          break;
      }

      if (formData.recipients) {
        body.recipients = {
          facilities: formData.recipients.facilities.map((facility) => facility.value),
          user_groups: formData.recipients.user_groups.map((group) => group.value),
          individual_users: formData.recipients.individual_users.map((user) => user.value),
        };
      }

      const response = await runTaskService(task.id, body);

      if (isResponseOk(response)) {
        navigate(STATIC_ROUTES.TASKS);
        toast(`The task was run successfully`, {
          icon: () => <img src="/images/full-check-circle.svg" />,
          closeButton: ({ closeToast }) => (
            <button onClick={closeToast} className="btn btn-icon close-alert-btn">
              <img src="/images/x-icon.svg" />
            </button>
          ),
          className: 'task-run-alert',
          toastClassName: 'task-run-wrapper',
          autoClose: 5000,
          hideProgressBar: false,
          progress: undefined,
          theme: 'light',
          closeOnClick: true,
          type: 'success',
        });
      } else {
        setErrors(response.response.data);
      }
    } catch (e) {
      console.log(e);
    }
  }, [task.id, JSON.stringify(formData)]);

  return (
    <div className="task-details-tab-content">
      <div className="task-settings-tab">
        <div className="task-settings-tab-header">
          <h1>{task.name}</h1>
        </div>
        {submittersType === 'user' && formData && (
          <SubmissionSettingsSystem
            formData={formData}
            task={task}
            handleFormChange={handleFormChange}
            companyID={companyID}
            formID={task.task_forms[0].form_id}
            setFormData={setFormData}
            user={user}
            errors={errors}
            hasStrictOrderOfSubmissions={hasStrictOrderOfSubmissions}
          />
        )}
        {submittersType === 'contact' && formData && (
          <SubmissionSettingsContacts
            formData={formData}
            task={task}
            handleFormChange={handleFormChange}
            companyID={companyID}
            user={user}
            errors={errors}
          />
        )}
        {submittersType === 'unregistered' && formData && (
          <SubmissionSettingsUnregistered
            formData={formData}
            task={task}
            handleFormChange={handleFormChange}
            companyID={companyID}
            user={user}
            errors={errors}
          />
        )}
        {canSelectRecipients && formData && (
          <RecipientSettings
            formData={formData}
            task={task}
            handleFormChange={handleFormChange}
            companyID={companyID}
            formID={task.task_forms[0].form_id}
            setFormData={setFormData}
            user={user}
            errors={errors}
          />
        )}

        {formData && (
          <>
            <div className="form-control-item-settings">
              <div className="form-settings-label">
                <label className="form-label form-label-big">Notify recipients when all forms in the task are completed</label>
                <span>1 notification = one submitter completed all forms.</span>
              </div>
              <div className="form-settings-inputs">
                <div className="d-flex">
                  <div className="form-control-radio">
                    <input
                      name="notify_recipients"
                      type="radio"
                      value="true"
                      onChange={() => handleFormChange('notify_recipients', true)}
                      checked={!!formData.notify_recipients}
                    />
                    <span>Yes</span>
                  </div>
                  <div className="form-control-radio">
                    <input
                      name="notify_recipients"
                      type="radio"
                      value="true"
                      onChange={() => handleFormChange('notify_recipients', false)}
                      checked={!formData.notify_recipients}
                    />
                    <span>No</span>
                  </div>
                </div>

                {formData.notify_recipients && (
                  <>
                    <div className="form-control-item-group">
                      <label className="form-label">Notification type</label>
                      <div className="select-multy-wrapper">
                        <Select
                          hideSelectedOptions={false}
                          isSearchable={false}
                          options={notificationOptions}
                          value={formData.recipients_notification_type}
                          onChange={(e) => handleFormChange('recipients_notification_type', e)}
                          className="form-control-select"
                          placeholder="Sms / Email"
                          classNamePrefix="react-select"
                        ></Select>
                      </div>
                      <span className="form-error">{errors?.recipients_notification_type && errors.recipients_notification_type[0]}</span>
                    </div>
                    <div className="form-control-item-group">
                      <label className="form-label">Notification text</label>
                      <textarea
                        onChange={(e) => handleFormChange('recipients_notification_text', e.target.value)}
                        value={formData.recipients_notification_text}
                        placeholder="Enter notification or message text that will be sent to recipients that the form is completely filled by all submitters"
                        className="form-control"
                      ></textarea>
                      <span className="form-error">{errors?.recipients_notification_text && errors.recipients_notification_text[0]}</span>
                    </div>
                  </>
                )}
              </div>
            </div>
            {!hasStrictOrderOfSubmissions && (
              <div className="form-control-item-settings">
                <div className="form-settings-label">
                  <label className="form-label form-label-big">Notify submitters about forms that are assigned to the submitter</label>
                </div>
                <div className="form-settings-inputs">
                  {submittersType !== 'unregistered' && (
                    <div className="d-flex">
                      <div className="form-control-radio">
                        <input
                          name="notify_submitters"
                          type="radio"
                          value="true"
                          onChange={() => handleFormChange('notify_submitters', true)}
                          checked={!!formData.notify_submitters}
                        />
                        <span>Yes</span>
                      </div>
                      <div className="form-control-radio">
                        <input
                          name="notify_submitters"
                          type="radio"
                          value="true"
                          onChange={() => handleFormChange('notify_submitters', false)}
                          checked={!formData.notify_submitters}
                        />
                        <span>No</span>
                      </div>
                    </div>
                  )}

                  {formData.notify_submitters && (
                    <>
                      <div className="form-control-item-group">
                        <label className="form-label">Notification type</label>
                        <div className="select-multy-wrapper">
                          <Select
                            hideSelectedOptions={false}
                            isSearchable={false}
                            options={notificationOptions}
                            value={formData.submitters_notification_type}
                            onChange={(e) => handleFormChange('submitters_notification_type', e)}
                            className="form-control-select"
                            placeholder="Sms / Email"
                            classNamePrefix="react-select"
                          ></Select>
                        </div>
                        <span className="form-error">{errors?.submitters_notification_type && errors.submitters_notification_type[0]}</span>
                      </div>
                      <div className="form-control-item-group">
                        <label className="form-label">Notification text</label>
                        <textarea
                          onChange={(e) => handleFormChange('submitters_notification_text', e.target.value)}
                          value={formData.submitters_notification_text}
                          placeholder="Enter notification or message text that will be sent to submitters that the task with forms were assigned"
                          className="form-control"
                        ></textarea>
                        <span className="form-error">{errors?.submitters_notification_text && errors.submitters_notification_text[0]}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="form-control-actions">
        <button
          className="btn btn-outline"
          onClick={() => {
            navigate(STATIC_ROUTES.TASKS);
          }}
        >
          Close
        </button>
        <button className="btn" onClick={handleRunTheTask}>
          Run the task
        </button>
      </div>
    </div>
  );
};

export default TaskSettingsTab;
