import React from 'react';
import Modal from 'react-modal';
import './styles.scss';

const DeleteBookedSlotModal = ({ closeModal, handleConfirm, data }) => {
  const handleClose = (e) => {
    e.stopPropagation();
    closeModal();
  };

  return (
    <Modal
      className="modal-alert delete-task-modal"
      isOpen
      onRequestClose={handleClose}
      contentLabel="Delete Booked Slot Modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        <img src="/images/alert-icon.svg" alt="" />
        <button className="btn btn-icon" onClick={handleClose}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>

      <div>
        <h3>Confirm time slot deletion</h3>
        <p>
          Are you sure you want to delete the time slot on{' '}
          <strong>
            {data.starts_at.toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            })}
          </strong>
          , from{' '}
          <strong>
            {data.starts_at.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })} to{' '}
            {data.ends_at.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}
          </strong>
          ? This action cannot be undone.
        </p>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={handleClose}>
          Cancel
        </button>
        <button
          className="btn btn-danger"
          onClick={(e) => {
            e.stopPropagation();
            handleConfirm();
          }}
        >
          Delete
        </button>
      </div>
    </Modal>
  );
};

export default DeleteBookedSlotModal;
