import Layout from '../../../layout/default';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import './styles.scss';
import { CNAFilterStatusOptions } from './constants';
import DatePicker from 'react-datepicker';
import { DATE_PICKER_DATE_FORMAT } from '../../../constants';
import Header from '../components/header';
import { flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { STATIC_ROUTES } from '../../../constants/routes';
import {
  getCNAAssignmentsFacilitiesService,
  getCNAAssignmentsService,
  getCNAAssignmentsUnitsService,
} from '../../../services/cna/assignments';
import { useAuth } from '../../../container/authContext';
import { isResponseOk } from '../../../utility/isResponseOk';
import PageLoader from '../../../components/other/PageLoader';
import dayjs from 'dayjs';
import { usePermission } from '../../../container/permissionContext';
import { isTablet } from 'react-device-detect';

const assignmentsPerPage = 8;

const CNAAssignmentsPage = () => {
  const { user = {} } = useAuth();
  const navigate = useNavigate();
  const { company: { id: companyID } = {}, facility: userFacility, unit_id: userUnitID, is_unit_user } = user;

  const [filters, setFilters] = useState({
    unit: userUnitID,
    facility: is_unit_user ? userFacility?.id : undefined,
    status: 'active',
  });
  const [units, setUnits] = useState(null);
  const [facilities, setFacilities] = useState(null);
  const [assignments, setAssignments] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalUnits, setTotalUnits] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { status: filteredStatus, facility: filteredFacility, unit: filteredUnit, date: filteredDate } = filters;

  const NYDateStr = useMemo(() => {
    const now = new Date();

    return new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(now);
  });

  const tableColumns = useMemo(() => {
    return [
      {
        accessorKey: 'unit',
        header: () => (
          <div>
            Unit <span className={'total-units-value'}>{`(Total: ${totalUnits} Units)`}</span>
          </div>
        ),
        cellClassName: 'unit',
      },
      {
        accessorKey: 'facility',
        header: 'Facility',
        cellClassName: 'facility',
      },
      {
        accessorKey: 'date',
        header: 'Date',
        cellClassName: 'date',
      },
    ];
  }, [totalUnits]);

  const table = useReactTable({
    data: assignments,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: totalPages,
    initialState: {
      pagination: {
        pageSize: assignmentsPerPage,
        pageIndex: 0,
      },
    },
  });

  const selectedStatusOption = useMemo(() => {
    return filteredStatus ? CNAFilterStatusOptions.find((o) => o.value === filteredStatus) : null;
  }, [filteredStatus]);

  const facilitiesOptions = useMemo(() => {
    return facilities?.map(({ id, name }) => ({ value: id, label: name }));
  }, [facilities]);
  const selectedFacilityOption = useMemo(() => {
    return filteredFacility ? facilitiesOptions?.find((o) => o.value === filteredFacility) : null;
  }, [facilitiesOptions, filteredFacility]);
  const unitsOptions = useMemo(() => {
    return units?.map(({ id, name }) => ({ value: id, label: name }));
  }, [units]);
  const selectedUnitOption = useMemo(() => {
    return filteredUnit ? unitsOptions?.find((o) => o.value === filteredUnit) : null;
  }, [filteredUnit, unitsOptions]);

  useEffect(() => {
    (async () => {
      try {
        if (companyID) {
          if (user.has_cna_permission) {
            const facilitiesResponse = await getCNAAssignmentsFacilitiesService(companyID);
            if (isResponseOk(facilitiesResponse) && facilitiesResponse.data) {
              setFacilities(facilitiesResponse.data);
            }
          } else {
            if (userFacility) {
              setFacilities([userFacility]);
              setFilters((prevState) => {
                return {
                  ...prevState,
                  facility: userFacility.id,
                };
              });
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [companyID, user.has_cna_permission, userFacility]);

  useEffect(() => {
    (async () => {
      try {
        if (filteredFacility) {
          const response = await getCNAAssignmentsUnitsService(filteredFacility);
          if (isResponseOk(response) && response.data) {
            const unitsData = response.data;
            setUnits(unitsData);
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [filteredFacility, user.has_cna_permission]);

  const handleGetAssignmentsService = useCallback(async () => {
    try {
      if (filteredFacility) {
        setIsLoading(true);

        const params = {};

        if (filteredStatus) {
          params.status = filteredStatus;
        }
        if (filteredFacility) {
          params.facility_id = filteredFacility;
        }
        if (filteredUnit) {
          params.unit_id = filteredUnit;
        }
        if (filteredDate) {
          params.date = dayjs(filteredDate).format('YYYY-MM-DD');
        }
        if (page) {
          params.page = page;
        }

        const response = await getCNAAssignmentsService(params);
        if (isResponseOk(response) && response.data) {
          const { page, results, total_pages, count: totalUnits } = response.data;
          setAssignments(results);
          setPage(page);
          setTotalPages(total_pages);
          setTotalUnits(totalUnits);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [filteredDate, filteredFacility, filteredStatus, filteredUnit, page]);

  useEffect(() => {
    handleGetAssignmentsService();
  }, [filteredStatus, filteredUnit, filteredDate, filteredFacility, page, handleGetAssignmentsService]);

  const handleChangeFilter = (key, value) => {
    setFilters((prevState) => {
      const newState = {
        ...prevState,
        [key]: value,
      };

      // Delete filtered unit if changed facility
      if (key === 'facility') {
        delete newState.unit;
      }

      return newState;
    });
  };

  const handleClearFilter = () => {
    setFilters({});
    setAssignments([]);
  };

  const handleChangePage = (e) => {
    setPage(e.selected + 1);
  };

  const renderFilters = () => {
    return (
      <>
        {isTablet ? (
          <div className={'library-filters tablet'} style={{ marginTop: is_unit_user ? 0 : undefined }}>
            <div className="library-filters-wrapper" style={{ alignItems: 'flex-end' }}>
              <div className="left-filters">
                <div className="form-item facility-filter">
                  <label className="form-label">Facility</label>
                  <Select
                    isDisabled={is_unit_user}
                    options={facilitiesOptions}
                    value={selectedFacilityOption}
                    className="form-control-select"
                    classNamePrefix="react-select"
                    onChange={(e) => handleChangeFilter('facility', e.value)}
                  />
                </div>
                <div className="form-item unit-filter">
                  <label className="form-label">Unit</label>
                  <Select
                    isDisabled={is_unit_user || !selectedFacilityOption || !unitsOptions?.length}
                    value={selectedUnitOption}
                    options={unitsOptions}
                    className="form-control-select"
                    classNamePrefix="react-select"
                    onChange={(e) => handleChangeFilter('unit', e.value)}
                  />
                </div>
              </div>
              <div className="right-filters">
                <div className="d-flex date-status-filters">
                  <div className="form-item">
                    <label className="form-label">Date</label>
                    <DatePicker
                      isClearable
                      selected={filteredDate}
                      className="form-control date-filter__input"
                      onChange={(date) => handleChangeFilter('date', date)}
                      disabledKeyboardNavigation
                      placeholderText={'Select...'}
                      dateFormat={DATE_PICKER_DATE_FORMAT}
                      disabled={selectedStatusOption?.value === 'active'}
                    />
                  </div>
                  <div className="form-item">
                    <label className="form-label">Status</label>
                    <Select
                      value={selectedStatusOption}
                      className="form-control-select"
                      classNamePrefix="react-select"
                      options={CNAFilterStatusOptions}
                      onChange={(e) => handleChangeFilter('status', e.value)}
                    />
                  </div>
                </div>
                <div className="form-item">
                  <label className="form-label"> </label>
                  <button className="btn btn-outline clear-btn" style={{ height: 40 }} onClick={handleClearFilter}>
                    Clear All
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={'library-filters'} style={{ marginTop: is_unit_user ? 0 : undefined }}>
            <div className="library-filters-wrapper" style={{ alignItems: 'flex-end' }}>
              <div className="form-item">
                <label className="form-label">Status</label>
                <Select
                  value={selectedStatusOption}
                  className="form-control-select"
                  classNamePrefix="react-select"
                  options={CNAFilterStatusOptions}
                  onChange={(e) => handleChangeFilter('status', e.value)}
                />
              </div>
              <div className="form-item">
                <label className="form-label">Facility</label>
                <Select
                  isDisabled={is_unit_user}
                  options={facilitiesOptions}
                  value={selectedFacilityOption}
                  className="form-control-select"
                  classNamePrefix="react-select"
                  onChange={(e) => handleChangeFilter('facility', e.value)}
                />
              </div>
              <div className="form-item">
                <label className="form-label">Unit</label>
                <Select
                  isDisabled={is_unit_user || !selectedFacilityOption || !unitsOptions?.length}
                  value={selectedUnitOption}
                  options={unitsOptions}
                  className="form-control-select"
                  classNamePrefix="react-select"
                  onChange={(e) => handleChangeFilter('unit', e.value)}
                />
              </div>
              <div className="form-item">
                <label className="form-label">Date</label>
                <DatePicker
                  isClearable
                  selected={filteredDate}
                  className="form-control date-filter__input"
                  onChange={(date) => handleChangeFilter('date', date)}
                  disabledKeyboardNavigation
                  placeholderText={'Select...'}
                  dateFormat={DATE_PICKER_DATE_FORMAT}
                  disabled={selectedStatusOption?.value === 'active'}
                />
              </div>
              <button className="btn btn-outline" style={{ height: 40 }} onClick={handleClearFilter}>
                Clear All
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderUnitsTable = () => {
    return (
      <div className={'table-container'}>
        <table className={'units-table'}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => {
              const { id: headerGroupId } = headerGroup;
              return (
                <tr key={headerGroupId}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id} className={header.column.columnDef.cellClassName}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {table.getRowModel().rows.length === 0 ? (
              <tr>
                <td colSpan={table.getAllColumns().length} style={{ height: 128, textAlign: 'center', verticalAlign: 'middle' }}>
                  No data available
                </td>
              </tr>
            ) : (
              table.getRowModel().rows.map((row) => {
                const { original: rowData } = row;
                return (
                  <tr
                    key={row.id}
                    className={'units-table__row'}
                    onClick={() =>
                      navigate(`${STATIC_ROUTES.CNA_ASSIGNMENTS}/${rowData.id}?is_active=${selectedStatusOption?.value == 'active'}`, {
                        state: { status: selectedStatusOption?.value },
                      })
                    }
                  >
                    {row.getVisibleCells().map((cell) => {
                      const cellCtx = cell.getContext();
                      const columnID = cellCtx.column.id;
                      let formattedValue = null;

                      if (rowData) {
                        switch (columnID) {
                          case 'unit': {
                            formattedValue = rowData.unit?.name;
                            break;
                          }
                          case 'facility': {
                            formattedValue = rowData.unit?.facility?.name;
                            break;
                          }
                          case 'date': {
                            formattedValue = filteredStatus == 'active' ? NYDateStr : rowData.date;
                            break;
                          }
                        }
                      }

                      return <td key={cell.id}>{formattedValue}</td>;
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>

        {totalPages > 1 && (
          <ReactPaginate
            className={'react-pagination'}
            breakLabel="..."
            nextLabel="Next ->"
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="<- Previous"
            onPageChange={handleChangePage}
            containerClassName="pagination"
            activeClassName="active"
          />
        )}
      </div>
    );
  };

  return (
    <Layout title="CNA Assignments">
      <div className="page-wrapper cna-assignments">
        <Header />
        {renderFilters()}
        {renderUnitsTable()}
        {isLoading && <PageLoader />}
      </div>
    </Layout>
  );
};

export default CNAAssignmentsPage;
