// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.demo-scheduled-body strong {
  color: #101828 !important;
}

.is-mobile .ReactModal__Overlay .modal-alert {
  min-width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/components/ScheduleSuccessModal/styles.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;AAAJ;;AAKE;EACE,gBAAA;AAFJ","sourcesContent":[".demo-scheduled-body {\n  strong {\n    color: #101828 !important;\n  }\n}\n\n.is-mobile {\n  .ReactModal__Overlay .modal-alert {\n    min-width: 300px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
