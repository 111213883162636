import { setHours, setMinutes } from 'date-fns';
import React, { useState, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import Modal from 'react-modal';
import Select from 'react-select';
import { DATE_PICKER_DATE_FORMAT } from '../../../../constants';
import { addTimeSlotService } from '../../../../services/schedule';
import { isResponseOk } from '../../../../utility/isResponseOk';
import { utcToLocalDate } from '../../../../utility/dates/utcToLocalDate';
import './styles.scss';

const AddTimeSlotsModal = ({ onClose, activeTab, setSlotsData }) => {
  const [formData, setFormData] = useState({
    dates: [],
    startTime: null,
    endTime: null,
    interval: null,
  });

  const handleSubmit = async () => {
    const body = {
      dates: formData.dates.map((date) => new Date(date).toLocaleDateString('en-CA')),
      start_time: formData.startTime.toLocaleTimeString('en-GB'),
      end_time: formData.endTime.toLocaleTimeString('en-GB'),
      interval: formData.interval.value,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    try {
      const response = await addTimeSlotService(body);

      if (isResponseOk(response)) {
        if (activeTab == 'schedule') {
          setSlotsData((state) => [
            ...state,
            ...response.data.map((slot) => ({ ...slot, starts_at: utcToLocalDate(slot.starts_at), ends_at: utcToLocalDate(slot.ends_at) })),
          ]);
        }
        onClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeStartTime = (value) => {
    if (formData.endTime && value.getHours() >= formData.endTime.getHours()) {
      setFormData((state) => ({ ...state, endTime: null, startTime: value }));
    } else {
      setFormData((state) => ({ ...state, startTime: value }));
    }
  };

  const isFormDataValue = useMemo(() => {
    if (formData.dates.length > 0 && formData.startTime && formData.endTime && formData.interval) {
      return true;
    }

    return false;
  }, [JSON.stringify(formData)]);

  return (
    <Modal isOpen className="modal-default" onRequestClose={onClose} contentLabel="Add time slot" ariaHideApp={false}>
      <h2 className="modal-header">
        Add new time slots for demos
        <button className="btn btn-icon" onClick={onClose}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>
      <div className="form-wrapper">
        <h3 className="form-wrapper-title">
          <img src="/images/schedule-with-circles.svg" alt="" />
          Schedule
        </h3>

        <div className="form-item form-item-select slot-date-picker">
          <label className="form-label">Date</label>
          <DatePicker
            selectsMultiple
            selectedDates={formData.dates}
            className="form-control"
            dateFormat={DATE_PICKER_DATE_FORMAT}
            placeholderText="Select dates"
            minDate={new Date()}
            shouldCloseOnSelect={false}
            onChange={(value) => {
              setFormData((state) => ({
                ...state,
                dates: value,
              }));
            }}
          />
        </div>

        <div className="time-selectors">
          <div className="form-item form-item-time">
            <label className="form-label">Start Time</label>
            <DatePicker
              className="form-control"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={60}
              timeCaption=""
              placeholderText="Choose Time"
              dateFormat="hh:mm aa"
              minTime={setHours(setMinutes(new Date(), 0), 8)}
              maxTime={setHours(setMinutes(new Date(), 0), 19)}
              selected={formData.startTime}
              onChange={handleChangeStartTime}
            />
          </div>
          <div className="form-item form-item-select form-item-time">
            <label className="form-label">End Time</label>
            <DatePicker
              className="form-control"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={60}
              timeCaption=""
              placeholderText="Choose Time"
              minTime={setHours(setMinutes(new Date(), 0), formData.startTime ? formData.startTime.getHours() : 10)}
              maxTime={setHours(setMinutes(new Date(), 0), 21)}
              dateFormat="hh:mm aa"
              selected={formData.endTime}
              onChange={(value) => setFormData((state) => ({ ...state, endTime: value }))}
            />
          </div>
        </div>
        <div className="form-item form-item-select">
          <label className="form-label">Interval</label>
          <Select
            options={[
              {
                value: 20,
                label: '20 minutes',
              },
              {
                value: 30,
                label: '30 minutes',
              },
              {
                value: 45,
                label: '45 minutes',
              },
              {
                value: 60,
                label: '1 hour',
              },
              {
                value: 90,
                label: '1 hour 30 minutes',
              },
              {
                value: 105,
                label: '1 hour 45 minutes',
              },
              {
                value: 120,
                label: '2 hours',
              },
            ]}
            className="form-control-select"
            classNamePrefix="react-select"
            value={formData.interval}
            placeholder="Choose interval"
            onChange={(e) => setFormData((state) => ({ ...state, interval: e }))}
          />
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={onClose}>
          Cancel
        </button>
        <button className="btn" disabled={!isFormDataValue} onClick={handleSubmit}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default AddTimeSlotsModal;
