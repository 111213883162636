import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { MultiSelect } from '../../../../components/other/MultiSelect';
import { getCompanyUserGroupListSettingsInit } from '../../../../services/company';
import { getUsersListSettingsInit } from '../../../../services/user';
import { useDeepCompareEffect } from 'react-use';

const RecipientSettings = ({ task, formData, handleFormChange, companyID, setFormData, user, errors }) => {
  const recipientSettings = task.task_forms[0].recipient_settings;
  const formID = task.task_forms[0].form_id;

  const [recipientsOptions, setRecipientsOptions] = useState({
    facilities: [],
    user_groups: [],
    individual_users: [],
  });

  const handleGetUserGroups = useCallback(
    async (facilitiesIDs) => {
      try {
        const result = await getCompanyUserGroupListSettingsInit({
          company_id: companyID,
          status: 'active',
          form_id: formID,
          field: 'recipient_settings',
          facilities_ids: facilitiesIDs,
        });

        setRecipientsOptions((prevState) => ({
          ...prevState,
          user_groups: result.map((group) => ({ value: group.id, label: group.name })),
        }));
      } catch (e) {
        console.log(e);
      }
    },
    [companyID],
  );

  const handleGetIndividualUsers = useCallback(
    async (facilitiesIDs, userGroupsIDs) => {
      try {
        const result = await getUsersListSettingsInit({
          company_id: companyID,
          status: 'active',
          form_id: formID,
          field: 'recipient_settings',
          facilities_ids: facilitiesIDs,
          user_groups_ids: userGroupsIDs,
        });

        setRecipientsOptions((prevState) => ({
          ...prevState,
          individual_users: result.map((user) => ({ value: user.id, label: `${user.last_name} ${user.first_name}` })),
        }));
      } catch (e) {
        console.log(e);
      }
    },
    [companyID, formID],
  );

  useDeepCompareEffect(() => {
    const facilitiesIDs = formData.recipients.facilities.map((facility) => facility.value);

    if (facilitiesIDs.length) {
      if (!recipientSettings.recipients_only_from_initiator_facility) {
        handleGetUserGroups(facilitiesIDs);
      } else {
        handleGetUserGroups(user.facility ? [user.facility.id] : facilitiesIDs);
      }
    } else {
      setRecipientsOptions((prevState) => ({ ...prevState, user_groups: [] }));
    }
  }, [handleGetUserGroups, formData.recipients.facilities]);

  useDeepCompareEffect(() => {
    const facilitiesIDs = formData.recipients.facilities.map((facility) => facility.value);
    const userGroupsIDs = formData.recipients.user_groups.map((group) => group.value);

    if (!recipientsOptions.recipients_only_from_initiator_facility) {
      handleGetIndividualUsers(facilitiesIDs, userGroupsIDs);
    } else {
      handleGetIndividualUsers(user.facility ? [user.facility.id] : facilitiesIDs, userGroupsIDs);
    }
  }, [formData.recipients.user_groups, formData.recipients.facilities, handleGetIndividualUsers]);

  useDeepCompareEffect(() => {
    if (formData.recipients.user_groups.length) {
      setFormData((prevState) => {
        const newPrevState = _.cloneDeep(prevState);
        newPrevState.recipients.user_groups = newPrevState.recipients.user_groups.filter((group) =>
          recipientsOptions.user_groups.some((item) => item.value == group.value),
        );

        return newPrevState;
      });
    }
  }, [recipientsOptions.user_groups]);

  useDeepCompareEffect(() => {
    if (formData.recipients.individual_users.length) {
      setFormData((prevState) => {
        const newPrevState = _.cloneDeep(prevState);
        newPrevState.recipients.individual_users = newPrevState.recipients.individual_users.filter((obj) =>
          recipientsOptions.individual_users.some((item) => obj.value == item.value),
        );

        return newPrevState;
      });
    }
  }, [recipientsOptions.individual_users]);

  useDeepCompareEffect(() => {
    if (!recipientSettings.recipients_only_from_initiator_facility) {
      setRecipientsOptions((prevState) => ({
        ...prevState,
        facilities: recipientSettings.facilities.map((item) => ({ value: item.id, label: item.name })),
      }));
      handleGetIndividualUsers([], []);
    } else {
      handleGetIndividualUsers(user.facility ? [user.facility.id] : []);
      handleGetUserGroups(user.facility ? [user.facility.id] : []);
    }
  }, [task, handleGetIndividualUsers]);

  return (
    <div className="form-control-item-settings">
      <div className="form-settings-label">
        <label className="form-label form-label-big">Specify recipients</label>
        <span>
          Specify facilities, user groups or individual users from pre-selected facilities, user groups, individual users by a form creator
        </span>
      </div>

      <div className="form-settings-inputs">
        <div className="form-item form-item-select mb-4">
          <label className="form-label form-label-big">Facility</label>
          <div className="select-multy-wrapper">
            <span className="select-placeholder">Choose one or several facilities</span>
            <MultiSelect
              isDisabled={!recipientsOptions.facilities.length}
              options={recipientsOptions.facilities}
              value={formData.recipients.facilities}
              onChange={(e) => handleFormChange('recipients.facilities', e)}
              placeholder="Choose one or several facilities"
              allName="All facilities"
            ></MultiSelect>
            {formData.recipients.facilities.length > 0 && (
              <>
                <ul>
                  {formData.recipients.facilities.map((item, key) => (
                    <li key={key}>
                      {item.label}
                      <button
                        className="btn btn-icon"
                        onClick={() =>
                          handleFormChange(
                            'recipients.facilities',
                            formData.recipients.facilities.filter((facility) => facility.value !== item.value),
                          )
                        }
                      >
                        <img src="/images/x-circle.svg" alt="" />
                      </button>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
          <span className="form-error mt-1 d-flex">{errors?.recipients?.facilities && errors.recipients?.facilities[0]}</span>
        </div>
        <div className={`form-item form-item-select mb-4`}>
          <label className="form-label form-label-big">User groups</label>
          <div className="select-multy-wrapper">
            <span className="select-placeholder">Choose one or several user groups</span>
            <MultiSelect
              isDisabled={!recipientsOptions.user_groups.length}
              options={recipientsOptions.user_groups}
              value={formData.recipients.user_groups}
              onChange={(e) => handleFormChange('recipients.user_groups', e)}
              allName="All user groups"
            ></MultiSelect>
            {formData.recipients.user_groups.length > 0 && (
              <>
                <ul>
                  {formData.recipients.user_groups.map((item, key) => (
                    <li key={key}>
                      {item.label}
                      <button
                        className="btn btn-icon"
                        onClick={() =>
                          handleFormChange(
                            'recipients.user_groups',
                            formData.recipients.user_groups.filter((group) => group.value != item.value),
                          )
                        }
                      >
                        <img src="/images/x-circle.svg" alt="" />
                      </button>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
          <span className="form-error mt-1 d-flex">{errors?.recipients?.user_groups && errors.recipients?.user_groups[0]}</span>
        </div>

        <div className="form-item form-item-select mb-4">
          <label className="form-label form-label-big">Individual Users</label>
          <div className="select-multy-wrapper">
            <span className="select-placeholder">Choose one or several individual_users</span>
            <MultiSelect
              isDisabled={!recipientsOptions.individual_users.length}
              options={recipientsOptions.individual_users}
              value={formData.recipients.individual_users}
              onChange={(e) => handleFormChange('recipients.individual_users', e)}
              allName="All individual users"
            ></MultiSelect>
            {formData.recipients.individual_users.length > 0 && (
              <>
                <ul className="individual-users-list">
                  {formData.recipients.individual_users
                    .sort(function (a, b) {
                      if (a.label < b.label) {
                        return -1;
                      }
                      if (a.label > b.label) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((item, key) => (
                      <li key={key}>
                        {item.label}
                        <button
                          className="btn btn-icon"
                          onClick={() =>
                            handleFormChange(
                              'recipients.individual_users',
                              formData.recipients.individual_users.filter((user) => user.value !== item.value),
                            )
                          }
                        >
                          <img src="/images/x-circle.svg" alt="" />
                        </button>
                      </li>
                    ))}
                </ul>
              </>
            )}
            <span className="form-error mt-1 d-flex">{errors?.recipients?.individual_users && errors.recipients?.individual_users[0]}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipientSettings;
