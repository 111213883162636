import React from 'react';

export const cnaColors = [
  {
    bg: '#FFECB8',
    border: '#7A6D1A',
  },
  {
    bg: '#CFFCEF',
    border: '#2B9462',
  },
  {
    bg: '#FFF9A6',
    border: '#7A6D1A',
  },
  {
    bg: '#CFF5FF',
    border: '#05A1D6',
  },
  {
    bg: '#FFBFE0',
    border: '#B00066',
  },
  {
    bg: '#FFCCC9',
    border: '#AB0000',
  },
  {
    bg: '#C7D6FF',
    border: '#2B489C',
  },
  {
    bg: '#EED6D6',
    border: '#181F29',
  },
  {
    bg: '#D4E9B8',
    border: '#566727',
  },
  {
    bg: '#F2D7BE',
    border: '#7A4A1A',
  },
];

export const tableColumns = [
  {
    header: 'CNA',
    accessorKey: 'cna',
    cellClassName: 'cna',
  },
  {
    header: 'Room',
    accessorKey: 'room',
    cellClassName: 'room',
  },
  {
    header: 'Resident',
    accessorKey: 'resident',
    cellClassName: 'resident',
  },
  {
    header: 'Tasks',
    accessorKey: 'tasks',
    cellClassName: 'tasks',
  },
];
