// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table .thead .th {
  padding: 13px 24px;
}
.table .tbody .tr .td {
  padding: 26px 24px;
}
.table .tbody .tr .delete-slot-btn {
  visibility: hidden;
}
.table .tbody .tr .delete-slot-btn:hover {
  opacity: 0.7;
}
.table .tbody .tr .delete-slot-btn i::before {
  color: #b42318 !important;
}
.table .tbody .tr:hover .delete-slot-btn {
  visibility: visible;
}

.slot-deleted-alert {
  width: max-content;
  right: 150px;
}
.slot-deleted-alert .Toastify__toast-body {
  font-size: 18px;
  color: var(--Secondary-eading, #101828);
  font-weight: 600;
}
.slot-deleted-alert .Toastify__toast-body .Toastify__toast-icon {
  margin-right: 40px;
}
.slot-deleted-alert .Toastify__progress-bar--success {
  background: #12b76a !important;
}
.slot-deleted-alert .close-alert-btn {
  margin: 0 10px;
}
.slot-deleted-alert .close-alert-btn:hover {
  opacity: 0.3;
}`, "",{"version":3,"sources":["webpack://./src/pages/schedule/components/BookedSlotsTab/styles.scss"],"names":[],"mappings":"AAEI;EACE,kBAAA;AADN;AAOM;EACE,kBAAA;AALR;AAOM;EACE,kBAAA;AALR;AAOQ;EACE,YAAA;AALV;AASU;EACE,yBAAA;AAPZ;AAaQ;EACE,mBAAA;AAXV;;AAkBA;EAcE,kBAAA;EACA,YAAA;AA5BF;AAcE;EACE,eAAA;EACA,uCAAA;EACA,gBAAA;AAZJ;AAcI;EACE,kBAAA;AAZN;AAeE;EACE,8BAAA;AAbJ;AAmBE;EAKE,cAAA;AArBJ;AAiBI;EACE,YAAA;AAfN","sourcesContent":[".table {\n  .thead {\n    .th {\n      padding: 13px 24px;\n    }\n  }\n\n  .tbody {\n    .tr {\n      .td {\n        padding: 26px 24px;\n      }\n      .delete-slot-btn {\n        visibility: hidden;\n\n        &:hover {\n          opacity: 0.7;\n        }\n\n        i {\n          &::before {\n            color: #b42318 !important;\n          }\n        }\n      }\n\n      &:hover {\n        .delete-slot-btn {\n          visibility: visible;\n        }\n      }\n    }\n  }\n}\n\n.slot-deleted-alert {\n  .Toastify__toast-body {\n    font-size: 18px;\n    color: var(--Secondary-eading, #101828);\n    font-weight: 600;\n\n    .Toastify__toast-icon {\n      margin-right: 40px;\n    }\n  }\n  .Toastify__progress-bar--success {\n    background: #12b76a !important;\n  }\n\n  width: max-content;\n  right: 150px;\n\n  .close-alert-btn {\n    &:hover {\n      opacity: 0.3;\n    }\n\n    margin: 0 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
