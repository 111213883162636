import client from '../api';
import { CancelToken } from 'axios';
import qs from 'qs';

let cancel;

let cancelForms;

export const getTasksService = async (params) => {
  if (cancel != undefined) {
    cancel();
  }

  try {
    return await client.get(`/api/tasks/`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getAvailableFormsService = async (params) => {
  if (cancelForms != undefined) {
    cancelForms();
  }

  try {
    return await client.get('/api/tasks/available-forms', {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      cancelToken: new CancelToken(function executor(c) {
        cancelForms = c;
      }),
    });
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const createTaskService = async (body) => {
  if (cancelForms != undefined) {
    cancelForms();
  }

  try {
    return await client.post('/api/tasks/', body);
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const partialUpdateTaskService = async (body, taskID) => {
  if (cancelForms != undefined) {
    cancelForms();
  }

  if (!taskID || !body) {
    return;
  }

  try {
    return await client.patch(`/api/tasks/${taskID}/`, body);
  } catch (e) {
    console.log(e);
  }
};

export const deleteTaskService = async (taskID) => {
  if (cancelForms != undefined) {
    cancelForms();
  }

  if (!taskID) {
    return;
  }

  try {
    return await client.delete(`/api/tasks/${taskID}/`);
  } catch (e) {
    console.log(e);
  }
};

export const retrieveTaskService = async (taskID) => {
  if (!taskID) {
    return;
  }

  try {
    return await client.get(`/api/tasks/${taskID}/`);
  } catch (e) {
    return;
  }
};

export const runTaskService = async (taskID, body) => {
  if (!taskID) {
    return;
  }

  try {
    return await client.post(`/api/tasks/${taskID}/initiate/`, body);
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getTaskInitiationForms = async (taskInitiationID) => {
  if (!taskInitiationID) {
    return;
  }

  try {
    return await client.get(`/api/tasks/initiations/${taskInitiationID}/`);
  } catch (e) {
    console.log(e);
    return e;
  }
};
