import React, { useCallback, useEffect, useState } from 'react';
import { MultiSelect } from '../../../../components/other/MultiSelect';
import { getContactsListSettings } from '../../../../services/contacts';
import { getUnitListSettings } from '../../../../services/units';
import { useAuth } from '../../../../container/authContext';
import { useDeepCompareEffect } from 'react-use';

const SubmissionSettingsContacts = ({ task, formData, handleFormChange, companyID, user, errors }) => {
  const [submittersOptions, setSubmittersOptions] = useState({ contacts: [], units: [] });

  const handleGetContacts = useCallback(
    async (unitsIDs, facilitiesIDs) => {
      try {
        const response = await getContactsListSettings({
          company_id: companyID,
          status: 'active',
          facilities_ids: facilitiesIDs,
          units_ids: unitsIDs,
        });

        setSubmittersOptions((prevState) => ({
          ...prevState,
          contacts: response.map((item) => ({ value: item.id, label: item.last_name + ' ' + item.first_name })),
        }));
      } catch (e) {
        console.log(e);
      }
    },
    [companyID],
  );

  const handleGetUnits = useCallback(
    async (facilitiesIDs) => {
      try {
        const response = await getUnitListSettings({
          company_id: companyID,
          status: 'active',
          facilities_ids: facilitiesIDs,
        });

        setSubmittersOptions((prevState) => ({ ...prevState, units: response.map((item) => ({ value: item.id, label: item.name })) }));
      } catch (e) {
        console.log(e);
      }
    },

    [companyID],
  );

  useDeepCompareEffect(() => {
    handleGetContacts(user.facility ? [user.facility.id] : []);
    handleGetUnits(user.facility ? [user.facility.id] : []);
  }, [task]);

  return (
    <div className="form-builder-setting-tab-content">
      <div className="form-builder-recipient-settings">
        <p className="info-text">
          <i className="icon-icon-info"></i> Choose submitters and submission rules.
        </p>
        <div className="form-control-item-settings">
          <div className="form-settings-label">
            <label className="form-label form-label-big">Specify Submitters (required)</label>
            <span>Submitters are users to whom you are sending this task.</span>
          </div>
          <div className="form-settings-inputs">
            <>
              <div className={`form-item form-item-select mb-4`}>
                <label className="form-label form-label-big">Unit</label>
                <div className="select-multy-wrapper">
                  <span className="select-placeholder">Choose one or several unit</span>
                  <MultiSelect
                    isDisabled={!submittersOptions.units.length}
                    options={submittersOptions.units}
                    value={formData.submitters.units}
                    onChange={(e) => handleFormChange('submitters.units', e)}
                    placeholder=""
                    allName="All Units"
                  ></MultiSelect>
                  {formData.submitters.units.length > 0 && (
                    <>
                      <ul>
                        {formData.submitters.units.map((item, key) => (
                          <li key={key}>
                            {item.label}
                            <button
                              className="btn btn-icon"
                              onClick={() =>
                                handleFormChange(
                                  'submitters.units',
                                  formData.submitters.units.filter((unit) => unit.value !== item.value),
                                )
                              }
                            >
                              <img src="/images/x-circle.svg" alt="" />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
                <span className="form-error">{errors?.submitters?.units && errors.submitters.units[0]}</span>
              </div>

              <div className={`form-item form-item-select mb-4`}>
                <label className="form-label form-label-big">Residents</label>
                <div className="select-multy-wrapper">
                  <span className="select-placeholder">Choose one or several residents</span>
                  <MultiSelect
                    isDisabled={!submittersOptions.contacts.length}
                    options={submittersOptions.contacts}
                    value={formData.submitters.contacts}
                    onChange={(e) => handleFormChange('submitters.contacts', e)}
                    placeholder=""
                    allName="All Residents"
                  ></MultiSelect>
                  {formData.submitters.contacts.length > 0 && (
                    <>
                      <ul className={'individual-users-list'}>
                        {formData.submitters.contacts.map((item, key) => (
                          <li key={key}>
                            {item.label}
                            <button
                              className="btn btn-icon"
                              onClick={() =>
                                handleFormChange(
                                  'submitters.contacts',
                                  formData.submitters.contacts.filter((contact) => contact.value !== item.value),
                                )
                              }
                            >
                              <img src="/images/x-circle.svg" alt="" />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  <span className="form-error">{errors?.submitters?.contacts && errors.submitters.contacts[0]}</span>
                </div>
              </div>
            </>
          </div>
        </div>
        <div className="form-control-item-settings">
          <div className="form-settings-label">
            <label className="form-label form-label-big">Define form recievers</label>
            <span>
              If both are specified, it means that the task will be sent not only to selected residents, but also to all of their
              representatives
            </span>
          </div>
          <div className="form-settings-inputs">
            <div className="d-flex">
              <div className={`form-control-radio`}>
                <input
                  name="contact_person_submitter_type"
                  type="radio"
                  onChange={() => handleFormChange('submitters.contact_person_submitter_type', 'contact')}
                  checked={formData.submitters.contact_person_submitter_type === 'contact'}
                />
                <span>Residents</span>
              </div>
              <div className={`form-control-radio`}>
                <input
                  name="contact_person_submitter_type"
                  type="radio"
                  onChange={() => handleFormChange('submitters.contact_person_submitter_type', 'representative')}
                  checked={formData.submitters.contact_person_submitter_type === 'representative'}
                />
                <span>Representatives </span>
              </div>
              <div className={`form-control-radio`}>
                <input
                  name="contact_person_submitter_type"
                  type="radio"
                  onChange={() => handleFormChange('submitters.contact_person_submitter_type', 'both')}
                  checked={formData.submitters.contact_person_submitter_type === 'both'}
                />
                <span>Both</span>
              </div>
            </div>
            <span className="form-error mb-2 d-flex">
              {errors?.submitters?.contact_person_submitter_type && errors.submitters?.contact_person_submitter_type[0]}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmissionSettingsContacts;
