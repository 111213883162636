import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../layout/default';
import { useNavigate, useParams } from 'react-router-dom';
import { copyFacilityApi, getCompanyUserGroupList } from '../../services/company';
import { getState, redirectToLibrary } from '../../utility';
import {
  activatedFacility,
  deactivateFacility,
  deleteFacility,
  getFacilitiesByCompany,
  getFacilitiesById,
  updateFacility,
} from '../../services/facility';
import { getUsersList, importUser } from '../../services/user';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import UserTab from '../../components/company/user-tab';
import UserModal from '../../components/company/user-modal';
import UserGroupTab from '../../components/company/user-group-tab';
import UserGroupModal from '../../components/company/user-group-modal';
import FileTab from '../../components/company/file-tab';
import useOutsideClick from '../../hook/useOutsideClick';
import FacilityModal from '../../components/company/facility-modal';
import DeactivateModal from '../../components/company/deactivate-modal';
import { usePermission } from '../../container/permissionContext';
import ImportModal from '../../components/company/import-modal';
import CopyModal from '../../components/company/copy-modal';
import { utcToLocalDateFormatter } from '../../utility/dates/utcToLocalDate';
import SettingsTab from './components/SettingsTab';

function FacilityProfile() {
  const { facility_id, id } = useParams();
  const [facility, setFacility] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [users, setUsers] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [defaultIndex, setDefaultIndex] = useState(0);
  const [userGroupPage, setUserGroupPage] = useState(1);
  const [totalGroupPage, setTotalGroupPage] = useState(1);
  const [totalGroup, setTotalGroup] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [userPage, setUserPage] = useState(1);
  const [totalUserPage, setTotalUserPage] = useState(1);
  const [modalEditIsOpen, setIsOpenEdit] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const ref = useRef();
  const [deactivateIsOpen, setIsOpenDeactivate] = useState(false);
  const [userSearch, setUserSearch] = useState('');
  const [userGroupSearch, setUserGroupSearch] = useState('');
  const navigate = useNavigate();
  const { isEmployee } = usePermission();
  const [errorFile, setErrorFile] = useState({});
  const [deleteIsOpen, setIsOpenDelete] = useState(false);
  const { isSuperAdmin } = usePermission();
  const handleDeleteIsOpen = () => {
    setIsOpenDelete(false);
  };
  useEffect(() => {
    if (isEmployee()) {
      redirectToLibrary(navigate);
    }
  }, [isEmployee, navigate]);
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState([
    {
      url: '/companies/',
      name: 'Company List',
    },
    {
      url: `/company/${id}`,
      name: 'Company',
    },
    {
      url: `/company/${id}/facility/${facility_id}`,
      name: 'Facility Profile',
    },
  ]);
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  useOutsideClick(ref, closeDropdown);
  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, [id, userSearch, userGroupSearch, facility_id]);

  useEffect(() => {
    handelCreateUserGroup();
    // eslint-disable-next-line
  }, [userGroupPage]);
  useEffect(() => {
    handelCreateUser();
    // eslint-disable-next-line
  }, [userPage]);

  const initPage = () => {
    getFacilitiesById(facility_id)
      .then((res) => {
        setFacility(res);
        console.log(res);
        setPageBreadcrumbs([
          {
            url: '/companies/',
            name: 'Company List',
          },
          {
            url: `/company/${id}`,
            name: res.company.name,
          },
          {
            url: `/company/${id}/facility/${facility_id}`,
            name: res.name,
          },
        ]);
        getFacilitiesByCompany(id, 'getAll')
          .then((resFacilities) => {
            setFacilities(resFacilities);
            getUsersList(id, { page: userPage, search: userSearch, status: 'active', facilities_ids: [facility_id] })
              .then((resUsers) => {
                setUsers(resUsers.results);
                setTotalUser(resUsers.count);
                setTotalUserPage(resUsers.total_pages);
                getCompanyUserGroupList(id, {
                  page: userGroupPage,
                  search: userGroupSearch,
                  status: 'active',
                  facilities_ids: [facility_id],
                })
                  .then((res) => {
                    setUserGroups(res.results);
                    setTotalGroup(res.count);
                    setTotalGroupPage(res.total_pages);
                  })
                  .catch((e) => console.log(e));
              })
              .catch((e) => console.log(e));
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  };

  const openAddModal = () => {
    setIsOpen(true);
  };

  function closeAddModal() {
    setIsOpen(false);
  }

  const handelCreateUser = () => {
    initPage();
    closeAddModal();
  };

  const handelCreateUserGroup = () => {
    initPage();
    closeAddModal();
  };

  const handlePageUserGroupClick = (event) => {
    setUserGroupPage(event.selected + 1);
  };
  const handlePageUserClick = (event) => {
    setUserPage(event.selected + 1);
  };

  const handleActivate = () => {
    activatedFacility(facility.id)
      .then((res) => initPage())
      .catch((e) => console.log(e));
  };

  const handelDeactivate = () => {
    deactivateFacility(facility.id)
      .then((res) => initPage())
      .catch((e) => console.log(e));
    handleDeactivateIsOpen();
  };
  const handelDelete = (facility) => {
    deleteFacility(facility.id)
      .then((res) => navigate(`/company/${id}`))
      .catch((e) => console.log(e));
  };
  const openEditModal = () => {
    setIsOpenEdit(true);
  };

  function closeEditModal() {
    setIsOpenEdit(false);
  }

  function handelCreateFacility() {
    closeEditModal();
    initPage();
  }

  const handleDeactivateIsOpen = () => {
    setIsOpenDeactivate(false);
  };

  const removeFile = (id, key) => {
    setErrorFile(null);
    const formDate = {};
    if (key === 'contract_file') {
      formDate['contract_file'] = null;
    } else if ('other_files') {
      formDate['other_files'] = [...facility.other_files.filter((item) => item.uuid !== id).map((item) => item.uuid)];
    }

    updateFacility(formDate, facility.id)
      .then((res) => {
        initPage(res);
      })
      .catch((e) => {
        if (e?.response?.data) {
          setErrorFile(JSON.parse(JSON.stringify(e.response.data)));
        }
      });
  };

  const addOther = (res) => {
    const formDate = {};
    formDate['other_files'] = [...facility.other_files.map((item) => item.uuid), res.uuid];
    updateFacility(formDate, facility.id)
      .then((res) => {
        initPage(res);
      })
      .catch((e) => {
        if (e?.response?.data) {
          setErrorFile(JSON.parse(JSON.stringify(e.response.data)));
        }
      });
  };

  const [importModalIsOpen, setImportModalIsOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [errors, setErrors] = useState(null);

  const handelImportUser = (file) => {
    if (!file) {
      setIsPending(false);
      setErrors(null);
      return;
    }

    setIsPending(true);
    setErrors(null);
    const formData = new FormData();
    formData.append('file', file.file);

    importUser(formData, id)
      .then((res) => {
        initPage();
        closeImportModal();
        setIsPending(false);
      })
      .catch((e) => {
        if (e?.response?.data) {
          setErrors(e?.response?.data);
        }
        setIsPending(false);
      });
  };

  function closeImportModal() {
    setImportModalIsOpen(false);
  }

  const [copyFacilityOpen, setCopyFacilityOpen] = useState(false);
  const [copyError, setCopyError] = useState([]);
  const handleCopyIsClose = () => {
    setCopyFacilityOpen(false);
    setCopyError([]);
  };
  const handelCopy = () => {
    copyFacilityApi(facility.id, {
      should_copy_positions: true,
      should_copy_user_groups: true,
    })
      .then((res) => {
        handleCopyIsClose();
        navigate(`/company/${id}/facility/${res.id}`);
      })
      .catch((e) => {
        if (e?.response?.data) {
          setCopyError(e.response.data);
        }
      });
  };

  return (
    <Layout breadcrumbs={pageBreadcrumbs} title={'Facility Profile'}>
      <div className={`page-wrapper ${facility.deleted_at ? 'page-wrapper-deactivated' : ''}`}>
        <div className="company-profile">
          {facility.name && (
            <>
              <div className="company-profile-header">
                <div>
                  <span className="date-add">
                    Date Added: {utcToLocalDateFormatter(facility.created_at)}{' '}
                    {facility.deleted_at && (
                      <span className="date-add-deactivated">Deactivated: {utcToLocalDateFormatter(facility.deleted_at)}</span>
                    )}
                  </span>
                  <h2 className="company-profile-title">
                    {facility.name} <span className="facility-title">({facility.company.name})</span>
                  </h2>
                  <h3 className="company-profile-subtitle">{facility.nickname} </h3>
                </div>
                <div className="d-flex">
                  {facility.deleted_at && (
                    <button className="btn btn-outline btn-success mr-2" onClick={handleActivate}>
                      <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1484_3438)">
                          <path
                            d="M18.3332 9.2333V9.99997C18.3321 11.797 17.7503 13.5455 16.6743 14.9848C15.5983 16.4241 14.0859 17.477 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8121C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0233 1.56633 11.24 1.68457 9.4469C1.80281 7.65377 2.49751 5.94691 3.66507 4.58086C4.83263 3.21482 6.41049 2.26279 8.16333 1.86676C9.91617 1.47073 11.7501 1.65192 13.3915 2.3833M18.3332 3.3333L9.99984 11.675L7.49984 9.17497"
                            stroke="#12B76A"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1484_3438">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      Activate
                    </button>
                  )}

                  {!facility.deleted_at && (
                    <>
                      <button className="btn btn-outline mr-2" onClick={openEditModal}>
                        <i className="icon-icon-edit mr-2"></i>Edit
                      </button>
                      <div className="dropdown">
                        <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="btn btn-outline">
                          <img src="/images/more-vertical.svg" alt="" />
                        </button>
                        {isDropdownOpen && (
                          <ul className="dropdown-menu" ref={ref}>
                            <li>
                              <button onClick={() => setCopyFacilityOpen(true)} className="btn btn-left btn-full btn-outline">
                                <i className="icon-copy mr-2"></i>Copy
                              </button>
                            </li>
                            <li>
                              <button className="btn btn-left btn-full btn-outline" onClick={() => setIsOpenDeactivate(true)}>
                                <i className="icon-icon-x mr-2"></i>Deactivate
                              </button>
                            </li>
                            {isSuperAdmin && (
                              <li>
                                <button className="btn btn-left btn-full btn-danger btn-outline" onClick={() => setIsOpenDelete(true)}>
                                  <i className="icon-remove mr-2"></i>Delete
                                </button>
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="company-profile-content">
                <div className="company-profile-person">
                  <h3 className="company-profile-secondary-text">State:</h3>
                  <ul>
                    <li>
                      <strong>{getState(facility.state_of_location).label}</strong>
                    </li>
                  </ul>

                  <h3 className="company-profile-secondary-text">Address:</h3>
                  <ul>
                    <li>
                      <strong>{facility.address}</strong>
                    </li>
                  </ul>
                </div>
                <div className="company-profile-person">
                  <h3 className="company-profile-secondary-text">License Number:</h3>
                  <ul>
                    <li>
                      <strong>{facility.license_number}</strong>
                    </li>
                  </ul>

                  <h3 className="company-profile-secondary-text">Monthly fee:</h3>
                  <ul>
                    <li>
                      <strong>${facility.monthly_fee}</strong>
                    </li>
                  </ul>
                </div>

                <div className="company-profile-person">
                  <h3 className="company-profile-secondary-text">Contract Signed by:</h3>
                  <ul>
                    <li>
                      <strong>
                        {facility.signer_name} {facility.signer_lastname}
                      </strong>
                    </li>
                    <li>{facility.signer_phone}</li>
                    <li>
                      <a href={`mailto:${facility.contact_person_email}`}>{facility.signer_email}</a>
                    </li>
                  </ul>
                </div>
                <div className="company-profile-person">
                  <h3 className="company-profile-secondary-text">Contact Person:</h3>
                  <ul>
                    <li>
                      <strong>
                        {facility.contact_person_name} {facility.contact_person_lastname}
                      </strong>
                    </li>
                    <li>{facility.contact_person_phone}</li>
                    <li>
                      <a href={`mailto:${facility.contact_person_email}`}>{facility.contact_person_email}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="company-profile-tab">
                <Tabs selectedIndex={defaultIndex} onSelect={(index) => setDefaultIndex(index)}>
                  <TabList>
                    <Tab>Users</Tab>
                    <Tab>All User Groups</Tab>
                    <Tab>Related Files</Tab>
                    <Tab>Settings</Tab>
                  </TabList>
                  <TabPanel>
                    {/*<h3 className="company-profile-title">Users</h3>*/}
                    <ImportModal
                      handelError={setErrors}
                      isPending={isPending}
                      onSubmit={handelImportUser}
                      errors={errors}
                      closeModal={closeImportModal}
                      isOpen={importModalIsOpen}
                      title={'Import Users from File'}
                    ></ImportModal>
                    <UserTab
                      openImport={setImportModalIsOpen}
                      onSearch={setUserSearch}
                      totalUser={totalUser}
                      isShortTable
                      totalPage={totalUserPage}
                      handlePageClick={handlePageUserClick}
                      users={users}
                      openAddModal={openAddModal}
                    ></UserTab>
                    <UserModal
                      facility={facility}
                      facilities={facilities}
                      closeModal={closeAddModal}
                      companyId={id}
                      isOpen={modalIsOpen}
                      openModal={openAddModal}
                      handelCreateUser={handelCreateUser}
                    />
                  </TabPanel>
                  <TabPanel>
                    {/*<h3 className="company-profile-title">Users Groups</h3>*/}
                    <UserGroupTab
                      onSearch={setUserGroupSearch}
                      totalItem={totalGroup}
                      totalPage={totalGroupPage}
                      handlePageClick={handlePageUserGroupClick}
                      userGroup={userGroups}
                      openAddModal={openAddModal}
                    />
                    <UserGroupModal
                      facility={facility}
                      facilities={facilities}
                      closeModal={closeAddModal}
                      isOpen={modalIsOpen}
                      openModal={openAddModal}
                      handelCreateUserGroup={handelCreateUserGroup}
                    />
                  </TabPanel>
                  <TabPanel>
                    <FileTab updated={initPage} removeFile={removeFile} error={errorFile} addOther={addOther} company={facility} />
                  </TabPanel>
                  <TabPanel>
                    <SettingsTab facility={facility} setFacility={setFacility} />
                  </TabPanel>
                </Tabs>
              </div>
            </>
          )}
        </div>
        {modalEditIsOpen && (
          <FacilityModal
            editMode={facility}
            facilityData={facility}
            companyId={id}
            isOpen={modalEditIsOpen}
            openModal={openEditModal}
            closeModal={closeEditModal}
            handelCreateFacility={handelCreateFacility}
          />
        )}

        <DeactivateModal
          isOpen={deactivateIsOpen}
          closeModal={handleDeactivateIsOpen}
          handelSuccess={handelDeactivate}
          title="Facility Profile Deactivation"
          subtitle="Deactivation will temporarily hide this facility from the system. You can reactivate it anytime in this facility profile."
        ></DeactivateModal>

        <DeactivateModal
          isOpen={deleteIsOpen}
          closeModal={handleDeleteIsOpen}
          handelSuccess={handelDelete}
          title="Confirm Facility deletion"
          button={'Delete'}
          subtitle="This action is irreversible and will permanently remove all associated entities. Deleted information cannot be restored. "
        ></DeactivateModal>

        <CopyModal
          isOpen={copyFacilityOpen}
          closeModal={handleCopyIsClose}
          title={'Copy Facility'}
          subtitle={'Are you sure you want to copy this facility?'}
          button={'Copy'}
          handelSuccess={handelCopy}
          errors={copyError}
        />
      </div>
    </Layout>
  );
}

export default FacilityProfile;
