import React, { useCallback, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import { deleteTimeSlotService, getTimeSlotService } from '../../../../services/schedule';
import { getState } from '../../../../utility';
import { utcToLocalDate } from '../../../../utility/dates/utcToLocalDate';
import { isResponseOk } from '../../../../utility/isResponseOk';
import DeleteBookedSlotModal from '../DeleteBookedSlotModal';
import './styles.scss';

const BookedSlotsTab = ({ slotsData, setSlotsData }) => {
  const [page, setPage] = useState(0);
  const [deleteBookedSlotData, setDeleteBookedSlotData] = useState(null);
  const { total_pages: totalPages, results: bookedSlots, count } = slotsData;

  const getBookedSlots = useCallback(async () => {
    try {
      const params = {
        page: page + 1,
        status: 'BOOKED',
      };

      const response = await getTimeSlotService(params);

      if (isResponseOk(response)) {
        setSlotsData({
          ...response.data,
          results: response.data.results.map((item) => ({
            ...item,
            starts_at: utcToLocalDate(item.starts_at),
            ends_at: utcToLocalDate(item.ends_at),
          })),
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [page]);

  useEffect(() => {
    getBookedSlots();
  }, [getBookedSlots]);

  const handleDeleteSlot = useCallback(async () => {
    try {
      const deleteSlotID = deleteBookedSlotData.id;
      const body = {
        ids: [deleteSlotID],
      };
      const response = await deleteTimeSlotService(body);

      if (isResponseOk(response)) {
        toast(`The time slot was deleted successfully`, {
          icon: () => <img src="/images/full-check-circle.svg" />,
          closeButton: ({ closeToast }) => (
            <button onClick={closeToast} className="btn btn-icon close-alert-btn">
              <img src="/images/x-icon.svg" />
            </button>
          ),
          className: 'slot-deleted-alert',
          toastClassName: 'slot-deleted-alert-wrapper',
          autoClose: 5000,
          hideProgressBar: false,
          progress: undefined,
          theme: 'light',
          closeOnClick: true,
          pauseOnHover: true,
          type: 'success',
        });
        setDeleteBookedSlotData(null);
        getBookedSlots();
      }
    } catch (e) {
      console.log(e);
    }
  }, [deleteBookedSlotData, getBookedSlots]);

  return (
    <>
      <div className="table-responsive table-default table-full">
        <div className="table-wrapper">
          <div className="table">
            <div className="thead">
              <div className="tr">
                <div className="th">Name</div>
                <div className="th">Company</div>
                <div className="th">Facility</div>
                <div className="th">Position</div>
                <div className="th">State</div>
                <div className="th">Email</div>
                <div className="th">Date</div>
                <div className="th">Time</div>
                <div className="th"></div>
              </div>
            </div>

            <div className="tbody">
              {bookedSlots?.length > 0 &&
                bookedSlots.map(({ user, ...slot }) => {
                  return (
                    <div className="tr cursor-pointer" key={slot.id}>
                      <div className="td">{`${user.first_name} ${user.last_name}`}</div>
                      <div className="td">{user.company_name}</div>
                      <div className="td">{user.facility_name}</div>
                      <div className="td">{user.position}</div>
                      <div className="td">{getState(user.state).label}</div>
                      <div className="td">{user.email}</div>
                      <div className="td">
                        {slot.starts_at.toLocaleDateString('en-US', {
                          month: '2-digit',
                          day: '2-digit',
                          year: 'numeric',
                        })}
                      </div>
                      <div className="td">{`${slot.starts_at.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })} - ${slot.ends_at.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}`}</div>
                      <div className="td">
                        <button className="btn btn-icon delete-slot-btn" onClick={() => setDeleteBookedSlotData(slot)}>
                          <i className="icon-remove"></i>
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          {!!count && count > 10 && (
            <ReactPaginate
              className="react-pagination"
              breakLabel="..."
              nextLabel="Next ->"
              pageRangeDisplayed={5}
              pageCount={totalPages}
              forcePage={page}
              previousLabel="<- Previous"
              onPageChange={(page) => setPage(page.selected)}
              containerClassName="pagination"
              activeClassName="active"
            />
          )}
        </div>
      </div>
      {deleteBookedSlotData?.id && (
        <DeleteBookedSlotModal
          data={deleteBookedSlotData}
          closeModal={() => setDeleteBookedSlotData(null)}
          handleConfirm={handleDeleteSlot}
        />
      )}
    </>
  );
};

export default BookedSlotsTab;
