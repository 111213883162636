import React, { useState } from 'react';

export const SingleLineElement = function FromElement({ element, handlerChangeOption }) {
  return (
    <>
      <label htmlFor={element.id} className="form-label">
        {element.options.label} <span className="required">{element.options.requiredFor ? '*' : ''}</span>
      </label>
      <input
        name={element.options.name}
        id={element.id}
        value={element.options.valueInput}
        type="text"
        className={`form-control form-control-${element.options.size}`}
        onChange={(e) => {
          handlerChangeOption(!!e.target.value, 'filledByCreator', element.id);
          handlerChangeOption(e.target.value, 'valueInput', element.id);
        }}
      />
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
