import React, { useEffect, useState } from 'react';
// import {MultiSelect} from "../other/MultiSelect";
import InputMask from 'react-input-mask';

const SubmissionSettingsUnregistered = ({ formData, handleFormChange, errors }) => {
  return (
    <div className="form-builder-setting-tab-content">
      <div className="form-builder-recipient-settings">
        <p className="info-text">
          <i className="icon-icon-info"></i> Choose submitters and submission rules.
        </p>
        <div className="form-control-item-settings">
          <div className="form-settings-label">
            <label className="form-label form-label-big">Specify Submitters (required)</label>
            <span>Submitters are users to whom you are sending this task.</span>
          </div>
          <div className="form-settings-inputs">
            <>
              {formData.submitters.unregistered_submitters.map((item, index) => (
                <div className="mt-4" key={index}>
                  <div className={'form-item mb-4 form-item-title'}>
                    <span className={'d-flex'}>
                      <img src="/images/company-add-icon-3.svg" className={'mr-2'} alt="" />
                      Submitter {index + 1} information{' '}
                      {index >= 1 && (
                        <button
                          onClick={() =>
                            handleFormChange(
                              'submitters.unregistered_submitters',
                              formData.submitters.unregistered_submitters.filter((submitter, submitterIndex) => index !== submitterIndex),
                            )
                          }
                          className="ml-2 btn btn-remove btn-icon"
                        >
                          <i className="icon-remove"></i>
                        </button>
                      )}
                    </span>
                  </div>
                  <div className={`form-item mb-4`}>
                    <label className="form-label">First Name</label>
                    <input
                      placeholder="Enter First Name"
                      value={item ? item.first_name : ''}
                      onChange={(e) => handleFormChange(`submitters.unregistered_submitters[${index}].first_name`, e.target.value)}
                      type="text"
                      className="form-control"
                    />
                    <span className="form-error">
                      {errors?.submitters?.unregistered_submitters[index] &&
                        errors.submitters?.unregistered_submitters[index].first_name &&
                        errors.submitters?.unregistered_submitters[index].first_name[0]}
                    </span>
                  </div>
                  <div className={`form-item mb-4`}>
                    <label className="form-label">Last Name</label>
                    <input
                      placeholder="Enter Last Name"
                      value={item ? item.last_name : ''}
                      onChange={(e) => handleFormChange(`submitters.unregistered_submitters[${index}].last_name`, e.target.value)}
                      type="text"
                      className="form-control"
                    />
                    <span className="form-error">
                      {errors?.submitters?.unregistered_submitters[index] &&
                        errors.submitters?.unregistered_submitters[index].last_name &&
                        errors.submitters?.unregistered_submitters[index].last_name[0]}
                    </span>
                  </div>
                  <div className={`form-item  form-item-mail mb-4`}>
                    <label className="form-label">
                      Email<span className="optional">optional</span>
                    </label>
                    <input
                      placeholder="mail@example.com"
                      id="email"
                      value={item ? item.email : ''}
                      onChange={(e) => handleFormChange(`submitters.unregistered_submitters[${index}].email`, e.target.value)}
                      type="text"
                      className="form-control"
                    />
                    <span className="form-error">
                      {errors?.submitters?.unregistered_submitters[index] &&
                        errors.submitters?.unregistered_submitters[index].email &&
                        errors.submitters?.unregistered_submitters[index].email[0]}
                    </span>
                  </div>
                  <div className={`form-item mb-4`}>
                    <label className="form-label">Phone Number</label>
                    <InputMask
                      placeholder="XXX-XXX-XXXX"
                      mask="999-999-9999"
                      value={item ? item.phone : ''}
                      onChange={(e) => handleFormChange(`submitters.unregistered_submitters[${index}].phone`, e.target.value)}
                      className="form-control"
                    ></InputMask>
                    <span className="form-error">
                      {errors?.submitters?.unregistered_submitters[index] &&
                        errors.submitters?.unregistered_submitters[index].phone &&
                        errors.submitters?.unregistered_submitters[index].phone[0]}
                    </span>
                  </div>
                </div>
              ))}

              <span
                className="add-facility cursor-pointer"
                onClick={() =>
                  handleFormChange('submitters.unregistered_submitters', [
                    ...formData.submitters.unregistered_submitters,
                    { email: '', first_name: '', last_name: '', phone: '' },
                  ])
                }
              >
                <i className="icon-plus-svgrepo-com mr-2"></i>Add Unregistered User
              </span>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmissionSettingsUnregistered;
