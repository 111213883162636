import React, { useCallback, useEffect, useState } from 'react';
import PageLoader from '../../../components/other/PageLoader';
import { useAuth } from '../../../container/authContext';
import { EmptyPage } from '../../../components/other/EmptyPage';
import { usePermission } from '../../../container/permissionContext';
import Layout from '../../../layout/default';
import { getTasksService } from '../../../services/tasks';
import { isResponseOk } from '../../../utility/isResponseOk';
import ManageTaskModal from '../components/modals/manage-task-modal';
import TaskListItem from '../components/TaskListItem';
import './styles.scss';
import _ from 'lodash';

const TasksPage = () => {
  const { user = {} } = useAuth();
  const { isSuperAdmin, isCompanySystemAdministrator } = usePermission();
  const { company: { id: companyID } = {} } = user;

  const [filters, setFilters] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [manageTaskModalData, setManageTaskModalData] = useState(null);

  const hasWritePermissions = isSuperAdmin() || isCompanySystemAdministrator();

  const onFilter = (value, key) => {
    setFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleGetTasks = useCallback(async () => {
    try {
      setIsLoading(true);

      const params = {};

      if (filters.search) {
        params.search = filters.search;
      }

      if (isSuperAdmin()) {
        params.company_id = companyID;
      }

      const response = await getTasksService(params);
      if (isResponseOk(response) && response.data) {
        setTasks(response.data);
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  }, [filters.search, isSuperAdmin, companyID]);

  useEffect(() => {
    handleGetTasks();
  }, [handleGetTasks, filters.search]);

  const handleEditTaskSuccess = (data) => {
    setTasks((prevState) => {
      const newState = _.cloneDeep(prevState);
      newState.map((stateTask) => {
        if (stateTask.id === data.id) {
          stateTask.name = data.name;
          stateTask.task_forms = data.task_forms;
        }

        return stateTask;
      });

      return newState;
    });
  };

  const handleAddTaskSuccess = (data) => {
    setTasks((prevState) => [...prevState, data]);
  };

  return (
    <Layout title="Tasks" className={'tasks-page-layout'}>
      <div className="page-wrapper">
        <div className="library-filter">
          <div className="library-filter-search form-control-item">
            <input
              type="text"
              className="form-control"
              value={filters.search}
              onChange={(e) => onFilter(e.target.value, 'search')}
              placeholder="Search"
            />
            <i className="icon-search"></i>
          </div>
          {hasWritePermissions && (
            <div>
              <button
                className="btn"
                onClick={() => {
                  setManageTaskModalData({ isOpen: true });
                }}
              >
                <i className="icon-plus-svgrepo-com mr-2"></i> Add Task
              </button>
            </div>
          )}
        </div>

        <h3 className="library-subtitle">Tasks</h3>
        <div className="tasks-wrapper">
          {tasks.map((task) => (
            <TaskListItem
              key={task.id}
              task={task}
              setManageTaskModalData={setManageTaskModalData}
              setTasks={setTasks}
              hasWritePermissions={hasWritePermissions}
            />
          ))}
        </div>
        {!tasks?.length && !isLoading && <EmptyPage title={'Currently, there are no tasks'} />}
      </div>
      {isLoading && <PageLoader />}

      {manageTaskModalData && (
        <ManageTaskModal
          taskData={manageTaskModalData}
          handleCloseModal={() => setManageTaskModalData(null)}
          handleSuccess={manageTaskModalData.id ? handleEditTaskSuccess : handleAddTaskSuccess}
          companyId={companyID}
        />
      )}
    </Layout>
  );
};

export default TasksPage;
