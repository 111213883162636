import React, { useState } from 'react';
import './styles.scss';
import { updateFacility } from '../../../../services/facility';
import { toast } from 'react-toastify';

const SettingsTab = ({ facility }) => {
  const [formData, setFormData] = useState({
    allow_cna_module: facility.allow_cna_module,
  });

  const handleSave = async () => {
    try {
      const response = await updateFacility(formData, facility.id);

      if (response && response.id) {
        toast(`The settings were updated successfully`, {
          icon: () => <img src="/images/full-check-circle.svg" />,
          closeButton: ({ closeToast }) => (
            <button onClick={closeToast} className="btn btn-icon close-alert-btn">
              <img src="/images/x-icon.svg" />
            </button>
          ),
          className: 'slot-deleted-alert',
          toastClassName: 'slot-deleted-alert-wrapper',
          autoClose: 5000,
          hideProgressBar: false,
          progress: undefined,
          theme: 'light',
          closeOnClick: true,
          pauseOnHover: true,
          type: 'success',
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="facility-settings">
      <div className="facility-settings-item">
        <div className="facility-settings-item-label">
          <h3>Allow CNA assignments module</h3>
          <p>Please, specify if the users with according permission have access to the module</p>
        </div>
        <div className="facility-settings-item-inputs facility-settings-item-inputs-checkbox">
          <div className="form-control-checkbox">
            <input
              name="allow_cna_module"
              type="checkbox"
              checked={formData.allow_cna_module}
              onChange={(e) => setFormData((state) => ({ ...state, allow_cna_module: !state.allow_cna_module }))}
            />
            <span>Allow</span>
          </div>
        </div>
      </div>
      <div className="facility-settings-item">
        <div className="facility-settings-item-label"></div>
        <div className="facility-settings-action">
          <button className="btn" onClick={() => handleSave(formData)}>
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsTab;
