import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Tooltip } from 'react-tooltip';
import PageLoader from '../../../components/other/PageLoader';
import Layout from '../../../layout/init-form/';
import { retrieveTaskService } from '../../../services/tasks';
import { isResponseOk } from '../../../utility/isResponseOk';
import FormsListTab from './tabs/FormsListTab';
import TaskSettingsTab from './tabs/TaskSettingsTab';
import './styles.scss';

const TaskDetailsPage = () => {
  const { taskID } = useParams();
  const navigation = useNavigate();
  const location = useLocation();

  const tabs = [
    {
      name: 'Forms List',
      component: 'forms-list',
      index: 0,
      url: `/tasks/${taskID}/forms`,
      getComponent: (task, setTask) => <FormsListTab task={task} setTask={setTask} />,
    },
    {
      name: 'Task Settings',
      component: 'task-settings',
      index: 1,
      isDisable: false,
      url: `/tasks/${taskID}/settings/`,
      getComponent: (task) => <TaskSettingsTab task={task} />,
    },
  ];

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [task, setTask] = useState(null);
  const [tabIndex, setTabIndex] = useState(tabs.findIndex((item) => item.url === location.pathname));

  const handleRetrieveTask = useCallback(async () => {
    try {
      setIsPageLoading(true);

      const response = await retrieveTaskService(taskID);

      if (isResponseOk(response) && response.data) {
        setTask(response.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsPageLoading(false);
    }
  }, [taskID]);

  useEffect(() => {
    handleRetrieveTask();
  }, [taskID, handleRetrieveTask]);

  return (
    <Layout title="Tasks" className={'tasks-page-layout'} pageClassName="form-builder-wrapper-settings">
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <div className="form-builder-aside-left p-0">
          <TabList className="form-builder-aside-tab">
            {tabs.map((item) => (
              <Tab
                disabled={item.isDisable}
                onClick={() => {
                  if (!item.isDisable) {
                    navigation(item.url);
                  }
                }}
                key={item.index}
              >
                {item.name}

                {item.hasError && (
                  <>
                    <i data-tooltip-id={`table-tooltip-${item.index}`} className="icon-icon-info tab__error"></i>
                    <Tooltip opacity={1} id={`table-tooltip-${item.index}`} place="bottom" content={'Please fill in all required fields'} />
                  </>
                )}
              </Tab>
            ))}
          </TabList>
        </div>
        <div className="form-builder-setting-tab tasks-tab">
          {tabs.map((tab) => {
            return <TabPanel key={tab.index}>{task ? tab.getComponent(task, setTask) : <></>}</TabPanel>;
          })}
        </div>
      </Tabs>
      {isPageLoading && <PageLoader />}
    </Layout>
  );
};

export default TaskDetailsPage;
