import cx from 'classnames';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { getAvailableFormsService, createTaskService, partialUpdateTaskService } from '../../../../../services/tasks';
import { ReactComponent as TaskIcon } from '../../../../../assets/images/task-icon.svg';
import { isResponseOk } from '../../../../../utility/isResponseOk';
import './styles.scss';
import PageLoader from '../../../../../components/other/PageLoader';
import _ from 'lodash';

const ManageTaskModal = ({
  handleCloseModal,
  taskData = {},
  handleSuccess,
  companyId,
  hideName = false,
  hideForms = false,
  hideTitle = false,
  customTitle = null,
}) => {
  const isEditing = !!taskData?.id;

  const [searchForm, setSearchForm] = useState('');
  const [formData, setFormData] = useState({ forms: [], name: '' });
  const [availableForms, setAvailableForms] = useState([]);
  const [isLoadingAvailableForms, setIsLoadingAvailableForms] = useState(false);
  const [errors, setErrors] = useState(null);

  const isActiveSaveBtn = useMemo(() => {
    if (formData.name?.length && formData.forms?.length > 1) {
      return true;
    }

    return false;
  }, [formData.name, formData, formData.forms]);

  const areDeepEqualIgnoringOrder = (obj1, obj2) => {
    return _.isEqualWith(obj1, obj2, (value1, value2) => {
      if (_.isArray(value1) && _.isArray(value2)) {
        return _.isEqual(_.sortBy(value1), _.sortBy(value2));
      }
      return undefined;
    });
  };

  const formattedAvailableForms = useMemo(() => {
    let forms =
      availableForms?.map((form) => ({
        ...form,
        submission_settings: {
          ...form.submission_settings,
          submitters: {
            ...form.submission_settings.submitters,
            contacts: form.submission_settings.submitters.contacts?.length
              ? form.submission_settings.submitters.contacts.map((item) => item.id || item)
              : form.submission_settings.submitters.contacts,
            units: form.submission_settings.submitters.units?.length
              ? form.submission_settings.submitters.units.map((item) => item.id || item)
              : form.submission_settings.submitters.units,
            facilities: form.submission_settings.submitters.facilities?.length
              ? form.submission_settings.submitters.facilities.map((item) => item.id || item)
              : form.submission_settings.submitters.facilities,
            user_groups: form.submission_settings.submitters.user_groups?.length
              ? form.submission_settings.submitters.user_groups.map((item) => item.id || item)
              : form.submission_settings.submitters.user_groups,
            individual_users: form.submission_settings.submitters.individual_users?.length
              ? form.submission_settings.submitters.individual_users.map((item) => item.id || item)
              : form.submission_settings.submitters.individual_users,
          },
        },
        recipient_settings: {
          ...form.recipient_settings,
          facilities: form.recipient_settings.facilities?.length
            ? form.recipient_settings.facilities.map((item) => item.id || item)
            : form.recipient_settings.facilities,
          user_groups: form.recipient_settings.user_groups?.length
            ? form.recipient_settings.user_groups.map((item) => item.id || item)
            : form.recipient_settings.user_groups,
          individual_users: form.recipient_settings.individual_users?.length
            ? form.recipient_settings.individual_users.map((item) => item.id || item)
            : form.recipient_settings.individual_users,
        },
        checked: formData.forms.some((obj) => obj.id === form.id),
      })) || [];

    if (formData.forms.length) {
      const formToCompare = {
        ...formData.forms[0],
        submission_settings: {
          ...formData.forms[0].submission_settings,
          submitters: {
            ...formData.forms[0].submission_settings.submitters,
            contacts: formData.forms[0].submission_settings.submitters.contacts?.length
              ? formData.forms[0].submission_settings.submitters.contacts.map((item) => item.id || item)
              : formData.forms[0].submission_settings.submitters.contacts,
            units: formData.forms[0].submission_settings.submitters.units?.length
              ? formData.forms[0].submission_settings.submitters.units.map((item) => item.id || item)
              : formData.forms[0].submission_settings.submitters.units,
            facilities: formData.forms[0].submission_settings.submitters.facilities?.length
              ? formData.forms[0].submission_settings.submitters.facilities.map((item) => item.id || item)
              : formData.forms[0].submission_settings.submitters.facilities,
            user_groups: formData.forms[0].submission_settings.submitters.user_groups?.length
              ? formData.forms[0].submission_settings.submitters.user_groups.map((item) => item.id || item)
              : formData.forms[0].submission_settings.submitters.user_groups,
            individual_users: formData.forms[0].submission_settings.submitters.individual_users?.length
              ? formData.forms[0].submission_settings.submitters.individual_users.map((item) => item.id || item)
              : formData.forms[0].submission_settings.submitters.individual_users,
          },
        },
        recipient_settings: {
          ...formData.forms[0].recipient_settings,
          facilities: formData.forms[0].recipient_settings.facilities?.length
            ? formData.forms[0].recipient_settings.facilities.map((item) => item.id || item)
            : formData.forms[0].recipient_settings.facilities,
          user_groups: formData.forms[0].recipient_settings.user_groups?.length
            ? formData.forms[0].recipient_settings.user_groups.map((item) => item.id || item)
            : formData.forms[0].recipient_settings.user_groups,
          individual_users: formData.forms[0].recipient_settings.individual_users?.length
            ? formData.forms[0].recipient_settings.individual_users.map((item) => item.id || item)
            : formData.forms[0].recipient_settings.individual_users,
        },
      };

      forms = forms.filter((form) => {
        return (
          areDeepEqualIgnoringOrder(form.submission_settings.submitters, formToCompare.submission_settings.submitters) &&
          areDeepEqualIgnoringOrder(form.recipient_settings, formToCompare.recipient_settings) &&
          form.submission_settings.has_strict_order_of_submissions === formToCompare.submission_settings.has_strict_order_of_submissions
        );
      });
    }
    return forms;
  }, [availableForms, formData.forms]);

  const handleGetAvailableForms = useCallback(async () => {
    try {
      setIsLoadingAvailableForms(true);
      const params = {};

      if (searchForm) {
        params.search = searchForm;
      }

      if (companyId) {
        params.company_id = companyId;
      }

      if (taskData?.id) {
        params.task_id = taskData.id;
      }

      const response = await getAvailableFormsService(params);
      if (isResponseOk(response) && response.data) {
        setAvailableForms(response.data);
        setIsLoadingAvailableForms(false);
      }
    } catch (e) {
      console.error(e);
      setIsLoadingAvailableForms(false);
    }
  }, [searchForm, companyId]);

  useEffect(() => {
    if (isEditing) {
      const formattedFormData = {
        id: taskData.id,
        name: taskData.name,
        forms: taskData.task_forms.map((task_form) => ({
          id: task_form.form_id,
          name: task_form.name,
          submission_settings: task_form.submission_settings,
          recipient_settings: task_form.recipient_settings,
        })),
      };

      setFormData(formattedFormData);
    }
  }, [taskData, isEditing]);

  useEffect(() => {
    handleGetAvailableForms();
  }, [handleGetAvailableForms, taskData?.id, searchForm]);

  const onClose = () => {
    handleCloseModal();
    setFormData({});
  };

  const handleFormChange = (key, value) => {
    setFormData((prevState) => {
      const newState = { ...prevState };
      newState[key] = value;

      return newState;
    });
  };

  const handleSave = async () => {
    try {
      const body = {
        name: formData.name,
        forms_ids: formData.forms.map((item) => item.id),
      };

      if (isEditing) {
        const response = await partialUpdateTaskService(body, taskData.id);

        if (isResponseOk(response) && response.data) {
          handleSuccess(response.data);
          handleCloseModal();
        } else {
          setErrors(response.response.data);
        }
      } else {
        body.company_id = companyId;

        const response = await createTaskService(body);

        if (isResponseOk && response.data) {
          handleSuccess(response.data);
          handleCloseModal();
        } else {
          setErrors(response.response.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSelectForm = (form) => {
    setFormData((prevState) => {
      const newSelectedForms = form.checked ? prevState.forms.filter((item) => item.id !== form.id) : [...prevState.forms, form];
      return {
        ...prevState,
        forms: newSelectedForms,
      };
    });
  };

  const renderFormsSuggest = () => {
    return (
      <div className={'forms--suggest'}>
        <div className="forms--suggest__list">
          {isLoadingAvailableForms && <PageLoader />}
          {formattedAvailableForms.map((availableForm) => {
            return (
              <div key={availableForm.id} className={`form-control-checkbox`}>
                <input
                  className={'input'}
                  type="checkbox"
                  checked={availableForm.checked}
                  onChange={() => handleSelectForm(availableForm)}
                />
                <span style={{ display: 'flex', alignItems: 'stretch', color: '#101828' }}>
                  <img src="/images/file-big-icon.png" alt="" style={{ minWidth: 36, height: 36, marginLeft: 20, marginRight: 6 }} />
                  <div className={'label'}>{availableForm.name}</div>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderSelectedForms = () => {
    return (
      <div className={'selected--forms'}>
        <div className="selected--forms__title">Selected:</div>
        <div className="selected--forms__list">
          {formData.forms?.map(({ id, name }) => {
            return (
              <div className="selected--forms__list__item" key={id}>
                <p>{name}</p>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleSelectForm({ id, name, checked: true })}
                >
                  <path
                    d="M12.5003 7.4974L7.50033 12.4974M7.50033 7.4974L12.5003 12.4974M18.3337 9.9974C18.3337 14.5998 14.6027 18.3307 10.0003 18.3307C5.39795 18.3307 1.66699 14.5998 1.66699 9.9974C1.66699 5.39502 5.39795 1.66406 10.0003 1.66406C14.6027 1.66406 18.3337 5.39502 18.3337 9.9974Z"
                    stroke="#FDA29B"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen
      className={cx('modal-default manage-task-modal')}
      overlayClassName={'modal-overlay'}
      onRequestClose={onClose}
      contentLabel="Manage Task Modal"
      ariaHideApp={false}
    >
      {!hideTitle && (
        <h2 className="modal-header">
          {customTitle ? customTitle : isEditing ? 'Edit task' : 'Create task'}
          <button className="btn btn-icon" onClick={onClose}>
            <i className="icon-icon-x" />
          </button>
        </h2>
      )}

      <div className="modal-body">
        {!hideName && (
          <div className="group task-name-group">
            <span className={'group__title'}>
              <TaskIcon />
              Task information
            </span>

            <div className="group__body">
              <label className="form-label">Task name</label>
              <input
                placeholder="Enter the Task name"
                value={formData.name}
                onChange={(e) => handleFormChange('name', e.target.value)}
                className="form-control"
              />
            </div>
            <span className="form-error mt-1 d-flex">{errors?.name && errors.name[0]}</span>
          </div>
        )}

        {!hideForms && (
          <>
            <h2 className="select-forms-title">Select Forms</h2>
            <div className="form-control-item">
              <input
                value={searchForm}
                type="text"
                className="form-control"
                placeholder="Search by name..."
                onChange={(e) => {
                  setSearchForm(e.target.value);
                }}
              />
              <i className="icon-search"></i>
            </div>
            <div className={'forms--container'}>
              {renderFormsSuggest()}
              {renderSelectedForms()}
            </div>
          </>
        )}
      </div>

      <div className="modal-footer">
        <button className="btn btn-outline" onClick={onClose}>
          Cancel
        </button>
        <button className="btn" disabled={!isActiveSaveBtn} onClick={handleSave}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default memo(ManageTaskModal);
