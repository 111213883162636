import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useOutsideClick from '../../../hook/useOutsideClick';
import { deleteTaskService } from '../../../services/tasks';
import { isResponseOk } from '../../../utility/isResponseOk';
import DeleteTaskModal from './modals/delete-task-modal';

const TaskListItem = ({ task, setManageTaskModalData, setTasks, hasWritePermissions }) => {
  const ref = useRef();
  const navigate = useNavigate();

  const hasPermissions = true;

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  useOutsideClick(ref, closeDropdown);

  const handleDeleteTask = async () => {
    try {
      const response = await deleteTaskService(task.id);
      if (isResponseOk(response)) {
        setTasks((prevState) => prevState.filter((item) => item.id !== task.id));
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="tasks-item cursor-pointer" onClick={(e) => navigate(`/tasks/${task.id}/forms`)}>
      <div className="d-flex justify-space-between flex-start">
        <div className="tasks-item-header">
          <h4 className="title">{task.name}</h4>
          <h5 className="subtitle">Forms:</h5>
        </div>
        {hasWritePermissions && (
          <div>
            <div className="dropdown" ref={ref}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDropdownOpen(!isDropdownOpen);
                }}
                className="btn btn-outline tasks-item-action-btn"
              >
                <img src="/images/more-vertical.svg" alt="" />
              </button>
              {isDropdownOpen && (
                <ul className="dropdown-menu">
                  <li>
                    <button
                      className="btn btn-left btn-full btn-text"
                      onClick={(e) => {
                        e.stopPropagation();
                        setManageTaskModalData(task);
                      }}
                    >
                      <i className="icon-icon-edit mr-2"></i>Edit
                    </button>
                  </li>
                  {hasPermissions && (
                    <li>
                      <button
                        className="btn btn-left btn-full btn-danger btn-text"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsOpenDeleteModal(true);
                        }}
                      >
                        <i className="icon-remove mr-2"></i>Delete
                      </button>
                    </li>
                  )}
                </ul>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="tasks-item-forms">
        {task.task_forms.slice(0, 2).map((form) => (
          <div key={form.id} className="tasks-item-forms-row">
            <img src="/images/icon-file-list.svg" />
            <p className="tasks-item-forms-row-name">{form.name}</p>
          </div>
        ))}
        {task.task_forms.length != 2 && (
          <>
            {task.task_forms.length > 3 ? (
              <div className="tasks-item-forms-more-title">+{task.task_forms.length - 2} more forms</div>
            ) : (
              <div className="tasks-item-forms-row no-border">
                <img src="/images/icon-file-list.svg" />
                <p className="tasks-item-forms-row-name">{task.task_forms[2].name}</p>
              </div>
            )}
          </>
        )}
      </div>
      {isOpenDeleteModal && <DeleteTaskModal closeModal={() => setIsOpenDeleteModal(false)} handleConfirm={handleDeleteTask} />}
    </div>
  );
};

export default TaskListItem;
