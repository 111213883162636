// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.available-date-picker .react-datepicker__day:not(.react-datepicker__day--disabled) {
  background-color: #7f56d9 !important;
  color: #fff !important;
}
.available-date-picker .react-datepicker__day:not(.react-datepicker__day--disabled):hover {
  opacity: 0.8;
}

.is-mobile.ReactModal__Overlay {
  padding-top: 0;
  padding-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/components/ScheduleDemoModal/styles.scss"],"names":[],"mappings":"AACE;EACE,oCAAA;EACA,sBAAA;AAAJ;AAEI;EACE,YAAA;AAAN;;AAME;EACE,cAAA;EACA,iBAAA;AAHJ","sourcesContent":[".available-date-picker {\n  .react-datepicker__day:not(.react-datepicker__day--disabled) {\n    background-color: #7f56d9 !important;\n    color: #fff !important;\n\n    &:hover {\n      opacity: 0.8;\n    }\n  }\n}\n\n.is-mobile {\n  &.ReactModal__Overlay {\n    padding-top: 0;\n    padding-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
