import _ from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Modal from 'react-modal';
import Select from 'react-select';
import { Breadcrumbs } from '../../../../components/library/Breadcrumbs';
import PageLoader from '../../../../components/other/PageLoader';
import { getUserSubmissionForms } from '../../../../services/folder/form-avaliable';
import './styles.scss';

const SelectFormsModal = ({ userID, formData, handleFormChange, onClose }) => {
  const [filters, setFilters] = useState({
    status: null,
    search: '',
  });
  const [availableFolders, setAvailableFolders] = useState([]);
  const [availableForms, setAvailableForms] = useState([]);
  const [isLoadingAvailableForms, setIsLoadingAvailableForms] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([{ name: 'Company Library' }]);
  const [tempForms, setTempForms] = useState(formData.forms);
  const [folderID, setFolderID] = useState(null);

  const goToPrev = (id, type) => {
    if (type === 'folder') {
      setFolderID(id);
    }
  };

  const getBreadcrumbs = (breadcrumbs) => {
    const list = [];

    function getProp(o) {
      list.push(o);
      if (o.children) {
        getProp(o.children);
      }
    }

    if (breadcrumbs) {
      getProp(breadcrumbs);
    }

    if (list[0]) {
      list[0].name = 'Company Library';
      list[0].url = '/corporate-library/forms/submissions';
    }

    return list;
  };

  const getAvailableFormsAndFolders = useCallback(async () => {
    try {
      const params = {};

      if (filters.status) {
        params.form_status = filters.status.value;
      }

      if (filters.search) {
        params.search_query = filters.search;
      } else if (folderID) {
        params.parent_id = folderID;
      }

      const response = await getUserSubmissionForms(userID, params);

      if (response.data) {
        if (filters.search) {
          setAvailableFolders([]);
          setAvailableForms(response.data);
        } else {
          const { data, breadcrumbs } = response.data;
          setBreadcrumbs(getBreadcrumbs(breadcrumbs));
          setAvailableFolders(data.filter((item) => item.type === 'folder'));
          setAvailableForms(data.filter((item) => item.type === 'form'));
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [folderID, filters, userID]);

  useEffect(() => {
    getAvailableFormsAndFolders();
  }, [getAvailableFormsAndFolders]);

  const isAllSelected = useMemo(() => {
    const selectedFormsIDs = tempForms.map((item) => item.id);

    return (
      (availableForms.length || availableFolders.length) &&
      availableForms.every((item) => selectedFormsIDs.indexOf(item.id) !== -1) &&
      availableFolders.every((folder) => folder.forms.every((form) => selectedFormsIDs.indexOf(form.id) !== -1))
    );
  }, [availableFolders, availableForms, tempForms]);

  const handleSelectAll = () => {
    if (isAllSelected) {
      setTempForms(
        tempForms.filter(
          (item) =>
            !availableForms.some((availableForm) => availableForm.id === item.id) &&
            availableFolders.every((folder) => !folder.forms.some((folderForm) => folderForm.id === item.id)),
        ),
      );
    } else {
      let formsInFoldersFormatted = [];
      availableFolders.forEach((item) => {
        formsInFoldersFormatted = formsInFoldersFormatted.concat(item.forms.map((form) => ({ id: form.id, name: form.name })));
      });

      const formsFormatted = availableForms.map((item) => ({ id: item.id, name: item.name }));

      const uniqueForms = [...new Map([...tempForms, ...formsInFoldersFormatted, ...formsFormatted].map((obj) => [obj.id, obj])).values()];

      setTempForms(uniqueForms);
    }
  };

  const handleSeletFolder = (isChecked, folder) => {
    if (isChecked) {
      setTempForms((state) => state.filter((item) => !folder.forms.some((form) => form.id === item.id)));
    } else {
      const uniqueForms = [...new Map([...tempForms, ...folder.forms].map((obj) => [obj.id, obj])).values()];
      setTempForms(uniqueForms);
    }
  };

  return (
    <Modal
      isOpen
      className="modal-default select-forms-modal"
      overlayClassName="modal-overlay"
      onRequestClose={onClose}
      contentLabel="Select Forms"
      ariaHideApp={false}
    >
      <div className="modal-body">
        <DndProvider backend={HTML5Backend}>
          <Breadcrumbs breadcrumbs={breadcrumbs} goToLevel={goToPrev} />
        </DndProvider>
        <div className="library-filter">
          <div className="library-filter-search form-control-item">
            <input
              value={filters.search}
              onChange={(e) => {
                setFilters((state) => ({ ...state, search: e.target.value }));
              }}
              type="text"
              className="form-control"
              placeholder="Search by name..."
            />
            <i className="icon-search"></i>
          </div>
          <div className="form-control-item status-filter">
            <label className="form-label">Active / Archived</label>
            <Select
              options={[
                {
                  value: 'all',
                  label: 'All',
                },
                {
                  value: 'active',
                  label: 'Active',
                },
                {
                  value: 'archived',
                  label: 'Archived',
                },
              ]}
              isSearchable={false}
              value={filters.status}
              onChange={(e) => setFilters((state) => ({ ...state, status: e }))}
              placeholder="Select status"
              className="form-control-select"
              classNamePrefix="react-select"
            ></Select>
          </div>
        </div>
        <div className={'forms--container'}>
          <div className={'forms--suggest'}>
            <div className="form-control-checkbox d-flex select-all-forms-checkbox">
              <div>
                <input type="checkbox" onChange={handleSelectAll} checked={isAllSelected} />
                <span></span>
              </div>
              <strong className="select-all-forms-label">Select All</strong>
            </div>
            <div className="forms--suggest__list">
              {isLoadingAvailableForms && <PageLoader />}
              {availableFolders
                .filter((folder) => folder.forms.length)
                .map((availableFolder) => {
                  const isChecked = availableFolder.forms.every((form) => tempForms.some((item) => item.id === form.id));

                  return (
                    <div key={availableFolder.id} className={`form-control-checkbox`} onClick={() => setFolderID(availableFolder.id)}>
                      <input
                        className={'input folder-checkbox-input'}
                        type="checkbox"
                        checked={isChecked}
                        style={{
                          top: '22px',
                          left: '28px',
                          height: '20px',
                          width: '20px',
                          marginTop: 0,
                          marginLeft: 0,
                        }}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => {
                          handleSeletFolder(isChecked, availableFolder);
                        }}
                      />
                      <span style={{ display: 'flex', alignItems: 'stretch', color: '#101828' }}>
                        <img
                          src="/images/folder-big-icon.png"
                          alt=""
                          style={{ minWidth: 36, height: 36, marginLeft: 20, marginRight: 6 }}
                        />
                        <div className={'label'}>{availableFolder.name}</div>
                      </span>
                    </div>
                  );
                })}
              {availableForms.map((availableForm) => {
                const isChecked = tempForms.some((form) => form.id === availableForm.id);
                return (
                  <div key={availableForm.id} className={`form-control-checkbox`}>
                    <input
                      className={'input'}
                      type="checkbox"
                      checked={isChecked}
                      onChange={() => {
                        if (isChecked) {
                          setTempForms((state) => state.filter((item) => item.id !== availableForm.id));
                        } else {
                          setTempForms((state) => [...state, availableForm]);
                        }
                      }}
                    />
                    <span style={{ display: 'flex', alignItems: 'stretch', color: '#101828' }}>
                      <img src="/images/file-big-icon.png" alt="" style={{ minWidth: 36, height: 36, marginLeft: 20, marginRight: 6 }} />
                      <div className={'label'}>{availableForm.name}</div>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={'selected--forms'}>
            <div className="selected--forms__title">Selected:</div>
            <div className="selected--forms__list">
              {tempForms.map(({ id, name }) => {
                return (
                  <div className="selected--forms__list__item" key={id}>
                    <p>{name}</p>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => {
                        setTempForms((state) => state.filter((item) => item.id !== id));
                      }}
                    >
                      <path
                        d="M12.5003 7.4974L7.50033 12.4974M7.50033 7.4974L12.5003 12.4974M18.3337 9.9974C18.3337 14.5998 14.6027 18.3307 10.0003 18.3307C5.39795 18.3307 1.66699 14.5998 1.66699 9.9974C1.66699 5.39502 5.39795 1.66406 10.0003 1.66406C14.6027 1.66406 18.3337 5.39502 18.3337 9.9974Z"
                        stroke="#FDA29B"
                        strokeWidth="1.67"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button className="btn btn-outline" onClick={onClose}>
          Cancel
        </button>
        <button
          className="btn"
          onClick={() => {
            handleFormChange('forms', tempForms);
            onClose();
          }}
        >
          Select
        </button>
      </div>
    </Modal>
  );
};

export default memo(SelectFormsModal);
