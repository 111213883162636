// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submission-forms-table .tr:nth-child(n+1) .td {
  padding-left: 16px;
  padding-right: 16px;
}
.submission-forms-table .tr:nth-child(n+1) .td .delete-submission-btn {
  width: 40px;
  height: 36px;
  padding: 0px;
  justify-content: center;
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/corporate-library/submission/styles.scss"],"names":[],"mappings":"AAEI;EACE,kBAAA;EACA,mBAAA;AADN;AAGM;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,uBAAA;EACA,iBAAA;AADR","sourcesContent":[".submission-forms-table {\n  .tr:nth-child(n + 1) {\n    .td {\n      padding-left: 16px;\n      padding-right: 16px;\n\n      .delete-submission-btn {\n        width: 40px;\n        height: 36px;\n        padding: 0px;\n        justify-content: center;\n        margin-left: auto;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
