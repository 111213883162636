import React, { useEffect, useMemo, useState } from 'react';
import Layout from '../../layout/init-form';
import LayoutPublic from '../../layout/full-page';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getInitFromById, submitForm } from '../../services/form';
import { RenderForm } from '../../components/init-form/render-form';
import { useAuth } from '../../container/authContext';
import dayjs from 'dayjs';
import { DATE_FORMAT, TIME_FORMAT } from '../../constants';
import { utcToLocalDate, utcToLocalDateFormatter } from '../../utility/dates/utcToLocalDate';
import { getFormFieldsWithInitialValues } from '../../utility/getFormFieldsWithInitialValues';
import { useUserFormRole } from '../../hook/useUserFormRole';
import { usePermission } from '../../container/permissionContext';
import { FormActionsMenu } from '../../components/other/FormActionsMenu';
import { activateInitiatedForm, archiveInitiatedForm, deleteInitiatedForm } from '../../services/folder/form-avaliable';
import { isResponseOk } from '../../utility/isResponseOk';
import { useIsMobile } from '../../hook/useIsMobile';

function SendForm() {
  const navigation = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const location = useLocation();
  const { logout, user, updateUser } = useAuth();
  const userFormRole = useUserFormRole();
  const { hasPermissionByCodeName, isSuperAdmin, isCompanySystemAdministrator } = usePermission();
  const isMobile = useIsMobile();

  const [formData, setFormData] = useState({});
  const [initData, setInitData] = useState({});
  const [isFinished, setIsFinished] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errors, setErrors] = useState([]);

  const { deleted_at } = formData ?? {};
  const isPublicForm = formData?.filling_type === 'PUBLIC';
  const taskInitiationID = formData?.task_initiation_id;
  const isLastFormInTask = formData?.is_last_form_in_task;

  const handleDeleteForm = async () => {
    try {
      if (id) {
        const deletePublicFormRes = await deleteInitiatedForm(id);
        if (isResponseOk(deletePublicFormRes)) {
          backTo();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleActivateForm = async () => {
    try {
      if (id) {
        const activateInitiatedFormRes = await activateInitiatedForm(id);
        if (isResponseOk(activateInitiatedFormRes)) {
          handleGetInitFromById();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleArchiveForm = async () => {
    try {
      archiveInitiatedForm(id)
        .then(handleGetInitFromById)
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const publicFormActionsMenu = useMemo(() => {
    let menu = [];

    if ((isSuperAdmin() || isCompanySystemAdministrator()) && hasPermissionByCodeName('corporate_library_management')) {
      menu = [
        ...(deleted_at
          ? [
              <button style={{ color: '#12b76a' }} className="btn btn-full" onClick={handleActivateForm}>
                <svg className="mr-2" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1484_3438)">
                    <path
                      d="M18.3332 9.2333V9.99997C18.3321 11.797 17.7503 13.5455 16.6743 14.9848C15.5983 16.4241 14.0859 17.477 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8121C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0233 1.56633 11.24 1.68457 9.4469C1.80281 7.65377 2.49751 5.94691 3.66507 4.58086C4.83263 3.21482 6.41049 2.26279 8.16333 1.86676C9.91617 1.47073 11.7501 1.65192 13.3915 2.3833M18.3332 3.3333L9.99984 11.675L7.49984 9.17497"
                      stroke="#12B76A"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1484_3438">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Activate
              </button>,
            ]
          : [
              <button className="btn btn-full" onClick={handleArchiveForm}>
                <i className="icon-icon-x mr-2" />
                Archive
              </button>,
            ]),
        <button className="btn btn-full btn-remove" onClick={handleDeleteForm}>
          <i className="icon-remove mr-2" />
          Delete
        </button>,
      ];
    }

    return menu;
  }, [
    deleted_at,
    handleActivateForm,
    handleArchiveForm,
    handleDeleteForm,
    hasPermissionByCodeName,
    isCompanySystemAdministrator,
    isSuperAdmin,
  ]);

  const getToken = () => {
    if (location.search.includes('?token=')) {
      return location.search.split('?token=')[1];
    }

    return null;
  };

  const handleGetInitFromById = () => {
    if (id) {
      getInitFromById(id)
        .then((res) => {
          setFormData(res);

          if (res?.submitted_data?.length) {
            const form_fields = res?.form_fields.map((form_field) => {
              const submitted_item = res?.submitted_data?.find((item) => form_field.id === item.id);
              const saved_item = res?.saved_data?.find((item) => form_field.id === item.id);
              if (submitted_item) {
                return isReadOnly(submitted_item, res);
              }

              if (saved_item) {
                return saved_item;
              }

              return isReadOnly(form_field, res);
            });

            setInitData({ ...initData, form_fields: JSON.parse(JSON.stringify(form_fields)) });
          } else if (res?.form_fields) {
            if (res?.saved_data?.length) {
              const form_fields = res?.form_fields.map((form_field) => {
                const submitted_item = res?.saved_data?.find((item) => form_field.id === item.id);

                if (submitted_item) {
                  return submitted_item;
                }

                return isReadOnly(form_field, res);
              });

              setInitData({ form_fields: JSON.parse(JSON.stringify(form_fields)), ...initData });
            } else {
              let form_fields = res?.form_fields.map((item) => {
                return isReadOnly(item, res);
              });
              form_fields = getFormFieldsWithInitialValues(form_fields, userFormRole);

              setInitData({ form_fields, ...initData });
            }
          }
        })
        .catch((e) => console.log(e));
    }
  };

  useEffect(() => {
    if (!user) {
      logout();
    }
    if (id) {
      handleGetInitFromById();
    }
    // eslint-disable-next-line
  }, [id, location]);

  const handelInitSettings = (res, key) => {
    setInitData({ ...initData, [key]: res });
  };

  const isReadOnly = (element, res) => {
    if (element.type === 'name' || element.type === 'address' || element.type === 'checkbox') {
      element.inputs.map((input) => {
        if (input.valueInput) {
          input['readOnly'] = res.can_edit_previous_submissions ? null : 'readonly';
        }

        return input;
      });
    }

    if (element.type === 'table') {
      element.rows.map((row) => {
        if (row.cols) {
          row.cols.forEach((col) => {
            if (col?.value) {
              col['readOnly'] = res.can_edit_previous_submissions ? null : 'readonly';
            }
          });
        }
        return row;
      });
    }

    if (element.options.valueInput) {
      element.options['readOnly'] = res.can_edit_previous_submissions ? null : 'readonly';
    }

    return element;
  };

  const onClearForm = () => {
    if (formData?.submitted_data?.length) {
      const form_fields = formData?.form_fields.map((form_field) => {
        const submitted_item = formData?.submitted_data?.find((item) => form_field.id === item.id);
        if (submitted_item) {
          return isReadOnly(submitted_item, formData);
        }

        return isReadOnly(form_field, formData);
      });
      setInitData({ ...initData, form_fields: JSON.parse(JSON.stringify(form_fields)) });
    } else if (formData?.form_fields) {
      const form_fields = formData?.form_fields.map((item) => {
        return isReadOnly(item, formData);
      });

      setInitData({ ...initData, form_fields: form_fields });
    }
  };

  const onSend = (isSaved) => {
    setIsDisabled(true);

    const bodyRequest = {
      data: initData.form_fields,
      is_saved: isSaved,
    };

    if (getToken()) {
      bodyRequest['token'] = getToken();
    }

    submitForm(id, bodyRequest)
      .then(() => {
        updateUser();
        if (getToken() && !user) {
          setIsFinished(true);
        } else if (isSaved) {
          navigation('/corporate-library/forms/forms-in-progress');
        } else {
          backTo();
        }
        setIsDisabled(false);
      })
      .catch((e) => {
        setIsDisabled(false);
        console.log('Submit form error: ', e);
        if (e?.response?.data) {
          setErrors(e?.response?.data?.non_field_errors);
        }
      });
  };

  const backTo = () => {
    if (isPublicForm) {
      if (searchParams.has('isPublic')) {
        searchParams.delete('isPublic');
        setSearchParams(searchParams);
        if (searchParams.has('parent_id')) {
          navigation(`/corporate-library/forms/public-forms?parent_id=${searchParams.get('parent_id')}`);
        } else {
          navigation(`/corporate-library/forms/public-forms`);
        }
      }
    } else {
      if (taskInitiationID && !isLastFormInTask) {
        navigation(`/corporate-library/tasks/${taskInitiationID}`);
      } else {
        navigation(`/corporate-library/forms/forms-assigned-to-me`);
      }
    }
  };

  const renderPublicFormActions = () => {
    let layout = null;
    if (
      id &&
      isPublicForm &&
      (isSuperAdmin() || isCompanySystemAdministrator()) &&
      hasPermissionByCodeName('corporate_library_management')
    ) {
      layout = <FormActionsMenu menu={publicFormActionsMenu} />;
    }
    return layout;
  };

  if (getToken() && !user) {
    return (
      <LayoutPublic>
        {!isFinished && (
          <div>
            <div className="d-flex send-form-wrapper">
              <div className={'send-form-title'}>
                {formData.deadline && (
                  <>
                    <strong>Deadline:</strong> {utcToLocalDateFormatter(formData.deadline, `${DATE_FORMAT} hh:mm A`)}
                  </>
                )}
              </div>
            </div>
            <RenderForm
              isSubmitter={true}
              isPrint={false}
              errors={errors}
              handelInitSettings={handelInitSettings}
              title={
                formData.title_options
                  ? {
                      ...formData?.title_options,
                      options: { ...formData?.title_options.options, value: formData.name },
                    }
                  : formData.name
              }
              formElement={initData?.form_fields}
              formHasOnlyOneSubmitter={formData?.form_has_only_one_submitter || formData?.is_one_submitter}
              initiatorID={formData.initiator_id}
            />

            <div className="form-control-actions submit-form-actions">
              <div>
                <button className={'btn btn-outline'} style={{ gap: 8, display: 'inline-flex' }} onClick={onClearForm}>
                  {!isMobile && (
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_10670_32234)">
                        <path
                          d="M12.5003 7.4974L7.50033 12.4974M7.50033 7.4974L12.5003 12.4974M18.3337 9.9974C18.3337 14.5998 14.6027 18.3307 10.0003 18.3307C5.39795 18.3307 1.66699 14.5998 1.66699 9.9974C1.66699 5.39502 5.39795 1.66406 10.0003 1.66406C14.6027 1.66406 18.3337 5.39502 18.3337 9.9974Z"
                          stroke="#101828"
                          strokeWidth="1.67"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_10670_32234">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  Clear form
                </button>
              </div>
              <div
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <span onClick={backTo} className="btn btn-outline">
                  Cancel
                </span>
                {!formData?.deleted_at && (
                  <>
                    <button disabled={isDisabled} className="btn" onClick={() => onSend(false)}>
                      Submit the form
                    </button>
                    <button disabled={isDisabled} className="btn  btn-mobile" onClick={() => onSend(false)}>
                      Submit
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {isFinished && (
          <div className={'success-page'}>
            <img src="/images/sussess-icon.png" alt="" />
            <h2>Submission is Successful!</h2>
            <p>Thank you for submitting the form. Your information has been received successfully.</p>
          </div>
        )}
      </LayoutPublic>
    );
  }

  return (
    <Layout title="Send Form" pageClassName="form-builder-wrapper-settings init-page send-page">
      {!isFinished && (
        <div>
          <div className="d-flex send-form-wrapper">
            <div className={'send-form-title'}>
              {formData.deadline && (
                <>
                  <strong>Deadline:</strong> {dayjs(utcToLocalDate(formData.deadline)).format(`${DATE_FORMAT} ${TIME_FORMAT}`)}
                </>
              )}
            </div>
            <div className={'d-flex'}>
              {formData.has_save_button && (
                <button className={'btn btn-outline btn-save'} onClick={() => onSend(true)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M14.1667 17.5V10.8333H5.83333V17.5M5.83333 2.5V6.66667H12.5M15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H13.3333L17.5 6.66667V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5Z"
                      stroke="#7F56D9"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Save
                </button>
              )}
              {renderPublicFormActions()}
            </div>
          </div>
          {formData.has_save_button && (
            <div className={'send-form-wrapper-mobile'}>
              <button className={'btn btn-outline btn-save'} onClick={() => onSend(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M14.1667 17.5V10.8333H5.83333V17.5M5.83333 2.5V6.66667H12.5M15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H13.3333L17.5 6.66667V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5Z"
                    stroke="#7F56D9"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Save
              </button>
            </div>
          )}
          <RenderForm
            formData={formData}
            isSubmitter={true}
            isPrint={false}
            errors={errors}
            handelInitSettings={handelInitSettings}
            title={
              formData.title_options
                ? {
                    ...formData?.title_options,
                    options: { ...formData?.title_options.options, value: formData.name },
                  }
                : formData.name
            }
            formElement={initData?.form_fields}
            formHasOnlyOneSubmitter={formData?.form_has_only_one_submitter || formData?.is_one_submitter}
            initiatorID={formData.initiator_id}
          />

          <div className="form-control-actions submit-form-actions">
            <div>
              <button className={'btn btn-outline'} style={{ gap: 8, display: 'inline-flex' }} onClick={onClearForm}>
                {!isMobile && (
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_10670_32234)">
                      <path
                        d="M12.5003 7.4974L7.50033 12.4974M7.50033 7.4974L12.5003 12.4974M18.3337 9.9974C18.3337 14.5998 14.6027 18.3307 10.0003 18.3307C5.39795 18.3307 1.66699 14.5998 1.66699 9.9974C1.66699 5.39502 5.39795 1.66406 10.0003 1.66406C14.6027 1.66406 18.3337 5.39502 18.3337 9.9974Z"
                        stroke="#101828"
                        strokeWidth="1.67"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_10670_32234">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )}
                Clear form
              </button>
            </div>
            <div
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <span onClick={backTo} className="btn btn-outline">
                Cancel
              </span>
              {!formData?.deleted_at && (
                <>
                  <button disabled={isDisabled} className="btn" onClick={() => onSend(false)}>
                    Submit the form
                  </button>
                  <button disabled={isDisabled} className="btn  btn-mobile" onClick={() => onSend(false)}>
                    Submit
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {isFinished && (
        <div className={'success-page'}>
          <img src="/images/sussess-icon.png" alt="" />
          <h2>Submission is Successful!</h2>
          <p>Thank you for submitting the form. Your information has been received successfully.</p>
        </div>
      )}
    </Layout>
  );
}

export default SendForm;
