// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.page-header .header-right {
  display: flex;
  gap: 16px;
}
.page-header h2 {
  font-size: 25px;
  font-weight: 700;
}
.page-header .booked-slots-btn {
  height: 44px;
  width: 56px;
  padding: 0;
}
.page-header .booked-slots-btn.btn-active svg * {
  fill: var(--primary-600, #7f56d9);
}
.page-header .schedule-btn {
  height: 44px;
  width: 56px;
  padding: 0;
}
.page-header .schedule-btn.btn-active svg * {
  stroke: var(--primary-600, #7f56d9);
  fill: none;
}

.page-content {
  margin-top: 24px;
}`, "",{"version":3,"sources":["webpack://./src/pages/schedule/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,WAAA;AACF;AACE;EACE,aAAA;EACA,SAAA;AACJ;AAEE;EACE,eAAA;EACA,gBAAA;AAAJ;AAGE;EACE,YAAA;EACA,WAAA;EACA,UAAA;AADJ;AAIM;EACE,iCAAA;AAFR;AAOE;EACE,YAAA;EACA,WAAA;EACA,UAAA;AALJ;AAQM;EACE,mCAAA;EACA,UAAA;AANR;;AAYA;EACE,gBAAA;AATF","sourcesContent":[".page-header {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n\n  .header-right {\n    display: flex;\n    gap: 16px;\n  }\n\n  h2 {\n    font-size: 25px;\n    font-weight: 700;\n  }\n\n  .booked-slots-btn {\n    height: 44px;\n    width: 56px;\n    padding: 0;\n\n    &.btn-active {\n      svg * {\n        fill: var(--primary-600, #7f56d9);\n      }\n    }\n  }\n\n  .schedule-btn {\n    height: 44px;\n    width: 56px;\n    padding: 0;\n\n    &.btn-active {\n      svg * {\n        stroke: var(--primary-600, #7f56d9);\n        fill: none;\n      }\n    }\n  }\n}\n\n.page-content {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
