import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { addActions, editActions } from '../../services/user-group';
import { MultiSelect } from '../other/MultiSelect';

function FacilityModal({ isOpen, dataAction, closeModal, userGroupId, handelAddActions, facilities, actions, currentActions }) {
  const [errorForm, setErrorForm] = useState({});
  const [actionsOptions, setActionsOptions] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  // const [errorUser, setErrorUser] = useState({})

  const [form, setForm] = useState({
    facility_ids: [],
    action_id: '',
  });

  useEffect(() => {
    setFacilityOptions(facilities.map((item) => ({ value: item.id, label: item.name })));
  }, [facilities]);
  useEffect(() => {
    setActionsOptions(actions.map((item) => ({ value: item.id, label: item.name })));
  }, [actions]);

  useEffect(() => {
    if (dataAction) {
      setForm({
        action_id: { value: dataAction.action.id, label: dataAction.action.name },
        facility_ids: [...dataAction.influenced_facilities.map((item) => ({ value: item.id, label: item.name }))],
      });
    }
  }, [dataAction]);

  const handleChange = (value, key) => {
    if (errorForm.hasOwnProperty(key)) {
      delete errorForm[key];
    }

    if (key === 'facility_ids' && value.find((item) => item.value === 'select_all')?.value === 'select_all') {
      setForm({
        ...form,
        [key]: [...facilityOptions],
      });
      return;
    }

    setForm({
      ...form,
      [key]: value,
    });
  };

  const resetForm = () => {
    setForm({
      facility_ids: '',
      action_id: '',
    });
  };

  const handelRemove = (item) => {
    let newState = [...form.facility_ids];
    newState = newState.filter((facility) => facility.value !== item.value);
    setForm({
      ...form,
      facility_ids: newState,
    });
  };

  const handleSubmit = () => {
    const formDate = {
      action_id: form.action_id.value,
      influenced_facilities_ids: form.facility_ids.length ? form.facility_ids.map((item) => item.value) : [],
    };

    if (dataAction) {
      editActions(formDate, userGroupId, dataAction.id)
        .then((res) => {
          resetForm();
          handelAddActions(res);
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorForm(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    } else {
      addActions(formDate, userGroupId)
        .then((res) => {
          resetForm();
          handelAddActions(res);
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorForm(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    }
  };
  const handelClose = () => {
    resetForm();
    closeModal();
  };
  return (
    <Modal className="modal-default" isOpen={isOpen} onRequestClose={handelClose} contentLabel="Example Modal" ariaHideApp={false}>
      <h2 className="modal-header">
        {dataAction ? 'Edit' : 'Add New'} Permission{' '}
        <button className="btn btn-icon" onClick={handelClose}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>

      <div className="form-wrapper">
        <h3 className="form-wrapper-title">
          <img src="/images/icon-permissions.svg" alt="" />
          Permission information
        </h3>
        <div className="form-item">
          <label className="form-label">Action</label>
          <Select
            isDisabled={dataAction}
            options={actionsOptions}
            className="form-control-select select-action-form-control"
            classNamePrefix="react-select"
            value={form.action_id}
            onChange={(e) => handleChange(e, 'action_id')}
            placeholder={'Select Action'}
          ></Select>
          <span className="form-error">{errorForm.action_id && errorForm.action_id[0]}</span>
        </div>
        <div className="form-control-item-settings form-item">
          <label className="form-label">Facility</label>
          <div className="select-multy-wrapper">
            <span className="select-placeholder">Select Facility</span>
            <MultiSelect
              isDisabled={!facilityOptions.length}
              options={facilityOptions}
              value={form.facility_ids}
              onChange={(e) => handleChange(e, 'facility_ids')}
              placeholder=""
              allName="All facilities"
            ></MultiSelect>
            {form.facility_ids.length > 0 && (
              <>
                <ul>
                  {form.facility_ids.map((item, key) => (
                    <li key={key}>
                      {item.label}
                      <button className="btn btn-icon" onClick={() => handelRemove(item)}>
                        <img src="/images/x-circle.svg" alt="" />
                      </button>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>

          <span className="form-error">{errorForm.facility_ids && errorForm.facility_ids[0]}</span>
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={handelClose}>
          Cancel
        </button>
        <button className="btn" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </Modal>
  );
}

export default FacilityModal;
