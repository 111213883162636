import React from 'react';
import Modal from 'react-modal';
import './styles.scss';

const DeleteSubmissionModal = ({ closeModal, handleConfirm }) => {
  const handleClose = (e) => {
    e.stopPropagation();
    closeModal();
  };

  return (
    <Modal
      className="modal-alert delete-task-modal"
      isOpen
      onRequestClose={handleClose}
      contentLabel="Delete task modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        <img src="/images/alert-icon.svg" alt="" />
        <button className="btn btn-icon" onClick={handleClose}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>

      <div>
        <h3>Please Confirm Deletion</h3>
        <p>Are you sure you want to delete this submission? This action cannot be undone. </p>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={handleClose}>
          Cancel
        </button>
        <button
          className="btn btn-danger"
          onClick={(e) => {
            e.stopPropagation();
            handleConfirm();
          }}
        >
          Delete Submission
        </button>
      </div>
    </Modal>
  );
};

export default DeleteSubmissionModal;
