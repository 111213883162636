// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slot-date-picker .react-datepicker__day--today:hover {
  background-color: #7f56d9 !important;
  color: #fff !important;
}
.slot-date-picker .react-datepicker__day--selected {
  background-color: #7f56d9 !important;
  color: #fff !important;
}
.slot-date-picker .react-datepicker__day:hover(:not(.react-datepicker__day--disabled)) {
  background-color: #7f56d9 !important;
  color: #fff !important;
}

.react-datepicker__time-list-item {
  display: flex;
  align-items: center;
  text-align: left;
}
.react-datepicker__time-list-item--disabled {
  display: none;
}

.time-selectors {
  display: flex;
  width: 100%;
}
.time-selectors .form-item {
  flex: unset;
}`, "",{"version":3,"sources":["webpack://./src/pages/schedule/components/AddTimeSlotsModal/styles.scss"],"names":[],"mappings":"AAGM;EACE,oCAAA;EACA,sBAAA;AAFR;AAKI;EACE,oCAAA;EACA,sBAAA;AAHN;AAMI;EACE,oCAAA;EACA,sBAAA;AAJN;;AASA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AANF;AAQE;EACE,aAAA;AANJ;;AAUA;EACE,aAAA;EACA,WAAA;AAPF;AASE;EACE,WAAA;AAPJ","sourcesContent":[".slot-date-picker {\n  .react-datepicker__day {\n    &--today {\n      &:hover {\n        background-color: #7f56d9 !important;\n        color: #fff !important;\n      }\n    }\n    &--selected {\n      background-color: #7f56d9 !important;\n      color: #fff !important;\n    }\n\n    &:hover(:not(.react-datepicker__day--disabled)) {\n      background-color: #7f56d9 !important;\n      color: #fff !important;\n    }\n  }\n}\n\n.react-datepicker__time-list-item {\n  display: flex;\n  align-items: center;\n  text-align: left;\n\n  &--disabled {\n    display: none;\n  }\n}\n\n.time-selectors {\n  display: flex;\n  width: 100%;\n\n  .form-item {\n    flex: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
