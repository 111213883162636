import React, { useState } from 'react';
import Layout from '../../layout/default';
import { ReactComponent as BookedSlotsIcon } from '../../assets/images/booked-slots.svg';
import { ReactComponent as ScheduleIcon } from '../../assets/images/schedule.svg';
import './styles.scss';
import BookedSlotsTab from './components/BookedSlotsTab';
import ScheduleTab from './components/ScheduleTab';
import AddTimeSlotsModal from './components/AddTimeSlotsModal';

const SchedulePage = () => {
  const [activeTab, setActiveTab] = useState('bookedSlots');
  const [isOpenAddTimeSlotsModal, setIsOpenAddTimeSlotsModal] = useState(false);
  const [slotsData, setSlotsData] = useState([]);

  return (
    <Layout title="Demo Schedule">
      <div className="page-wrapper">
        <div className="page-header">
          <div className="header-left">
            <h2>{activeTab == 'bookedSlots' ? 'Booked slots' : 'Demo Schedule'}</h2>
          </div>
          <div className="header-right">
            <button
              className={`btn-outline btn btn-down ${activeTab == 'bookedSlots' ? 'btn-active' : ''} booked-slots-btn`}
              onClick={() => {
                setSlotsData([]);
                setActiveTab('bookedSlots');
              }}
            >
              <BookedSlotsIcon />
            </button>
            <button
              className={`btn-outline btn btn-down ${activeTab == 'schedule' ? 'btn-active' : ''} schedule-btn`}
              onClick={() => {
                setSlotsData([]);
                setActiveTab('schedule');
              }}
            >
              <ScheduleIcon />
            </button>
            <button
              className="btn"
              onClick={() => {
                setIsOpenAddTimeSlotsModal(true);
              }}
            >
              <i className="icon-plus-svgrepo-com mr-2"></i> Add time slots
            </button>
          </div>
        </div>
        <div className="page-content">
          {activeTab == 'bookedSlots' && <BookedSlotsTab slotsData={slotsData} setSlotsData={setSlotsData} />}
          {activeTab == 'schedule' && <ScheduleTab slotsData={slotsData} setSlotsData={setSlotsData} />}
        </div>
      </div>

      {isOpenAddTimeSlotsModal && (
        <AddTimeSlotsModal
          onClose={() => {
            setIsOpenAddTimeSlotsModal(false);
          }}
          setSlotsData={setSlotsData}
          activeTab={activeTab}
        />
      )}
    </Layout>
  );
};

export default SchedulePage;
