import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { TextElement } from './elements/text-element';
import { SingleLineElement } from './elements/single-line-element';
import { MultilineElement } from './elements/multiline-element';
import { NumberElement } from './elements/number-element';
import { PhoneElement } from './elements/phone-element';
import { EmailElement } from './elements/email-element';
import { FullNameElement } from './elements/full-name-element';
import { AddressElement } from './elements/address-element';
import { DateElement } from './elements/date-element';
import { TimeElement } from './elements/time-element';
import { DropdownElement } from './elements/dropdown-element';
import { CheckboxElement } from './elements/checkbox-element';
import { RadiobuttonElement } from './elements/radiobutton-element';
import { LinkElement } from './elements/link-element';
import { RatingElement } from './elements/rating-element';
import { SignatureElement } from './elements/signature-element';
import { TableElement } from './elements/table-element';
import { SingleLineHeaderElement } from './elements/single-line-header-element';
import { SubmittersFieldElement } from './elements/submitters-field-element';
import { FileUploadElement } from './elements/file-upload-element';
import { FileAttachElement } from './elements/file-attach-element';
import { LoginElement } from './elements/login-element';
import cx from 'classnames';

let timeoutId;

export const FromBuilderItem = function FromElement(props) {
  const {
    id,
    element,
    handlerRemove,
    handlerSettings,
    isActive,
    moveCard,
    index,
    findCard,
    handlerChangeOption,
    handlerCloseSettings,
    handlerChangeWidth,
    duplicateElement,
    handleChangeTableColumn,
  } = props;
  const ref = useRef(null);
  const originalIndex = findCard(id).index;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'fromElement',
      item: { id, originalIndex },
      canDrag: () => !isActive,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          timeoutId = setTimeout(() => {
            moveCard(droppedId, originalIndex);
          }, 500);
        }
      },
    }),
    [id, originalIndex, moveCard, isActive],
  );

  const [{ handlerId }, drop] = useDrop(
    () => ({
      accept: 'fromElement',
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      hover({ id: draggedId }, monitor) {
        const hoverIndex = index;
        if (draggedId === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / (hoverBoundingRect.height / 2);
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (draggedId < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        // Dragging upwards
        if (draggedId > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        const { index: overIndex } = findCard(id);
        moveCard(draggedId, overIndex);
      },
    }),
    [findCard, moveCard],
  );

  drag(drop(ref));

  return (
    <>
      <div
        ref={ref}
        className={cx(`form-builder-element-preview form-label-alignment-${element.options.alignment} form-label-${element.options.size}`, {
          'form-builder-element-preview-drag': isDragging,
        })}
      >
        <div className="form-builder-element-preview-wrapper">
          {element.type === 'text' && (
            <>
              <TextElement
                element={element}
                isActive={isActive}
                handlerChangeOption={handlerChangeOption}
                handlerCloseSettings={handlerCloseSettings}
              />
            </>
          )}
          {element.type === 'single-line' && <SingleLineElement element={element} handlerChangeOption={handlerChangeOption} />}
          {element.type === 'multiline-line' && <MultilineElement element={element} handlerChangeOption={handlerChangeOption} />}
          {element.type === 'number' && <NumberElement element={element} />}
          {element.type === 'phone' && <PhoneElement element={element} />}
          {element.type === 'email' && <EmailElement element={element} />}
          {element.type === 'name' && <FullNameElement element={element} />}
          {element.type === 'address' && <AddressElement element={element} />}
          {element.type === 'time' && <TimeElement element={element} />}
          {element.type === 'date' && <DateElement element={element} />}
          {element.type === 'dropdown' && <DropdownElement element={element} />}
          {element.type === 'checkbox' && <CheckboxElement element={element} />}
          {element.type === 'radiobutton' && <RadiobuttonElement element={element} />}
          {element.type === 'link' && <LinkElement element={element} />}
          {element.type === 'rating' && <RatingElement element={element} />}
          {element.type === 'signature' && <SignatureElement element={element} />}
          {element.type === 'table' && (
            <TableElement
              element={element}
              handlerChangeWidth={handlerChangeWidth}
              handleChangeTableColumn={handleChangeTableColumn}
              isActive={isActive}
            />
          )}
          {element.type === 'single-line-header' && <SingleLineHeaderElement element={element} />}
          {element.type === 'submitters-field' && <SubmittersFieldElement element={element} />}
          {element.type === 'file-upload' && <FileUploadElement element={element} />}
          {element.type === 'file-attach' && <FileAttachElement handelChange={handlerChangeOption} element={element} />}
          {element.type === 'login' && <LoginElement element={element} />}
        </div>
        <div
          data-handler-id={handlerId}
          className={cx('form-builder-element-preview-action', { 'form-builder-element-preview-action-active': isActive })}
        >
          <button onClick={() => duplicateElement(element.id)} className="btn btn-settings btn-icon">
            <i className="icon-copy"></i>
          </button>
          <button onClick={() => handlerSettings(element)} className="btn btn-settings btn-icon">
            <i className="icon-settings"></i>
          </button>
          <button onClick={() => handlerRemove(element, isActive)} className="btn btn-remove btn-icon">
            <i className="icon-remove"></i>
          </button>
        </div>
      </div>
    </>
  );
};
