import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Layout from '../../../layout/default';
import { Breadcrumbs } from '../../../components/library/Breadcrumbs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getTaskInitiationForms } from '../../../services/tasks';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { SubmissionItem } from '../../../components/library/submission-item';
import TabsMobile from '../../corporate-library/mobile-tabs';
import withLibraryViewMode from '../../../HOCs/withLibraryViewMode';
import PageLoader from '../../../components/other/PageLoader';
import { isResponseOk } from '../../../utility/isResponseOk';

const TaskInitiationFormsPage = ({ ViewModeSwitcher, libraryViewMode }) => {
  const navigate = useNavigate();
  const { taskInitiationID } = useParams();

  const [taskInitiation, setTaskInitiation] = useState({ forms: [] });
  const [isLoading, setIsLoading] = useState(false);

  const isExistForms = !!taskInitiation.forms.length;
  const isListMode = libraryViewMode === 'LIST';

  const breadcrumbs = useMemo(() => {
    return [
      {
        name: 'Company Library',
        url: '/corporate-library',
      },
      {
        name: 'Forms assigned to me',
        url: '/corporate-library/forms/forms-assigned-to-me',
      },
      {
        name: taskInitiation.name,
        url: `/corporate-library/tasks/${taskInitiationID}`,
      },
    ];
  }, [taskInitiation?.name, taskInitiationID]);

  const handleGetForms = useCallback(() => {
    setIsLoading(true);

    getTaskInitiationForms(taskInitiationID)
      .then((res) => {
        if (isResponseOk(res)) {
          setTaskInitiation({ name: res.data.name, forms: res.data.form_initiations, id: res.data.id });
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setIsLoading(false));
  }, [taskInitiationID]);

  useEffect(() => {
    handleGetForms();
  }, [handleGetForms]);

  const goToPrev = (id, type) => {
    if (type === 'root') {
      navigate(id, { replace: true });
    }
  };

  const backToMobile = () => {
    let backToLink = breadcrumbs[breadcrumbs.length - 2]
      ? breadcrumbs[breadcrumbs.length - 2]
      : {
          name: 'Company Library',
          url: '/corporate-library',
        };

    if (backToLink.url) {
      navigate(backToLink.url);
    } else {
      navigate('/corporate-library');
    }
  };

  const renderForms = () => {
    let layout;
    if (isLoading) {
      layout = <PageLoader />;
    } else {
      layout = (
        <>
          {isExistForms && isListMode && (
            <h3 className="library-subtitle mt-2">
              Forms <span className={'total'}>(Total: {taskInitiation.forms.length} forms)</span>
            </h3>
          )}

          {isExistForms && (
            <>
              {!isListMode && <h3 className="library-subtitle mt-2">Forms</h3>}
              <div className="library-wrapper forms" style={{ rowGap: isListMode ? 0 : undefined }}>
                {taskInitiation.forms.map((item) => (
                  <SubmissionItem isListItem={isListMode} key={item.id} url={`/form-view/${item.id}`} item={item} />
                ))}
              </div>
            </>
          )}

          {!taskInitiation.forms.length && (
            <>
              <div className="empty-page">
                <h3 className="empty-page-title">This folder is empty</h3>
                <h4 className="empty-page-subtitle">This folder currently does not contain any forms. </h4>
              </div>
            </>
          )}
        </>
      );
    }
    return layout;
  };

  return (
    <Layout title="Company Library">
      <div className="library">
        <DndProvider backend={HTML5Backend}>
          <Breadcrumbs breadcrumbs={breadcrumbs} goToLevel={goToPrev} />
        </DndProvider>

        <TabsMobile />
        <h2 className={'library-title-mobile'}>
          <svg
            onClick={backToMobile}
            className={'mr-2 cursor-pointer'}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M15.8337 10.0013H4.16699M4.16699 10.0013L10.0003 15.8346M4.16699 10.0013L10.0003 4.16797"
              stroke="#101828"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {breadcrumbs[breadcrumbs.length - 1] ? breadcrumbs[breadcrumbs.length - 1].name : ''}
        </h2>
        <h2 className="library-title d-flex justify-content-space-between">
          <Link to={'/corporate-library/forms/forms-assigned-to-me'} className="mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M15.8337 10.0013H4.16699M4.16699 10.0013L10.0003 15.8346M4.16699 10.0013L10.0003 4.16797"
                stroke="#101828"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
          {taskInitiation.name || ''}
          <ViewModeSwitcher styles={{ marginLeft: 'auto' }} />
        </h2>

        {renderForms()}
      </div>
    </Layout>
  );
};

export default withLibraryViewMode(TaskInitiationFormsPage);
