import { Popover } from 'react-tiny-popover';
import React from 'react';
import './styles.scss';
import { getState } from '../../../../utility';

const SlotDetailsPopover = ({ onClose, isOpen, data, children, setDeleteBookedSlotData }) => {
  return (
    <Popover
      isOpen={isOpen}
      positions={['right', 'left']}
      padding={10}
      reposition
      onClickOutside={onClose}
      content={({ position, nudgedLeft, nudgedTop }) => (
        <div className="slot-details-block">
          <p className="slot-title">{data.user ? 'Scheduled Demo' : 'Available slot'}</p>
          <p className="slot-subtitle">
            {data.starts_at.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })}{' '}
            | {data.starts_at.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })} -{' '}
            {data.ends_at.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}
          </p>
          {data.user && (
            <div className="slot-user-info">
              <div className="slot-user-info-labels">
                <div className="slot-user-info-label">Name:</div>
                <div className="slot-user-info-label">Company:</div>
                <div className="slot-user-info-label">Facility:</div>
                <div className="slot-user-info-label">Position:</div>
                <div className="slot-user-info-label">State:</div>
                <div className="slot-user-info-label">Email:</div>
              </div>
              <div className="slot-user-info-values">
                <div className="slot-user-info-value">{`${data.user.first_name} ${data.user.last_name}`}</div>
                <div className="slot-user-info-value">{data.user.company_name}</div>
                <div className="slot-user-info-value">{data.user.facility_name}</div>
                <div className="slot-user-info-value">{data.user.position}</div>
                <div className="slot-user-info-value">{getState(data.user.state).label}</div>
                <div className="slot-user-info-value">
                  <a href={`mailto:${data.user.email}`}>{data.user.email}</a>
                </div>
              </div>
            </div>
          )}
          <button
            className="btn btn-full btn-remove slot-delete-btn"
            onClick={() => {
              onClose();
              setDeleteBookedSlotData(data);
            }}
          >
            <i className="icon-remove mr-2" />
            Delete
          </button>
        </div>
      )}
    >
      {children}
    </Popover>
  );
};

export default SlotDetailsPopover;
