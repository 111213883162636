import client from '../api';
import { CancelToken } from 'axios';
import qs from 'qs';

let cancel;

export const addTimeSlotService = async (body) => {
  if (cancel != undefined) {
    cancel();
  }

  try {
    return await client.post('/api/schedule/', body);
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getTimeSlotService = async (params) => {
  if (cancel != undefined) {
    cancel();
  }

  try {
    return await client.get(`/api/schedule/`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const bookTimeSlotService = async (body) => {
  if (cancel != undefined) {
    cancel();
  }

  try {
    return await client.post('/api/schedule/book/', body);
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const deleteTimeSlotService = async (body) => {
  if (cancel != undefined) {
    cancel();
  }

  try {
    return await client.post(`/api/schedule/delete/`, body);
  } catch (e) {
    console.log(e);
    return e;
  }
};
