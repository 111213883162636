import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ContextMenu from '../../../../components/other/context-menu';
import { useAuth } from '../../../../container/authContext';
import { deleteTaskService, partialUpdateTaskService } from '../../../../services/tasks';
import { isResponseOk } from '../../../../utility/isResponseOk';
import DeleteTaskModal from '../../components/modals/delete-task-modal';
import ManageTaskModal from '../../components/modals/manage-task-modal';
import { STATIC_ROUTES } from '../../../../constants/routes';
import { usePermission } from '../../../../container/permissionContext';

const FormsListTab = ({ task, setTask }) => {
  const { user = {} } = useAuth();
  const navigate = useNavigate();
  const { isSuperAdmin, isCompanySystemAdministrator } = usePermission();

  const hasWritePermissions = isSuperAdmin() || isCompanySystemAdministrator();

  const { company: { id: companyID } = {} } = user;

  const [isOpenSelectFormsModal, setIsOpenSelectFormsModal] = useState(false);
  const [isOpenRenameTaskModal, setIsOpenRenameTaskModal] = useState(false);
  const [isOpenDeleteTaskModal, setIsOpenDeleteTaskModal] = useState(false);

  const removeForm = useCallback(
    async (taskFormID) => {
      try {
        const newTaskForms = task.task_forms.filter((item) => item.id != taskFormID);

        const body = {
          forms_ids: newTaskForms.map((item) => item.form_id),
        };

        const response = await partialUpdateTaskService(body, task.id);

        if (isResponseOk(response) && response.data) {
          setTask((task) => ({ ...task, task_forms: newTaskForms }));
        }
      } catch (e) {
        console.log(e);
      }
    },
    [setTask, task],
  );

  const renderTaskActionsContextMenu = () => {
    const menu = [
      {
        label: 'Rename',
        icon: <i className="icon-icon-edit" />,
        action: ({ onCloseMenu }) => {
          setIsOpenRenameTaskModal(true);
          onCloseMenu();
        },
      },
      {
        label: 'Delete',
        color: '#B32318',
        icon: <i className="icon-remove" />,
        action: ({ onCloseMenu }) => {
          setIsOpenDeleteTaskModal(true);
          onCloseMenu();
        },
      },
    ];

    return <ContextMenu size={'small'} menu={menu} btnClassName="task-actions-btn" />;
  };

  const handleDeleteTask = async () => {
    try {
      const response = await deleteTaskService(task.id);

      if (isResponseOk(response)) {
        navigate(STATIC_ROUTES.TASKS);
        toast(`The task was deleted successfully`, {
          icon: () => <img src="/images/full-check-circle.svg" />,
          closeButton: ({ closeToast }) => (
            <button onClick={closeToast} className="btn btn-icon close-alert-btn">
              <img src="/images/x-icon.svg" />
            </button>
          ),
          className: 'task-deleted-alert',
          toastClassName: 'task-deleted-alert-wrapper',
          autoClose: 5000,
          hideProgressBar: false,
          progress: undefined,
          theme: 'light',
          closeOnClick: true,
          pauseOnHover: true,
          type: 'success',
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="task-details-tab-content">
      {isOpenSelectFormsModal && (
        <ManageTaskModal
          hideName
          hideTitle
          handleCloseModal={() => setIsOpenSelectFormsModal(false)}
          taskData={task}
          companyId={companyID}
          handleSuccess={(data) => setTask((prevState) => ({ ...prevState, task_forms: data.task_forms }))}
        />
      )}
      {isOpenRenameTaskModal && (
        <ManageTaskModal
          hideForms
          customTitle="Rename Task"
          handleCloseModal={() => setIsOpenRenameTaskModal(false)}
          taskData={task}
          companyId={companyID}
          handleSuccess={(data) => setTask((prevState) => ({ ...prevState, name: data.name }))}
        />
      )}
      {isOpenDeleteTaskModal && <DeleteTaskModal closeModal={() => setIsOpenDeleteTaskModal(false)} handleConfirm={handleDeleteTask} />}
      <div className="forms-list-tab">
        <div className="forms-list-tab-header">
          <h1>{task.name}</h1>
          {hasWritePermissions && (
            <div className="forms-list-header-actions">
              <button
                className="btn add-form-btn"
                onClick={() => {
                  setIsOpenSelectFormsModal(true);
                }}
              >
                <i className="icon-plus-svgrepo-com mr-2"></i> Add Form
              </button>
              {renderTaskActionsContextMenu()}
            </div>
          )}
        </div>
        <div>
          <div className="table-responsive table-default table-full forms-table">
            <div className="table-wrapper">
              <div className="table">
                <div className="tr">
                  <div className="th">
                    Name
                    <span className="th-total">(Total: {task.task_forms.length})</span>
                  </div>
                </div>
                {task.task_forms.map((taskForm) => (
                  <div className={`tr`} key={taskForm.id}>
                    <div className="td">
                      <div className="col-name">
                        <div className="col-name-left">
                          <img src="/images/icon-file-list.svg" />
                          {taskForm.name}
                        </div>
                        {hasWritePermissions && (
                          <div className="col-name-right">
                            {task.task_forms.length > 2 && (
                              <button className="btn btn-icon delete-form-button" onClick={() => removeForm(taskForm.id)}>
                                <i className="icon-remove"></i>
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormsListTab;
