import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { deleteTimeSlotService, getTimeSlotService } from '../../../../services/schedule';
import { getAllDaysInMonth } from '../../../../utility';
import { localToUTCStringForDateRange } from '../../../../utility/dates/localToUTCForDateRange';
import { utcToLocalDate } from '../../../../utility/dates/utcToLocalDate';
import { isResponseOk } from '../../../../utility/isResponseOk';
import DeleteBookedSlotModal from '../DeleteBookedSlotModal';
import SlotDetailsPopover from '../SlotDetailsPopover';
import ClearDayModal from '../ClearDayModal';
import './styles.scss';

const ScheduleTab = ({ slotsData, setSlotsData }) => {
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(() => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
  });
  const [slotPopoverData, setSlotPopoverData] = useState(null);
  const [deleteBookedSlotData, setDeleteBookedSlotData] = useState(null);
  const [clearDayData, setClearDayData] = useState(null);

  const getTimeSlots = useCallback(async () => {
    if (!selectedDate) {
      setSlotsData([]);
    }

    try {
      const selectedDateCopy = new Date(selectedDate);
      const params = {
        date_after: localToUTCStringForDateRange(selectedDateCopy, 'start'),
        date_before: localToUTCStringForDateRange(selectedDateCopy.setMonth(selectedDateCopy.getMonth() + 1), 'start'),
      };

      const response = await getTimeSlotService(params);

      if (isResponseOk(response)) {
        setSlotsData(
          response.data.map((slot) => ({ ...slot, starts_at: utcToLocalDate(slot.starts_at), ends_at: utcToLocalDate(slot.ends_at) })),
        );
      }
    } catch (e) {
      console.log(e);
    }
  }, [selectedDate]);

  useEffect(() => {
    getTimeSlots();
  }, [getTimeSlots]);

  const calendarDaysData = useMemo(() => {
    const emptyStartDays = Array.from({ length: selectedDate.getDay() }, (_, i) => ({ date: null, weekDay: i }));

    const allDays = getAllDaysInMonth(selectedDate.getMonth() + 1, selectedDate.getFullYear());
    const calendarDays = allDays.map((day) => ({
      date: day,
      weekDay: day.getDay(),
      slots: slotsData.filter((slot) => slot.starts_at.toLocaleDateString() === day.toLocaleDateString()),
    }));

    const emptyEndDays = Array.from({ length: 6 - allDays.at(-1).getDay() }, (_, i) => ({
      date: null,
      weekDay: allDays.at(-1).getDay() + i + 1,
    }));

    return [...emptyStartDays, ...calendarDays, ...emptyEndDays];
  }, [selectedDate, slotsData]);

  const handleChangeDate = (increment) => {
    setSlotsData([]);
    setSelectedDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + increment, 1));
  };

  const handleClearDay = async () => {
    try {
      const body = {
        ids: clearDayData,
      };

      const response = await deleteTimeSlotService(body);

      if (isResponseOk(response)) {
        toast(`The time slots was deleted successfully`, {
          icon: () => <img src="/images/full-check-circle.svg" />,
          closeButton: ({ closeToast }) => (
            <button onClick={closeToast} className="btn btn-icon close-alert-btn">
              <img src="/images/x-icon.svg" />
            </button>
          ),
          className: 'slot-deleted-alert',
          toastClassName: 'slot-deleted-alert-wrapper',
          autoClose: 5000,
          hideProgressBar: false,
          progress: undefined,
          theme: 'light',
          closeOnClick: true,
          pauseOnHover: true,
          type: 'success',
        });
        setSlotsData((state) => state.filter((item) => clearDayData.indexOf(item.id) === -1));
        setClearDayData(null);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteSlot = useCallback(async () => {
    try {
      const deleteSlotID = deleteBookedSlotData.id;
      const body = {
        ids: [deleteSlotID],
      };
      const response = await deleteTimeSlotService(body);

      if (isResponseOk(response)) {
        toast(`The time slot was deleted successfully`, {
          icon: () => <img src="/images/full-check-circle.svg" />,
          closeButton: ({ closeToast }) => (
            <button onClick={closeToast} className="btn btn-icon close-alert-btn">
              <img src="/images/x-icon.svg" />
            </button>
          ),
          className: 'slot-deleted-alert',
          toastClassName: 'slot-deleted-alert-wrapper',
          autoClose: 5000,
          hideProgressBar: false,
          progress: undefined,
          theme: 'light',
          closeOnClick: true,
          pauseOnHover: true,
          type: 'success',
        });
        setDeleteBookedSlotData(null);
        setSlotsData((state) => state.filter((item) => item.id !== deleteSlotID));
      }
    } catch (e) {
      console.log(e);
    }
  }, [deleteBookedSlotData]);

  return (
    <div>
      <div className="month-select">
        <button className="btn btn-outline pagination-btn" onClick={() => handleChangeDate(-1)}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.8334 6.9974H1.16675M1.16675 6.9974L7.00008 12.8307M1.16675 6.9974L7.00008 1.16406"
              stroke="#344054"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Previous
        </button>
        <div className="selected-date">
          {selectedDate.toLocaleDateString('en-US', {
            month: 'long',
            year: 'numeric',
          })}
        </div>
        <button className="btn btn-outline pagination-btn" onClick={() => handleChangeDate(1)}>
          Next
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.16675 6.9974H12.8334M12.8334 6.9974L7.00008 1.16406M12.8334 6.9974L7.00008 12.8307"
              stroke="#344054"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>

      <div className="calendar-container">
        <div className="day-name">Sun</div>
        <div className="day-name">Mon</div>
        <div className="day-name">Tue</div>
        <div className="day-name">Wed</div>
        <div className="day-name">Thu</div>
        <div className="day-name">Fri</div>
        <div className="day-name">Sat</div>

        {_.chunk(calendarDaysData, 7).map((row, rowIndex) =>
          row.map((item, colIndex) => (
            <div className={`calendar-day scrollbar calendar-day-${item.weekDay} row-${rowIndex}`} key={`${rowIndex} ${colIndex}`}>
              {item.date && (
                <div className={`day-number ${today.toDateString() === item.date.toDateString() ? 'active' : ''}`}>
                  <div>{item.date.getDate()}</div>
                  {!!item.slots?.length && (
                    <button
                      className="btn btn-outline btn-danger"
                      style={{ gap: 8, display: 'inline-flex', padding: '2px 8px', height: 'auto' }}
                      onClick={() => setClearDayData(item.slots.map((item) => item.id))}
                    >
                      <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_10670_32234)">
                          <path
                            d="M12.5003 7.4974L7.50033 12.4974M7.50033 7.4974L12.5003 12.4974M18.3337 9.9974C18.3337 14.5998 14.6027 18.3307 10.0003 18.3307C5.39795 18.3307 1.66699 14.5998 1.66699 9.9974C1.66699 5.39502 5.39795 1.66406 10.0003 1.66406C14.6027 1.66406 18.3337 5.39502 18.3337 9.9974Z"
                            stroke="#101828"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_10670_32234">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  )}
                </div>
              )}
              <div className="slots-list">
                {!!item.slots?.length &&
                  item.slots
                    .sort((a, b) => new Date(a.starts_at) - new Date(b.starts_at))
                    .map((slot) => (
                      <SlotDetailsPopover
                        isOpen={slotPopoverData?.id === slot.id}
                        data={slotPopoverData}
                        onClose={() => setSlotPopoverData(null)}
                        setDeleteBookedSlotData={setDeleteBookedSlotData}
                        key={slot.id}
                      >
                        <div
                          className={`slots-list-item ${slot.user ? 'booked' : 'free'}`}
                          onClick={() => {
                            setSlotPopoverData((currentSlot) => (!currentSlot || currentSlot.id !== slot.id ? slot : null));
                          }}
                        >
                          <div className="slots-list-item-name">
                            {slot.user ? `${slot.user.first_name} ${slot.user.last_name}` : 'Available slot'}
                          </div>
                          <div className="slots-list-item-time">
                            {slot.starts_at.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}
                          </div>
                        </div>
                      </SlotDetailsPopover>
                    ))}
              </div>
            </div>
          )),
        )}
      </div>
      {deleteBookedSlotData?.id && (
        <DeleteBookedSlotModal
          data={deleteBookedSlotData}
          closeModal={() => setDeleteBookedSlotData(null)}
          handleConfirm={handleDeleteSlot}
        />
      )}
      {clearDayData && <ClearDayModal closeModal={() => setClearDayData(null)} handleConfirm={handleClearDay} />}
    </div>
  );
};

export default ScheduleTab;
