import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../container/authContext';
import { usePermission } from '../../container/permissionContext';
import SMSModal from '../../components/other/SMSModal';
import ScheduleDemoModal from './components/ScheduleDemoModal';
import ScheduleSuccessModal from './components/ScheduleSuccessModal';

function HomePage() {
  const { user } = useAuth();
  const navigation = useNavigate();
  const { isSuperAdmin } = usePermission();
  const { is_unit_user = false } = user || {};

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenScheduleDemoModal, setIsOpenScheduleDemoModal] = useState(false);
  const [scheduleSuccessModalData, setScheduleSuccessModalData] = useState(null);

  const handelClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (user) {
      if (isSuperAdmin()) {
        if (user?.company?.id) {
          navigation(`company/${user.company.id}`);
        } else {
          navigation('/companies');
        }
      } else {
        if (is_unit_user) {
          navigation('/cna/assignments');
        } else {
          navigation('/corporate-library/');
        }
      }
    }
  }, [user, isSuperAdmin, navigation, is_unit_user]);

  if (user) {
    return;
  }

  return (
    <main className={'home-page'}>
      <div className={'home-wrapper'}>
        <div className={'home-logo'}>
          <img src="/images/logo.svg" alt="AllCareForms" />
        </div>
        <div className={'home-contact'}>
          <a href="mailto:info@AllCareForms.com">
            <img src="/images/icon-hone-mail.svg" className={'mr-2'} alt="" />
            info@AllCareForms.com
          </a>
          <a href="tel:+17247052198">
            <img src="/images/icon-hone-phone.svg" className={'mr-2'} alt="" />
            (724) 705-2198
          </a>
          {/* <button className={'btn'} onClick={() => setIsOpen(true)}>
            SMS opt-in consent
          </button> */}
          <button className="btn d-flex align-center" onClick={() => setIsOpenScheduleDemoModal(true)}>
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.3333 0.90625V4.23958M5.66667 0.90625V4.23958M1.5 7.57292H16.5M3.16667 2.57292H14.8333C15.7538 2.57292 16.5 3.31911 16.5 4.23958V15.9062C16.5 16.8267 15.7538 17.5729 14.8333 17.5729H3.16667C2.24619 17.5729 1.5 16.8267 1.5 15.9062V4.23958C1.5 3.31911 2.24619 2.57292 3.16667 2.57292Z"
                stroke="white"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="ml-2">Schedule a Demo</span>
          </button>
        </div>
        <div className={'home-content'}>
          <h1>ACHIEVE PAPERLESS SOLUTION</h1>
          <p>FOR</p>
          <h2>senior living operations and compliance</h2>
          <Link to={'/login'} className={'btn mr-auto ml-auto'}>
            Log in
          </Link>
        </div>
      </div>

      <SMSModal isOpen={isOpen} closeModal={handelClose} title={'SMS opt-in consent'}></SMSModal>
      {isOpenScheduleDemoModal && (
        <ScheduleDemoModal
          onClose={() => {
            setIsOpenScheduleDemoModal(false);
          }}
          onSuccess={(data) => {
            setIsOpenScheduleDemoModal(false);
            setScheduleSuccessModalData(data);
          }}
        />
      )}
      {scheduleSuccessModalData && (
        <ScheduleSuccessModal onClose={() => setScheduleSuccessModalData(null)} data={scheduleSuccessModalData} />
      )}
    </main>
  );
}

export default HomePage;
