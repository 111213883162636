import React from 'react';
import Modal from 'react-modal';
import { utcToLocalDate } from '../../../../utility/dates/utcToLocalDate';
import './styles.scss';
import { isMobile } from 'react-device-detect';

const ScheduleSuccessModal = ({ data, onClose }) => {
  const localDate = utcToLocalDate(data.starts_at);

  return (
    <Modal
      className="without-footer modal-alert"
      isOpen
      onRequestClose={onClose}
      overlayClassName={`${isMobile ? 'is-mobile' : ''}`}
      contentLabel="Schedule Success Modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        <img src="/images/full-check-circle.svg" />

        <button className="btn btn-icon" onClick={onClose}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>

      <div className="demo-scheduled-body">
        <h3>Demo Scheduled!</h3>
        <p>
          Your demo has been successfully scheduled for{' '}
          <strong>
            {localDate.toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            })}
          </strong>
          , at <strong>{localDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}</strong>
          <p className="mt-2">You will recieve an email from allcareforms.com</p>
        </p>
      </div>
    </Modal>
  );
};

export default ScheduleSuccessModal;
