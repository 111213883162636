import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';
import { MultiSelect } from '../../../components/other/MultiSelect';
import PageLoader from '../../../components/other/PageLoader';
import { DATE_PICKER_DATE_FORMAT } from '../../../constants';
import Layout from '../../../layout/default';
import { getUserSubmissions, exportUserSubmissions } from '../../../services/folder/form-avaliable';
import { localToUTCStringForDateRange } from '../../../utility/dates/localToUTCForDateRange';
import { utcToLocalDateFormatter } from '../../../utility/dates/utcToLocalDate';
import './styles.scss';
import { isResponseOk } from '../../../utility/isResponseOk';
import GeneratingStartModal from './GeneratingStartModal';
import SelectFormsModal from './SelectFormsModal';
import { ReactComponent as XIcon } from '../../../assets/images/x-circle-black.svg';

const ExportUserSubmissions = () => {
  const { id, user_id } = useParams();

  const pageBreadcrumbs = [
    {
      url: `/company/${id}/user/${user_id}`,
      name: 'User',
    },
    {
      url: `/company/${id}/user/${user_id}/forms`,
      name: `Generate the PDF`,
    },
  ];

  const submissionsTableCols = [
    {
      name: 'Submission',
    },
    {
      name: 'Status',
    },
    {
      name: 'Initiation date',
    },
    {
      name: 'Submission date',
    },
  ];

  const initialFormData = {
    pdfName: '',
    forms: [],
    submittedDate: [null, null],
    initiatedDate: [null, null],
    email: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  const [submissionsSearch, setSubmissionsSearch] = useState('');
  const [submissions, setSubmissions] = useState([]);
  const [selectedSubmissions, setSelectedSubmissions] = useState([]);
  const [isSubmissionsLoading, setIsSubmissionsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isOpenGeneratingStartModal, setIsOpenGeneratingStartModal] = useState(false);
  const [isSelectFormsModalOpen, setIsSelectFormsModalOpen] = useState(false);

  const isValidPDFName = useMemo(() => {
    const pdfNameRegex = /^[^<>:;,?"*|/]+$/;

    const trimValue = formData.pdfName.trim();
    return pdfNameRegex.test(trimValue) && trimValue.length <= 255;
  }, [formData.pdfName]);

  useDeepCompareEffect(() => {
    if (formData.forms.length === 0) {
      setSubmissions([]);
      return;
    }

    const params = {
      forms_ids: formData.forms.map((item) => item.id),
      submission_date_after: formData.submittedDate[0] ? localToUTCStringForDateRange(formData.submittedDate[0], 'start') : null,
      submission_date_before: formData.submittedDate[1] ? localToUTCStringForDateRange(formData.submittedDate[1], 'end') : null,
      initiation_date_after: formData.initiatedDate[0] ? localToUTCStringForDateRange(formData.initiatedDate[0], 'start') : null,
      initiation_date_before: formData.initiatedDate[1] ? localToUTCStringForDateRange(formData.initiatedDate[1], 'end') : null,
    };
    setIsSubmissionsLoading(true);

    getUserSubmissions(user_id, params)
      .then((response) => {
        setSubmissions(response.data);
        setIsSubmissionsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsSubmissionsLoading(false);
      });
  }, [setIsSubmissionsLoading, formData.forms, formData.submittedDate, formData.initiatedDate]);

  const handleFormChange = (key, value) => {
    setFormData((prevState) => {
      const newState = _.cloneDeep(prevState);
      _.set(newState, key, value);
      return newState;
    });
  };

  const displayingSubmissions = useMemo(() => {
    if (!submissionsSearch) {
      return submissions;
    }

    return submissions.filter((submission) => submission.name.toLowerCase().indexOf(submissionsSearch.toLowerCase().trim()) !== -1);
  }, [submissionsSearch, submissions]);

  const isFormDataValid = useMemo(() => {
    return !!isValidPDFName && selectedSubmissions.length && formData.email;
  }, [formData.email, selectedSubmissions, isValidPDFName]);

  const handleSelectSubmission = (id, isChecked) => {
    if (isChecked) {
      setSelectedSubmissions((state) => state.filter((itemID) => itemID !== id));
    } else {
      setSelectedSubmissions((state) => [...state, id]);
    }
  };

  const isAllSelected = useMemo(() => {
    if (
      displayingSubmissions.length > 0 &&
      displayingSubmissions.every((item) => selectedSubmissions.some((selectedSubmission) => selectedSubmission === item.id))
    ) {
      return true;
    }

    return false;
  }, [selectedSubmissions, displayingSubmissions]);

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedSubmissions((state) =>
        state.filter((item) => !displayingSubmissions.some((displayingSubmission) => displayingSubmission.id === item)),
      );
    } else {
      setSelectedSubmissions((state) => [...new Set([...state, ...displayingSubmissions.map((item) => item.id)])]);
    }
  };

  const handleSubmit = async () => {
    try {
      const body = {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        submission_parts_ids: selectedSubmissions.filter((submission) => displayingSubmissions.some((item) => item.id === submission)),
        file_name: formData.pdfName,
        email: formData.email,
      };

      const response = await exportUserSubmissions(user_id, body);

      if (response && isResponseOk(response)) {
        setErrors({});
        setIsOpenGeneratingStartModal(true);
      }
    } catch (e) {
      const errors = e.response.data;

      if (errors) {
        setErrors(errors);
      }
    }
  };

  const handleClearForm = () => {
    setFormData(initialFormData);
    setErrors({});
  };

  return (
    <Layout breadcrumbs={pageBreadcrumbs} title={'Generate the PDF'}>
      {isOpenGeneratingStartModal && (
        <GeneratingStartModal
          onClose={() => {
            setIsOpenGeneratingStartModal(false);
          }}
        />
      )}
      {isSelectFormsModalOpen && (
        <SelectFormsModal
          formData={formData}
          handleFormChange={handleFormChange}
          onClose={() => setIsSelectFormsModalOpen(false)}
          companyID={id}
          userID={user_id}
        />
      )}
      <div style={{ width: '100%' }} className="export-pdf-wrapper">
        <div className={'report-builder'} style={{ height: '100%' }}>
          <div className={'report-builder-content'} style={{ position: 'relative' }}>
            {isSubmissionsLoading && <PageLoader />}

            <h3 className="select-submissions-title">Select Submissions</h3>
            <div className="library-filter">
              <div className="library-filter-search form-control-item">
                <input
                  value={submissionsSearch}
                  onChange={(e) => {
                    setSubmissionsSearch(e.target.value);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
                <i className="icon-search"></i>
              </div>
            </div>

            {formData.forms.length ? (
              <div className="table-responsive table-default table-full">
                <div className="table-wrapper">
                  <div className="table">
                    <div className="thead">
                      <div className="tr">
                        <div className="th th-checkbox">
                          <div className="form-control-checkbox d-flex">
                            <div>
                              <input type="checkbox" onChange={handleSelectAll} checked={isAllSelected} />
                              <span className={''}></span>
                            </div>
                            <strong className="select-all-label">All</strong>
                          </div>
                        </div>
                        {submissionsTableCols.map((item, index) => (
                          <div className="th" key={index}>
                            {item.name}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="tbody">
                      {displayingSubmissions.map((item) => {
                        const isChecked = selectedSubmissions.some((id) => id === item.id);

                        return (
                          <div className="tr cursor-pointer" key={item.id} onClick={() => handleSelectSubmission(item.id, isChecked)}>
                            <div className="td td-checkbox">
                              <div className="form-control-checkbox d-flex">
                                <input type="checkbox" onChange={() => {}} checked={isChecked} />
                                <span className={''}></span>
                              </div>
                            </div>
                            <div className="td">{item.name}</div>
                            <div className="td">{item.status}</div>
                            <div className="td">{item.initiated_at ? utcToLocalDateFormatter(item.initiated_at) : ''}</div>
                            <div className="td">{item.submitted_at ? utcToLocalDateFormatter(item.submitted_at) : ''}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <h3 className="to-view-list-title mt-4 text-center">To view a list of submissions please select forms</h3>
            )}
          </div>

          <aside className={'report-builder-aside'}>
            <div className="d-flex justify-content-space-between generate-pdf-block">
              <h2 className="title d-flex justify-space-between">Generate the PDF</h2>
              <button onClick={handleClearForm} className="btn btn-outline m-0">
                <XIcon className="mr-2" />
                Clear All
              </button>
            </div>
            <div className={'form-item mb-6'}>
              <label className={'form-label'}>Specify the PDF name</label>
              <div className={'form-item-wrapper'}>
                <input
                  value={formData.pdfName}
                  type="text"
                  className={'form-control'}
                  placeholder={'Select...'}
                  onChange={(e) => handleFormChange('pdfName', e.target.value)}
                />
              </div>
              {!isValidPDFName && formData.pdfName && <span className="form-error">File name contains invalid symbols</span>}
            </div>

            <div className="form-item mb-6">
              <label className="form-label">Select Forms</label>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setIsSelectFormsModalOpen(true);
                }}
              >
                <MultiSelect
                  options={[]}
                  placeholder="Select"
                  allName="All"
                  otherSelectProps={{
                    menuIsOpen: false,
                    isDisabled: true,
                    styles: {
                      control: (base, state) => ({
                        ...base,
                        backgroundColor: 'unset',
                      }),
                    },
                  }}
                ></MultiSelect>
              </div>

              {formData.forms.length > 0 && (
                <div className="selected--forms">
                  {formData.forms.map((item) => {
                    return (
                      <div className="selected--forms__list__item" key={item.id}>
                        <p>{item.name}</p>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() =>
                            handleFormChange(
                              'forms',
                              formData.forms.filter((form) => item.id !== form.id),
                            )
                          }
                        >
                          <path
                            d="M12.5003 7.4974L7.50033 12.4974M7.50033 7.4974L12.5003 12.4974M18.3337 9.9974C18.3337 14.5998 14.6027 18.3307 10.0003 18.3307C5.39795 18.3307 1.66699 14.5998 1.66699 9.9974C1.66699 5.39502 5.39795 1.66406 10.0003 1.66406C14.6027 1.66406 18.3337 5.39502 18.3337 9.9974Z"
                            stroke="#FDA29B"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className={'form-item mb-6'}>
              <label className={'form-label'}>Period of submitting</label>
              <DatePicker
                isClearable
                selectsRange
                selected={formData.submittedDate[0]}
                className="form-control"
                startDate={formData.submittedDate[0]}
                endDate={formData.submittedDate[1]}
                dateFormat={DATE_PICKER_DATE_FORMAT}
                disabledKeyboardNavigation
                placeholderText={'Select'}
                onChange={(e) => handleFormChange('submittedDate', e)}
              />
            </div>
            <div className={'form-item mb-6'}>
              <label className={'form-label'}>Period of form initiation</label>
              <DatePicker
                isClearable
                selectsRange
                selected={formData.initiatedDate[0]}
                className="form-control"
                startDate={formData.initiatedDate[0]}
                endDate={formData.initiatedDate[1]}
                dateFormat={DATE_PICKER_DATE_FORMAT}
                disabledKeyboardNavigation
                placeholderText={'Select'}
                onChange={(e) => handleFormChange('initiatedDate', e)}
              />
            </div>
            <div className={`form-item form-item-mail mb-6`}>
              <label className={'form-label'}>Enter email for notification</label>
              <img className="mail-icon" src="/images/mail-icon.svg" alt="" />
              <input
                placeholder="mail@example.com"
                id="email"
                value={formData.email}
                onChange={(e) => handleFormChange('email', e.target.value)}
                type="text"
                className="form-control"
              />
              {errors.email?.length && <span className="form-error">{errors.email[0]}</span>}
            </div>
            <button onClick={handleSubmit} disabled={!isFormDataValid} className={'btn'}>
              <svg className={'mr-2'} xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <g clipPath="url(#clip0_5076_1432)">
                  <path
                    d="M19.6668 3.33136V8.33136M19.6668 8.33136H14.6668M19.6668 8.33136L15.8002 4.69803C14.9045 3.80196 13.7965 3.14737 12.5795 2.79534C11.3625 2.44331 10.0761 2.40532 8.8404 2.68491C7.60472 2.9645 6.46 3.55256 5.51305 4.39421C4.56611 5.23586 3.8478 6.30368 3.42516 7.49803M1.3335 16.6647V11.6647M1.3335 11.6647H6.3335M1.3335 11.6647L5.20016 15.298C6.09579 16.1941 7.20381 16.8487 8.42084 17.2007C9.63787 17.5527 10.9242 17.5907 12.1599 17.3111C13.3956 17.0316 14.5403 16.4435 15.4873 15.6018C16.4342 14.7602 17.1525 13.6924 17.5752 12.498"
                    stroke="white"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5076_1432">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                  </clipPath>
                </defs>
              </svg>
              Generate PDF
            </button>
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default ExportUserSubmissions;
