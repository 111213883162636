import React, { memo } from 'react';
import cx from 'classnames';

const TaskItem = ({ item, changeFolder, wrapperStyles, isListItem }) => {
  return (
    <div
      style={wrapperStyles}
      className={cx('library-item cursor-pointer', { 'list-item': isListItem })}
      onClick={() => {
        changeFolder(item.id, true);
      }}
    >
      <div className="library-item-text">
        <img src="/images/folder-big-icon.png" alt="" /> {item.name}
      </div>
    </div>
  );
};

export default memo(TaskItem);
